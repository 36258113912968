import {View} from "react-native";

import {AddressUtilities, DateUtilities, OrderStatus} from "@luminate/luminate-ts-sdk";

import {OrderLineItem} from "./OrderLineItem";

export type OrderDetailsProps = {
    patientFirstName?: string;
    patientLastName?: string;
    orderNumber?: string;
    status?: OrderStatus;
    purchaseDate?: Date;
    labName?: string;
    labAddress1?: string;
    labAddress2?: string;
    labCity?: string;
    labState?: string;
    labZip?: string;
    labPhone?: string;
};

export const OrderDetails = (props: OrderDetailsProps) => {
    const purchaser = `${props.patientFirstName} ${props.patientLastName}`;
    const purchaseDateDisplay = props.purchaseDate ? DateUtilities.formatYYYYMMDDHHMM(props.purchaseDate) : undefined;

    return (
        <View>
            {!props.status ? <></> : <OrderLineItem label={'Status:'} value={props.status} />}
            <OrderLineItem label={'Purchaser:'} value={purchaser} />
            <OrderLineItem label={'Order #:'} value={props.orderNumber} />
            <OrderLineItem label={'Date of Purchase:'} value={purchaseDateDisplay} />
            <OrderLineItem label={'Lab Contact:'} value={props.labName} />
            <OrderLineItem value={props.labAddress1} />
            {!props.labAddress2 ? <></> : <OrderLineItem value={props.labAddress2} />}
            <OrderLineItem value={AddressUtilities.compileCityStateZip(props.labCity, props.labState, props.labZip)} />
            <OrderLineItem value={props.labPhone} />
        </View>
    );
};