import React, {useEffect} from 'react';
import {LinkingOptions, NavigationContainer} from '@react-navigation/native';
import {ActivityIndicator} from "react-native-paper";
import {Provider as PaperProvider} from 'react-native-paper';
import * as Linking from 'expo-linking';

import {AppStackWeb} from "./AppStackWeb";
import {useAuth} from '../../contexts/AuthContext';
import {useThemeContext} from "../../contexts/ThemeContext";
import Environment from "../../models/Environment";

export const RouterWeb = () => {
    const {loading, session} = useAuth();
    const {theme} = useThemeContext();

    useEffect(() => {
        if (session?.appConfig && !session?.appConfig.enabled) {
            /*
             * Redirect users back to the patient portal
             */
            window.location.href = Environment.apiBaseUrl as string;
        }
    }, [session?.appConfig.enabled]);

    const prefixes = [Linking.createURL(Environment.appUrl as string)];

    const linkingConfig = {
        prefixes,
        config: {
            screens: {
                BrowseTab: '/dat/browse',
                CartTab: {
                    path: '/dat/cart',
                    screens: {
                        ShoppingCart: '/',
                        Checkout: {
                            path: '/checkout',
                            screens: {
                                SignIn: '/sign-in',
                                Register: '/register',
                                Connect: '/connect',
                                ForgotUsername: '/forgot/username',
                                ForgotPassword: '/forgot/password',
                                TermsOfService: '/terms',
                            }
                        },
                        OrderConfirmation: '/order',
                        OrderPdfWeb: '/order/pdf',
                        EditProfile: '/profile'
                    }
                },
                HowItWorksTab: '/dat/how-it-works',
                FaqTab: '/dat/faq',
                LabLocationsTab: '/dat/locations',
                ServiceAreasTab: '/dat/service-areas',
                ContactUsTab: '/dat/contact',
                ResultsTab: '/dat/results',
                TermsOfService: '/dat/terms',
                PrivacyPolicy: '/dat/privacy',
                OrderableTestDetailsScreen: '/dat/test/details',
                SettingsTab: {
                    path: '/dat/settings',
                    screens: {
                        SignIn: '/sign-in',
                        Register: '/register',
                        Connect: '/connect',
                        ForgotUsername: '/forgot/username',
                        ForgotPassword: '/forgot/password',
                        OrderHistoryTab: {
                            path: '/orders',
                            screens: {
                                OrderHistoryList: '',
                                OrderDetails: '/details',
                                OrderPdfWeb: '/pdf',
                                CancelOrder: '/cancel'
                            }
                        },
                        UserProfileTab: {
                            path: '/profile',
                            screens: {
                                ViewProfile: '/view',
                                EditProfile: '/edit',
                                ChangeEmail: '/change/email',
                                ForgotPassword: '/reset-password'
                            }
                        }
                    }
                }
            },
        }
    } as LinkingOptions<any>;

    return (
        <PaperProvider theme={theme}>
            {loading ? <ActivityIndicator animating={true} size="large"/> :
                <NavigationContainer theme={theme} linking={linkingConfig}>
                    <AppStackWeb/>
                </NavigationContainer>
            }
        </PaperProvider>
    );
};
