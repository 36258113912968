import {v4 as uuid} from "uuid";
import React, { useState } from "react";
import {ImageBackground, ScrollView, View, LayoutChangeEvent} from "react-native";
import {Surface} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {ContactUsPhoneConfig} from "@luminate/luminate-ts-sdk";

import {PageFooter} from "../common/PageFooter";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {Header16, Header24, Header40, SubText} from "../typography";
import {HtmlView} from "../common/HtmlView";
import {ExternalLink} from "../common/ExternalLink";
import {Button} from "../common/Button";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {DatImageUtility} from "../../services/DatImageUtility";
import { useResponsive, DeviceType } from "../hooks/useResponsive";

export const ContactUsScreen = () => {
    const {theme} = useThemeContext();
    const {session} = useAuth();
    const Navigation = useNavigation();
    const supportEmail = session?.appConfig.contactUsConfig.email || 'help@luminatehealth.com';
    const supportEmailLink = 'mailto:' + supportEmail;
    const { deviceType } = useResponsive();

    type ContactUsPhoneItemProps = {
        label: string;
        number: string;
    };
    const ContactUsPhoneItem = (props: ContactUsPhoneItemProps) => {
        const supportPhoneLink = 'tel:+' + props.number || '';
        return (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Header16>{props.label}:</Header16>
                <ExternalLink url={supportPhoneLink}>
                    <Header16 style={{marginHorizontal: 10}}>{props.number}</Header16>
                </ExternalLink>
            </View>
        );
    };

    const renderContactUsPhoneItem = (phoneConfig: ContactUsPhoneConfig) => {
        return <ContactUsPhoneItem key={uuid()} label={phoneConfig.label} number={phoneConfig.number}/>
    };

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{height: '100%', alignItems: 'center'}}>
                <View style={{flex: 1, width: '100%', maxWidth: 955, minWidth: 270}}>
                    <Surface style={{height: '100%', padding: 10}}>
                        <View>
                            <Header40 style={{padding: 20, textAlign: 'center'}}>Contact Us</Header40>
                            <View style={{padding: 10}}>
                                {session?.appConfig.contactUsConfig.banner &&
                                    <HtmlView>
                                        {session?.appConfig.contactUsConfig.banner}
                                    </HtmlView> ||
                                    <SubText style={{margin: 10, lineHeight: 20}}>
                                        We would love to hear from you. Please use the contact information below and send us a
                                        message. Whether you need help with the service, or want to share any feedback, we're here
                                        for you!
                                    </SubText>
                                }
                            </View>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                            <View style={{flex: 3, minWidth: 250, margin: 20}}>
                                <View>
                                    <Header24>{session?.appConfig.contactUsConfig.contactDetailsLabel || 'Contact Details'}:</Header24>
                                    {session?.appConfig.contactUsConfig.phones.map((phoneConfig) => renderContactUsPhoneItem(phoneConfig))}

                                    <View style={[{alignItems: 'left' }, (deviceType == DeviceType.MOBILE) ? {flexDirection: 'column'} : {flexDirection: 'row'}]}>
                                        <Header16>Email:</Header16><ExternalLink url={supportEmailLink}><Header16
                                        style={[(deviceType == DeviceType.MOBILE) ? {} : {marginHorizontal: 10}]}>{supportEmail}</Header16></ExternalLink>
                                    </View>

                                    {session?.appConfig.contactUsConfig.description &&
                                        <HtmlView>
                                            {session?.appConfig.contactUsConfig.description}
                                        </HtmlView>
                                    }
                                </View>
                                {!session?.appConfig.contactUsConfig.hideAddressDetails &&
                                    <View style={{marginTop: 20}}>
                                        <Header24>{session?.appConfig.contactUsConfig.addressDetailsLabel || 'Address:'}</Header24>
                                        <Header16>{session?.lab.name}</Header16>
                                        <SubText>{session?.lab.address1}</SubText>
                                        <SubText>{session?.lab.address2}</SubText>
                                        <SubText>{session?.lab.city} {session?.lab.state} {session?.lab.zip}</SubText>
                                    </View>
                                }
                                <View style={[{marginTop: 20, justifyContent: 'flex-start'}, (deviceType == DeviceType.MOBILE) ? {flexDirection: 'column', alignItems: 'start'} : {flexDirection: 'row'}]}>
                                    {!session?.appConfig.serviceAreasEnabled && <Button
                                        style={[{marginRight: 10}]}
                                        onPress={() => {
                                        Navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS)
                                    }}>Find A Location</Button>}
                                    <Button
                                        style={[(deviceType == DeviceType.MOBILE) ? {marginTop: 10} : {marginHorizontal: 0}]}
                                        onPress={() => {
                                            Navigation.navigate(SCREEN_NAMES.HELP)
                                        }}>Read Our FAQs</Button>
                                </View>
                            </View>
                            <View style={{
                                flex: 5,
                                minWidth: 270,
                                width: '100%',
                                height: '100%'
                            }}>
                                <ImageBackground style={{width: '100%', height: 400}}
                                                 source={{uri: DatImageUtility.getJpgImageUrl(session?.lab.id!, 'contact-us')}}
                                                 resizeMode={'contain'}/>
                            </View>
                        </View>
                    </Surface>
                </View>
            </View>
            <PageFooter/>
        </ScrollView>
    );
};