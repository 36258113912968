import {ReactNode} from "react";
import {Button as PaperButton} from 'react-native-paper';
import {LayoutChangeEvent, StyleSheet, ViewStyle} from "react-native";
import {IconSource} from "react-native-paper/lib/typescript/components/Icon";
import {useThemeContext} from "../../contexts/ThemeContext";

export type ButtonProps = {
    style?: ViewStyle;
    mode?: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal';
    children?: ReactNode;
    onPress?: () => void;
    disabled?: boolean;
    contentStyle?: ViewStyle;
    icon?: IconSource;
    loading?: boolean;
    onLayout?: ((event: LayoutChangeEvent) => void);
};

export const Button = (props: ButtonProps) => {
    const {theme} = useThemeContext();
    const styles = StyleSheet.create({
        content: {
            backgroundColor: theme.colors.primary,
        },
        disabled: {
            backgroundColor: theme.colors.surfaceDisabled,
        },
        label: {
            color: theme.colors.surface,
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 'bold'
        }
    });
    return (
        <PaperButton contentStyle={[styles.content, props.contentStyle, props.disabled ? styles.disabled : {}]}
                     labelStyle={styles.label}
                     onPress={props.onPress}
                     disabled={props.disabled}
                     loading={props.loading}
                     style={props.style}
                     icon={props.icon}
                     onLayout={props.onLayout}
                     mode={props.mode}
        >
            {props.children}
        </PaperButton>
    );
}