import {ImageURISource, StyleSheet, View} from "react-native";
import {Divider, MD2Colors, Surface} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import {HowItWorksStep} from "./HowItWorksStep";
import {HowItWorksContent} from "./HowItWorksContent";
import {Header30, Header40, SubText} from "../typography";
import {DatImageUtility} from "../../services/DatImageUtility";
import {useAuth} from "../../contexts/AuthContext";

export const DefaultHowItWorks = () => {
    const {session, displaySnack} = useAuth();
    const navigation = useNavigation();

    const styles = StyleSheet.create({
        centerPage: {
            marginVertical: 40,
            alignItems: 'center',
        },
        boldText: {
            fontWeight: 'bold'
        },
        description: {
            marginTop: 10,
            lineHeight: 20
        }
    });

    return (
        <>
            <Surface>
                <Surface>
                    <View style={{padding: 20, alignItems: 'center'}}>
                        <Header40>How It Works</Header40>
                        <SubText style={{margin: 20, lineHeight: 20}}>
                            With direct access testing, you can order your own tests without a provider’s
                            order, and get results sent directly to the {session?.lab?.name || ''} patient portal.
                        </SubText>
                    </View>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                        <HowItWorksStep icon={() => <MaterialCommunityIcons name={'cart'} color={MD2Colors.white} size={24} />}
                                        stepCount={1}
                                        description='Choose the tests and add them to your cart'
                        />
                        <HowItWorksStep icon={() => <MaterialCommunityIcons name={'credit-card'} color={MD2Colors.white} size={24} />}
                                        stepCount={2}
                                        description='Purchase your tests with a credit card'
                        />
                        <HowItWorksStep icon={() => <MaterialCommunityIcons name={'email-newsletter'} color={MD2Colors.white} size={24} />}
                                        stepCount={3}
                                        description='Bring your confirmation email to one of our patient service centers to start the testing process'
                        />
                        <HowItWorksStep icon={() => <MaterialCommunityIcons name={'file-plus'} color={MD2Colors.white} size={24} />}
                                        stepCount={4}
                                        description={`View results online from the ${session?.lab?.name || ''} patient portal`}
                        />
                    </View>
                </Surface>

                <Surface style={{marginTop: 10, padding: 10}}>
                    <HowItWorksContent
                        title={<Header30>Shopping For Tests</Header30>}
                        description={<SubText style={styles.description}>
                            You are able to add tests and/or bundles to your shopping cart from both the directory
                            as well as the individual test page. You cannot have tests or bundles in your shopping
                            cart that contain duplicate tests. If you try to add a duplicate test, you will have the
                            option of keeping your original test in the cart or replacing it with the new test.
                        </SubText>}
                        image={{uri: DatImageUtility.getJpgImageUrl(session?.lab?.id, 'how_it_works_shopping')} as ImageURISource}
                    />
                    <Divider/>
                    <HowItWorksContent
                        title={<Header30>Purchasing Tests</Header30>}
                        reverse={true}
                        description={<SubText style={styles.description}>
                            Before checkout, you will have to sign into the {session?.lab?.name || ''} patient
                            portal or create a portal account. Creating an account is the best way to connect and
                            view your results. After creating an account, you can pay for your shopping cart with a
                            credit card. Once your purchase is complete you will receive an order confirmation that
                            you can bring with you to the lab in order to have your testing done.
                        </SubText>}
                        image={{uri: DatImageUtility.getJpgImageUrl(session?.lab?.id, 'how_it_works_purchasing')} as ImageURISource}
                    />
                    <Divider/>
                    <HowItWorksContent
                        title={<Header30>Appearing For Tests</Header30>}
                        description={<SubText style={styles.description}>
                            Bring your confirmation email (via print, or on your phone) to one of our patient
                            service centers during business hours to collect your specimen and to start the testing
                            process. Click <SubText style={{textDecorationLine: 'underline'}}
                                                    onPress={() => navigation.navigate('ContactTab')}>here</SubText>&nbsp;to
                            find a testing center near you.
                        </SubText>}
                        image={{uri: DatImageUtility.getJpgImageUrl(session?.lab?.id, 'how_it_works_appearing')} as ImageURISource}
                    />
                    <Divider/>
                    <HowItWorksContent
                        title={<Header30>Accessing Your Results</Header30>}
                        reverse={true}
                        description={<SubText style={styles.description}>
                            You will receive an email once your test results are available to access within your
                            patient portal account. Please note that it is important to include a valid phone number
                            as a clinician will attempt to contact you 3 times if a laboratory test result is
                            critical.
                        </SubText>}
                        image={{uri: DatImageUtility.getJpgImageUrl(session?.lab?.id, 'how_it_works_accessing')} as ImageURISource}
                    />
                </Surface>
            </Surface>
        </>
    );
}