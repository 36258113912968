import {LayoutChangeEvent, ScrollView, StyleProp, View, ViewStyle} from "react-native";
import {ActivityIndicator, Surface} from "react-native-paper";
import {useEffect, useState} from "react";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import {PublicOrderableTestService, OrderableTest, DuplicateTestException} from "@luminate/luminate-ts-sdk";

import {Overlay} from "../common/Overlay";
import {useAuth} from "../../contexts/AuthContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {CollectAcknowledgments} from "./CollectAcknowledgments";
import {Header18} from "../typography";
import {AddToCartSuccessful} from "./AddToCartSuccessful";
import {DuplicateTestFound} from "./DuplicateTestFound";
import {KeepSelectionSuccessful} from "./KeepSelectionSuccessful";
import {UndoSelectionSuccessful} from "./UndoSelectionSuccessful";
import {AddToCartError} from "./AddToCartError";
import {DeviceType, useResponsive} from "../hooks/useResponsive";
import Environment from "../../models/Environment";
import {AddToCardEvent, AnalyticsService} from "../../services/AnalyticsService";
import {TestToAnalyticsItemConverter} from "../../services/converter/TestToAnalyticsItemConverter";

export type Layout = {
    width?: number;
    height?: number;
};

export interface AddToCartOverlayProps {
    style?: StyleProp<ViewStyle>;
    layout?: Layout;
    test: OrderableTest;
    code: string;
    testIdToAdd: number;
    onClose: () => void | undefined;
    onShowCart: () => void | undefined;
}

export const AddToCartOverlay = (props: AddToCartOverlayProps) => {
    const {acknowledgmentsCollected, recordAcknowledgementsCollected} = useAuth();
    const {theme} = useThemeContext();
    const {authData, session, addTestToCart, removeTestFromCart} = useAuth();
    const [processing, setProcessing] = useState<boolean>(false);
    const [processingMsg, setProcessingMsg] = useState<string>();
    const [overlayWidth, setOverlayWidth] = useState<number>();
    const [overlayHeight, setOverlayHeigth] = useState<number>();
    const [testToAdd, setTestToAdd] = useState<OrderableTest>();

    const {screenWidth, deviceType} = useResponsive();
    const sidePanelWidth = deviceType === DeviceType.MOBILE ? screenWidth * .95 : deviceType === DeviceType.TABLET ? screenWidth * .5 : screenWidth * .33;

    const [addSuccessful, setAddSuccessful] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [keepSuccessful, setKeepSuccessful] = useState(false);
    const [undoSuccessful, setUndoSuccessful] = useState(false);
    const [addError, setAddError] = useState(false);
    const [duplicateTests, setDuplicateTests] = useState<Array<OrderableTest>>(new Array<OrderableTest>());

    const onMount = async () => {
        if (acknowledgmentsCollected) {
            await performAddToCartOperation();
        }
    };

    useEffect(() => {
        onMount();
    }, []);

    const performAddToCartOperation = async () => {
        setProcessing(true);
        setProcessingMsg('Adding Test To Cart ...');

        if (!testToAdd) {
            setTestToAdd(await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listOrderableTestDetails(props.testIdToAdd));
        }

        try {
            await addTestToCart(props.testIdToAdd as number);
            AnalyticsService.create().sendEvent(
                new AddToCardEvent(
                    session?.lab.id as number,
                    props.test.price / 100,
                    [TestToAnalyticsItemConverter(props.test)],
                    authData?.patientId,
                )
            );
            setAddSuccessful(true);
        } catch (ex) {
            if (ex instanceof DuplicateTestException) {
                setDuplicateTests(ex.duplicateTests);
                setShowDuplicate(true);
            } else {
                setAddError(true);
            }
        } finally {
            setProcessing(false);
        }
    };

    const onContinueClicked = async () => {
        await recordAcknowledgementsCollected();
        await performAddToCartOperation();
    };

    const onAddToCartOverlayLayoutChanged = (event: LayoutChangeEvent) => {
        setOverlayWidth(event.nativeEvent.layout.width);
        setOverlayHeigth(event.nativeEvent.layout.height);
    };

    const onKeepSelection = async () => {
        setShowDuplicate(false);
        setProcessing(true);
        setProcessingMsg('Updating Cart ...');
        try {
            for await (const duplicate of duplicateTests) {
                await removeTestFromCart(duplicate.testId);
            }

            await addTestToCart(props.testIdToAdd as number);
            setKeepSuccessful(true);
        } catch (ex) {
            setAddError(true);
        } finally {
            setProcessing(false);
        }
    };

    const onUndo = async () => {
        setKeepSuccessful(false);
        setProcessing(true);
        setProcessingMsg('Updating Cart ...');

        try {
            await removeTestFromCart(testToAdd?.testId || 0);

            for await (const duplicate of duplicateTests) {
                await addTestToCart(duplicate.testId);
            }

            setUndoSuccessful(true);
        } catch (ex) {
            setAddError(true);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <Overlay style={props.style} height={props.layout?.height} width={props.layout?.width}>
            <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                <Surface style={{minWidth: 280, width: sidePanelWidth}}
                         onLayout={onAddToCartOverlayLayoutChanged}>
                    <ScrollView>
                        <View style={{flexDirection: 'row-reverse'}}>
                            <MaterialCommunityIcons name={'close-circle'}
                                                    size={18}
                                                    color={theme.colors.primary}
                                                    style={{marginTop: 10, marginRight: 10}}
                                                    onPress={props.onClose}
                            />
                        </View>
                        {processing
                            ? <Overlay style={{flex: 1, zIndex: 99, justifyContent: 'center', alignItems: 'center'}}
                                       width={overlayWidth} height={overlayHeight}>
                                <Surface style={{padding: 20, borderRadius: 10}}>
                                    <Header18>{processingMsg}</Header18>
                                    <ActivityIndicator animating={true} size={'large'}/>
                                </Surface>
                            </Overlay>
                            : <></>
                        }
                        {!acknowledgmentsCollected
                            ? <CollectAcknowledgments
                                onCancel={props.onClose}
                                onContinue={onContinueClicked}/>
                            : <></>
                        }
                        {addSuccessful
                            ? <AddToCartSuccessful testName={testToAdd?.name as string}
                                                   testComponents={testToAdd?.orderableTests || []}
                                                   onShowCart={props.onShowCart}
                                                   onClose={props.onClose}
                            />
                            : <></>
                        }
                        {showDuplicate
                            ?
                            <DuplicateTestFound testName={testToAdd?.name as string}
                                                duplicateTests={duplicateTests || []}
                                                onClose={props.onClose}
                                                onKeepSelection={onKeepSelection}
                            />
                            : <></>
                        }
                        {keepSuccessful
                            ? <KeepSelectionSuccessful testName={testToAdd?.name as string}
                                                       duplicateTests={duplicateTests || []}
                                                       testComponents={testToAdd?.orderableTests || []}
                                                       onClose={props.onClose}
                                                       onShowCart={props.onShowCart}
                                                       onUndo={onUndo}
                            />
                            : <></>
                        }
                        {undoSuccessful
                            ? <UndoSelectionSuccessful testName={testToAdd?.name as string}
                                                       duplicateTests={duplicateTests || []}
                                                       onClose={props.onClose}
                                                       onShowCart={props.onShowCart}
                            />
                            : <></>
                        }
                        {addError
                            ? <AddToCartError testName={testToAdd?.name as string} onClose={props.onClose}/>
                            : <></>
                        }
                    </ScrollView>
                </Surface>
            </View>
        </Overlay>
    );
};