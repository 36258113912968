import {useNavigation} from "@react-navigation/native";

import {SubText} from "../typography";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const PplSchedulingTests = () => {
    const navigation = useNavigation();
    return (
        <SubText>
            To get tested come in to our patient service center, no appointment is necessary. Click <SubText
            style={{textDecorationLine: 'underline'}}
            onPress={() => navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS)}>here</SubText> for our location.
        </SubText>
    );
}