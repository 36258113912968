import {loadStripe} from '@stripe/stripe-js';
import type { Stripe, ConfirmCardPaymentData, PaymentIntentResult } from '@stripe/stripe-js';
import type { StripeCardElement } from '@stripe/stripe-js/types/stripe-js/elements';
import type { CreatePaymentMethodCardData } from '@stripe/stripe-js/types/stripe-js/payment-intents';

import { PaymentException } from '@luminate/luminate-ts-sdk';

import Environment from "../models/Environment";
import {StripePaymentMetadata} from "../models";

export class StripeService {
  constructor(private readonly stripeClient: Stripe) {}

  public static create = async (labId: number): Promise<StripeService> => {
    const STRIPE_PUBLIC_KEY = Environment.env ? Environment.env[`ENV_STRIPE_PUBLIC_KEY_LAB_${labId}`] : 'not-configured';
    const stripeClient = await loadStripe(STRIPE_PUBLIC_KEY);

    if (!stripeClient) {
      throw new PaymentException('Unable to load Stripe.');
    }

    return new StripeService(stripeClient);
  };

  /**
   * Generates a new Credit Card element to be rendered in the browser.
   * @returns {StripeCardElement} The element to be mounted.
   */
  public createCard = async (zipCode?: string): Promise<StripeCardElement> => {
    const elements = this.stripeClient.elements();
    return elements.create('card', {  value: {postalCode: zipCode} });
  };

  /**
   * Submit a payment to Stripe.
   * @param {string} stripeClientSecret Unique identifier for the payment that will be submitted.
   * @param {string} billingDetails Billing details to be recorded with the payment.
   * @param {StripeCardElement} card Stripe credit card element used to submit with the payment request.
   * @param {StripePaymentMetadata} metadata Metadata to be added to the payment intent.
   * @returns {PaymentIntentResult} The result of the payment attempt.
   */
  public submitPayment = async (stripeClientSecret: string, billingDetails: unknown, card: StripeCardElement, metadata: unknown): Promise<PaymentIntentResult> => {
    const confirmCardPaymentData = {
      payment_method: {
        billing_details: billingDetails,
        card: card,
        metadata
      } as CreatePaymentMethodCardData,
    } as ConfirmCardPaymentData;

    return await this.stripeClient.confirmCardPayment(stripeClientSecret, confirmCardPaymentData);
  };
}
