import {View} from "react-native";
import {DefaultPurchaseConditions} from "./DefaultPurchaseConditions";
import {PplPurchaseConditions} from "./PplPurchaseConditions";
import {useAuth} from "../../contexts/AuthContext";
import {LabIds} from "../../models";
import {Header18} from "../typography";
import {HtmlView} from "../common/HtmlView";
import React from "react";
import {HelperText} from "react-native-paper";
import {DatComponent} from "@luminate/luminate-ts-sdk";

export const PurchaseConditions = (props: { componentData?: DatComponent }) => {
    const {useLab} = useAuth();

    return (
        <View style={{marginTop: 10}}>
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <Header18>Purchasing Conditions</Header18>}
            {useLab(LabIds.PPL) && <PplPurchaseConditions/>}
            {useLab(LabIds.ALVERNO) && <DefaultPurchaseConditions/>}
            {
                !(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && props.componentData?.enabled ?
                    props.componentData?.error ?
                        <HelperText type={"error"}>{props.componentData?.content.content}</HelperText>
                        : <>
                            <Header18>{props.componentData?.content.header}</Header18>
                            <HtmlView>{props.componentData?.content.content}</HtmlView>
                        </>
                    : <></>
            }
        </View>
    )
}