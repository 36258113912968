import {useNavigation} from "@react-navigation/native";

import {SubText} from "../typography";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const PplPurchaseConditions = () => {
    const navigation = useNavigation();

    return (
        <ul>
            <li><SubText>Purchaser must be 18 years old or older.</SubText></li>
            <li><SubText>Purchaser can only purchase tests for themselves.</SubText></li>
            <li>
                <SubText>
                    I understand that Walk-In Wellness Lab Testing does not replace the advice and care of my physician.
                    It is intended for educational purposes only. A Walk-In Wellness Test result is not a medical
                    diagnosis, a treatment, or a form of medical advice.I am solely responsible for promptly talking
                    with a physician about my lab test results. I understand that only my physician can interpret my
                    test results.
                </SubText>
            </li>
            <li>
                <SubText>
                    I agree to the <SubText style={{textDecorationLine: 'underline'}}
                                            onPress={() => navigation.navigate(SCREEN_NAMES.TERMS_OF_SERVICE)}>Luminate
                    Health Terms of Service</SubText>. Your information will be handled by Luminate Health in accordance
                    with its <SubText style={{textDecorationLine: 'underline'}}
                                      onPress={() => navigation.navigate(SCREEN_NAMES.PRIVACY_POLICY)}>Privacy
                    Policy</SubText>.
                </SubText>
            </li>
            <li>
                <SubText>
                    I understand that, because a physician has not ordered these tests, my health insurance (including
                    Medicare and Medicaid) will not pay for these tests. I will not ask my health insurer, Medicare,
                    Medicaid, or any other federal or state health program to pay for these tests.
                </SubText>
            </li>
            <li>
                <SubText>
                    I release and will not hold PPL Walk-In Wellness Lab responsible if I do not promptly communicate
                    the results of these test to my physician.
                </SubText>
            </li>
            <li>
                <SubText>
                    I understand that I am not entering a lab/patient relationship by ordering this test.
                </SubText>
            </li>
            <li>
                <SubText>
                    I understand that I am responsible for accessing my results and providing them to my physician.
                </SubText>
            </li>
        </ul>
    );
}