import React, { useEffect, useRef, useState } from "react";
import {
    Animated,
    LayoutChangeEvent,
    ListRenderItemInfo,
    ScrollView,
    StyleSheet,
    View
} from "react-native";
import { ActivityIndicator, Surface, Text, Title, TouchableRipple } from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {
    CollectionType,
    IndividualTest,
    OrderableTest,
    PublicOrderableTestService
} from "@luminate/luminate-ts-sdk";

import { Images } from "../../models";
import { Currency } from "../common/Currency";
import { SampleTypeIndicator } from "../orderableTests/SampleTypeIndicator";
import { AgeIndicator } from "../orderableTests/AgeIndicator";
import { FastingIndicator } from "../orderableTests/FastingIndicator";
import { BundleInclusion } from "../orderableTests/BundleInclusion";
import { ErrorDisplay } from "../common/ErrorDisplay";
import { useThemeContext } from "../../contexts/ThemeContext";
import { Button } from "../common/Button";
import { DatImageUtility } from "../../services/DatImageUtility";
import { useAuth } from "../../contexts/AuthContext";
import { PageFooter } from "../common/PageFooter";
import { ReactiveFlatList } from "../common/ReactiveFlatList";
import { AddToCartOverlay, Layout } from "../orderableTests/AddToCartOverlay";
import { SCREEN_NAMES } from "../routes/AppStackWeb";
import { Header30, SubText } from "../typography";
import Environment from "../../models/Environment";
import { AnalyticsService, ViewItemEvent } from "../../services/AnalyticsService";
import { TestToAnalyticsItemConverter } from "../../services/converter/TestToAnalyticsItemConverter";
import { FastingDetails } from "../fasting";
import { HtmlView } from "../common/HtmlView";
import { TurnAroundTimeIndicator } from "../orderableTests/TurnAroundTimeIndicator";

const H_SCROLL_DISTANCE = 30;
const DEFAULT_HEADER_HEIGHT = 169;

export const OrderableTestDetailsScreen = () => {
    const navigation = useNavigation();
    const route = useRoute();
    const { session, authData } = useAuth();
    const { theme } = useThemeContext();
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);
    const [details, setDetails] = useState<OrderableTest>();
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [overlayLayout, setOverlayLayout] = useState<Layout>();
    const [extendedTestId, setExtendedTestId] = useState(0)

    const getInclusionTitle = (collectionType: CollectionType | undefined): string => {
        switch (collectionType) {
            case CollectionType.BUNDLE:
                return 'Bundle Includes:';
            case CollectionType.PANEL:
                return 'Test Includes:';
            default:
                return '';
        }
    };

    const inclusionTitle = getInclusionTitle(details?.collectionType);

    const loadTestDetails = async (testId: number) => {
        setLoading(true);
        try {
            const testDetails = await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listOrderableTestDetails(testId);
            setDetails(testDetails);
            if (testDetails) {
                AnalyticsService.create().sendEvent(
                    new ViewItemEvent(
                        session?.lab.id as number,
                        testDetails.price / 100,
                        [TestToAnalyticsItemConverter(testDetails)],
                        authData?.patientId,
                    )
                );
            }
        } catch (ex) {
            setLoadError(true);
        } finally {
            setLoading(false);
        }
    };

    const onViewTestDetails = async (test: OrderableTest) => {
        // @ts-ignore
        if (test.visible) {
            navigation.push('OrderableTestDetailsScreen', { testId: test.testId });
        } else {
            for (let i in details?.orderableTests) {
                let orderableTest = details?.orderableTests[i];
                if (orderableTest.testId === test.testId) {
                    if (orderableTest.collectionType == 'PANEL' && !orderableTest.visible) {
                        details.orderableTests[i].orderableTests = (await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listOrderableTestDetails(orderableTest.testId))?.orderableTests;
                    }
                }
            }
            setExtendedTestId(test.testId)
        }
    };

    const onAddTestToCart = () => {
        setShowOverlay(true);
    };

    const renderBundleInclusion = (test: ListRenderItemInfo<OrderableTest>) => {
        return (
            <TouchableRipple onPress={() => onViewTestDetails(test.item)}>
                <BundleInclusion testId={test.item.testId} name={test.item.name}
                    description={test.item.shortDescription} tests={test.item.orderableTests}
                    orderable={test.item.visible} extended={test.item.testId === extendedTestId}
                />
            </TouchableRipple>
        );
    };

    const renderPanelInclusion = (test: IndividualTest) => {
        return (
            <Text key={`inclusion-${test.testId}`} style={{ fontSize: 15 }}>
                <li>{test.name}</li>
            </Text>
        )
    };

    useEffect(() => {
        // @ts-ignore
        loadTestDetails(route?.params?.testId);
        // @ts-ignore
    }, [route?.params?.testId]);

    const styles = StyleSheet.create({
        rootContainer: {
            flex: 1
        },
        contentContainer: {
            marginLeft: 20,
            marginRight: 20
        },
        currency: {
            fontSize: 24,
            fontWeight: 'bold',
            color: theme.colors.primary,
            marginBottom: 10,
            textAlign: 'center'
        },
        testTitle: {
            fontSize: 30,
            fontWeight: 'bold',
            color: theme.colors.primary,
        },
        testDescription: {
            fontSize: 15,
            marginTop: 20,
            fontFamily: theme.fonts.bodyMedium.fontFamily,
            fontWeight: theme.fonts.bodyMedium.fontWeight
        },
        inclusionHeader: {
            fontSize: 24,
            fontWeight: 'bold',
            color: theme.colors.primary,
            marginTop: 20,
            marginBottom: 10,
        },
        testImage: {
            width: 207,
            height: 117,
            borderRadius: 12
        },
        titleContainer: {
            flex: 1,
            justifyContent: 'center'
        },
        indicatorContainer: {
            marginTop: 10,
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        actionContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 20,
        },
        addToCartContentStyle: {
            flexDirection: 'row-reverse'
        },
        addToCartIconStyle: {
            color: theme.colors.surface
        }
    });

    const onCloseOverlay = () => {
        setShowOverlay(false);
    };

    const onShowCart = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.CART);
    };

    const onScreenLayoutChanged = (event: LayoutChangeEvent) => {
        setOverlayLayout(event.nativeEvent.layout);
    };

    const [headerOffset, setHeaderOffset] = useState(DEFAULT_HEADER_HEIGHT);
    const scrollY = useRef(new Animated.Value(0)).current;
    const onHeaderLayoutChanged = (event: LayoutChangeEvent) => {
        setHeaderOffset(event.nativeEvent.layout.height);
    };

    const [showIndicators, setShowIndicators] = useState<boolean>(true);
    const indicatorOpacityInterpolation = scrollY.interpolate({
        inputRange: [0, H_SCROLL_DISTANCE],
        outputRange: [1, 0],
        extrapolate: 'clamp'
    })

    indicatorOpacityInterpolation.addListener((value) => {
        setShowIndicators(value.value > 0);
    });

    const [showImage, setShowImage] = useState<boolean>(true);
    const imageOpacityInterpolation = scrollY.interpolate({
        inputRange: [0, H_SCROLL_DISTANCE],
        outputRange: [1, 0],
        extrapolate: 'clamp'
    });
    imageOpacityInterpolation.addListener((value) => {
        setShowImage(value.value > 0);
    });

    const imageHeightInterpolation = scrollY.interpolate({
        inputRange: [0, H_SCROLL_DISTANCE],
        outputRange: [117, 0]
    });

    const imageWidthInterpolation = scrollY.interpolate({
        inputRange: [0, H_SCROLL_DISTANCE],
        outputRange: [207, 0]
    });

    const backToCatalog = () => {
        setLoadError(false);
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate(SCREEN_NAMES.BROWSE);
    };

    return (
        <>
            {loadError
                ? <ErrorDisplay style={{ margin: 10 }}
                    message={'We were unable to load the details for the selected test at this time.  Please try again later.'}>
                    <Button icon={() => <MaterialIcons name={'chevron-left'} size={32} color={theme.colors.onPrimary} />}
                        onPress={() => backToCatalog()}>Return To Catalog</Button>
                </ErrorDisplay>
                : loading
                    ? <ActivityIndicator style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                        animating={true} size="large" />
                    : <View style={{ flex: 1 }} onLayout={onScreenLayoutChanged}>
                        <Animated.View
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                width: '100%',
                                zIndex: 999
                            }}
                        >
                            <Surface style={{ padding: 10 }} onLayout={onHeaderLayoutChanged}>
                                <TouchableRipple onPress={() => backToCatalog()}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <MaterialIcons name={'chevron-left'} size={32} color={theme.colors.primary} />
                                        <SubText style={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            textDecorationLine: 'underline',
                                        }}>
                                            Return To Catalog
                                        </SubText>
                                    </View>
                                </TouchableRipple>
                                <View style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    padding: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Animated.Image
                                        source={{ uri: DatImageUtility.getJpgImageUrl(session?.lab.id, details?.code) }}
                                        defaultSource={Images.placeholder}
                                        style={[
                                            styles.testImage
                                            , {
                                                opacity: imageOpacityInterpolation,
                                                height: imageHeightInterpolation,
                                                width: imageWidthInterpolation
                                            }
                                            , !showImage ? {
                                                display: 'none'
                                            } : {}
                                        ]}
                                        resizeMode={'cover'}
                                    />
                                    <View style={{ flex: 2, minWidth: 280, padding: 10 }}>
                                        <Header30>{details?.name}</Header30>
                                        <Animated.View style={
                                            [
                                                {
                                                    opacity: indicatorOpacityInterpolation,
                                                },
                                                !showIndicators ?
                                                    {
                                                        display: 'none'
                                                    } : {}
                                            ]
                                        }>
                                            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {details?.sampleType
                                                    ? <SampleTypeIndicator style={{ paddingRight: 10 }}
                                                        size={24}
                                                        type={details?.sampleType} />
                                                    : <></>
                                                }
                                                {!details?.minors
                                                    ? <AgeIndicator style={{ paddingRight: 10 }} />
                                                    : <></>
                                                }
                                                {details?.fasting
                                                    ? <FastingIndicator size={24} />
                                                    : <></>
                                                }
                                                {details?.turnAroundTime
                                                    ? <TurnAroundTimeIndicator turnAroundTime={details.turnAroundTime} />
                                                    : <></>
                                                }
                                            </View>
                                        </Animated.View>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            minWidth: 170,
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                        }}>
                                        {details?.visible &&
                                            <View style={{ flex: 1, alignItems: 'center' }}>
                                                <Currency value={details?.price} style={styles.currency} />
                                                <Button disabled={loadError}
                                                    onPress={onAddTestToCart}
                                                    icon={() => <MaterialIcons style={styles.addToCartIconStyle}
                                                        name={'add-shopping-cart'}
                                                        size={24} />}>
                                                    Add To Cart
                                                </Button>
                                            </View>
                                        }
                                    </View>
                                </View>
                            </Surface>
                        </Animated.View>
                        <ScrollView
                            onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false })}
                            scrollEventThrottle={16}
                        >
                            <View
                                style={{ flexDirection: 'row', margin: 10, flexWrap: 'wrap', paddingTop: headerOffset }}>
                                <View style={{ flex: 2, minWidth: 280, padding: 10 }}>
                                    <HtmlView style={{ marginVertical: 20 }}>
                                        {details?.longDescription}
                                    </HtmlView>
                                    {details?.orderableTests
                                        ? <>
                                            <Title style={styles.inclusionHeader}>{inclusionTitle}</Title>
                                            {details?.collectionType === CollectionType.BUNDLE
                                                ? <ReactiveFlatList data={details?.orderableTests}
                                                    itemMinimumWidth={560}
                                                    numColumns={2}
                                                    horizontal={false}

                                                    renderItem={renderBundleInclusion}
                                                    keyExtractor={(item) => item.testId.toString()}
                                                />
                                                : <ul>
                                                    {details?.orderableTests.map(renderPanelInclusion)}
                                                </ul>
                                            }
                                        </>
                                        : <></>
                                    }
                                </View>
                                <View style={{ flex: 1, minWidth: 280 }}>
                                    <FastingDetails fasting={details?.fasting || false} />
                                </View>
                            </View>
                            <PageFooter />
                        </ScrollView>
                        {showOverlay
                            ? <AddToCartOverlay
                                //@ts-ignore
                                testIdToAdd={route.params.testId as number}
                                //@ts-ignore
                                test={details}
                                //@ts-ignore
                                code={details?.code}
                                style={{ zIndex: 999 }}
                                layout={overlayLayout}
                                onClose={onCloseOverlay}
                                onShowCart={onShowCart} />
                            : <></>
                        }
                    </View>
            }
        </>
    );
};