import {createContext, ReactNode, useContext, useState} from "react";

import {LuminateDefaultTheme, LuminateTheme} from "../styles/LuminateTheme";

export type ThemeContextData = {
    theme: LuminateTheme,
    switchTheme(value: LuminateTheme): void;
};

export const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

export type ThemeProviderProps = {
    children: ReactNode;
};

export const ThemeProvider = (props: ThemeProviderProps) => {
    const [theme, setTheme] = useState<LuminateTheme>(LuminateDefaultTheme);

    const switchTheme = (value: LuminateTheme) => {
        setTheme(value);
    };

    return <ThemeContext.Provider
        value={{theme, switchTheme} as ThemeContextData}>{props.children}</ThemeContext.Provider>
};

export const useThemeContext = (): ThemeContextData => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw Error('useThemeContext must be used with ThemeProvider');
    }
    return context;
}