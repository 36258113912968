import React from "react";
import {createStackNavigator, StackNavigationProp} from "@react-navigation/stack";
import {MaterialBottomTabNavigationProp} from "@react-navigation/material-bottom-tabs";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {PubNubProvider} from "pubnub-react";
import PubNub from "pubnub";

import {ShoppingCartScreen} from "../screens/ShoppingCartScreen";
import {CheckoutScreenWeb} from "../screens/CheckoutScreenWeb";
import {OrderConfirmationScreen} from "../screens/OrderConfirmationScreen";
import {OrderConfirmationPdfWebScreen} from "../screens/OrderConfirmationPdfWebScreen";
import {AppTabNavigatorStackParams} from "./AppStack";
import {EditProfileScreen} from "../screens/EditProfileScreen";
import Environment from "../../models/Environment";

export type CartStackWebNavigationParams = {
    ShoppingCart: undefined,
    FAQ: undefined,
    Checkout: undefined,
    OrderConfirmation: undefined,
    OrderPdfWeb: { orderId: number, oderNumber: string },
};
const Stack = createStackNavigator<CartStackWebNavigationParams>();

export type ShoppingCartWebNavigationScreenProps = CompositeNavigationProp<StackNavigationProp<CartStackWebNavigationParams, 'ShoppingCart'>, MaterialBottomTabNavigationProp<AppTabNavigatorStackParams, 'CartTab'>>;
export type CheckoutScreenWebNavigationScreenProps = CompositeNavigationProp<StackNavigationProp<CartStackWebNavigationParams, 'Checkout'>, MaterialBottomTabNavigationProp<AppTabNavigatorStackParams, 'CartTab'>>;
export type OrderConfirmationWebNavigationScreenProps = CompositeNavigationProp<StackNavigationProp<CartStackWebNavigationParams, 'OrderConfirmation'>, MaterialBottomTabNavigationProp<AppTabNavigatorStackParams, 'CartTab'>>;
export type OrderPdfWebNavigationScreenProps = CompositeNavigationProp<StackNavigationProp<CartStackWebNavigationParams, 'OrderPdfWeb'>, MaterialBottomTabNavigationProp<AppTabNavigatorStackParams, 'CartTab'>>;
export type OrderConfirmationPdfWebScreenRouteProp = RouteProp<CartStackWebNavigationParams, 'OrderPdfWeb'>;


export const CartStackWeb = () => {
    const pubNubClient = new PubNub({
        publishKey: Environment.pubNub?.publishKey as string,
        subscribeKey: Environment.pubNub?.subscribeKey as string,
        uuid: Environment.pubNub?.userId as string
    });

    return (
        <PubNubProvider client={pubNubClient}>
            <Stack.Navigator screenOptions={{headerShown: false}}>
                <Stack.Screen options={{title: 'Cart'}} name={'ShoppingCart'} component={ShoppingCartScreen}/>
                <Stack.Screen options={{headerShown: true, headerTitle: 'Checkout'}}
                              name={'Checkout'}
                              component={CheckoutScreenWeb}
                />
                <Stack.Screen name={'OrderConfirmation'} component={OrderConfirmationScreen}/>
                <Stack.Screen options={{headerShown: true, headerTitle: 'Order Confirmation'}}
                              name={'OrderPdfWeb'}
                              component={OrderConfirmationPdfWebScreen}
                />
                <Stack.Screen options={{headerShown: false, title: 'Edit My Profile'}}
                              name={'EditProfile'}
                              component={EditProfileScreen}
                />
            </Stack.Navigator>
        </PubNubProvider>
    );
};