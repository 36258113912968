import {useEffect, useRef, useState} from "react";
import {FlatList, ListRenderItemInfo, StyleSheet, View} from "react-native";
import {Card, FAB, IconButton, Searchbar, Surface, TextInput, TouchableRipple} from "react-native-paper";
import MapView from 'react-native-maps';
import humanizeDuration from 'humanize-duration';

import {Coordinate, LabLocation, LabLocationService} from "@luminate/luminate-ts-sdk";

import {useAuth} from "../../contexts/AuthContext";
import {LabLocationCard} from "../contact/LabLocationCard";
import {CoordinateToLatLngLiteralConverter} from "../../services/converter/CoordinateToLatLngLiteralConverter";
import {CoordinatesToLatLngBoundsConverter} from "../../services/converter/CoordinatesToLatLngBoundsConverter";
import {useThemeContext} from "../../contexts/ThemeContext";
import {Header40, SubText} from "../typography";
import {PageFooter} from "../common/PageFooter";
import {useResponsive} from "../hooks/useResponsive";
import TravelMode = google.maps.TravelMode;
import LatLngLiteral = google.maps.LatLngLiteral;
import DirectionsService = google.maps.DirectionsService;
import DirectionsRenderer = google.maps.DirectionsRenderer;
import Environment from "../../models/Environment";

export const LabLocationsScreenWeb = () => {
    const {theme} = useThemeContext();
    const ref = useRef<MapView>(null);
    const locationRef = useRef<FlatList>(null);
    const directionsService = useRef<DirectionsService>(new google.maps.DirectionsService());
    const directionsRenderer = useRef<DirectionsRenderer>(new google.maps.DirectionsRenderer());
    const {session, displaySnack} = useAuth();
    const [mapIsReady, setMapIsReady] = useState(false);
    const [locations, setLocations] = useState<Array<LabLocation>>([]);
    const [selectedLocation, setSelectedLocation] = useState<LabLocation>();
    const [filter, setFilter] = useState('');
    const [filtered, setFiltered] = useState<Array<LabLocation>>([]);
    const [showDirections, setShowDirections] = useState(false);
    const [fromAddress, setFromAddress] = useState('');
    const [routeDistance, setRouteDistance] = useState(0);
    const [routeDuration, setRouteDuration] = useState(0);
    const [routeSummary, setRouteSummary] = useState<string>();
    const {screenWidth} = useResponsive();

    const styles = StyleSheet.create({
        map: {
            flex: 1
        },
        rootContainer: {
            margin: 5,
            flexDirection: 'row',
            flexWrap: 'wrap-reverse'
        },
        locationsContainer: {
            flex: 1,
            maxHeight: screenWidth > 768 ? '83.2vh' : '53vh',
            minWidth: screenWidth <= 768 ? screenWidth - 10 : undefined
        },
        mapContainer: {
            flex: 1,
            minWidth: screenWidth <= 768 ? screenWidth - 10 : undefined,
            height: screenWidth > 768 ? '83.2vh' : '33vh',
            width: screenWidth > 768 ? screenWidth : screenWidth - 10,
        },
        header: {
            fontWeight: 'bold',
            fontSize: 40,
            color: theme.colors.primary,
            marginVertical: 10
        },
        locationHeader: {
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.colors.primary
        }
    });

    const labLocationService = LabLocationService.create(Environment.apiBaseUrl as string);

    const onLocationSelected = (location: LabLocation | undefined) => {
        if (selectedLocation !== location) {
            setSelectedLocation(location);
            setZoom(location?.coordinate);
            locationRef.current?.scrollToIndex({index: locations.indexOf(location as LabLocation)});
        } else {
            setSelectedLocation(undefined);
            locationRef.current?.scrollToIndex({index: 0});
            setMapBoundary(filtered);
        }
    };

    const loadLocations = async () => {
        const locations = await labLocationService.getLocations(session?.lab.id as number);
        setLocations(locations);
        setFiltered(locations);
        setMapIsReady(true);
    };

    useEffect(() => {
        loadLocations();
    }, []);

    const renderLocation = (locationItem: ListRenderItemInfo<LabLocation>) => {
        return (
            <TouchableRipple key={`location-touch-${locationItem.item.id}`}
                             onPress={() => onLocationSelected(locationItem.item)}>
                <LabLocationCard key={`location-card-${locationItem.item.id}`}
                                 id={locationItem.item.id}
                                 name={locationItem.item.name}
                                 address1={locationItem.item.address1}
                                 address2={locationItem.item.address2}
                                 city={locationItem.item.city}
                                 state={locationItem.item.state}
                                 zip={locationItem.item.zip}
                                 phone={locationItem.item.phone}
                                 fax={locationItem.item.fax}
                                 businessHours={locationItem.item.businessHours}
                                 coordinate={locationItem.item.coordinate}
                                 selected={selectedLocation === locationItem.item}
                                 onGetDirections={onGetDirections}
                />
            </TouchableRipple>
        );
    };

    const onMarkerPressed = (event: any): void => {
        const selected = locations.find(location => location.coordinate.latitude === event.latLng.lat() && location.coordinate.longitude === event.latLng.lng());
        onLocationSelected(selected);
    };

    const onLocationFilterChanged = (value: string): void => {
        setFilter(value);
    };

    const onLocationFilterSubmitEditing = (): void => {
        onPerformSearch(filter);
    };

    const setZoom = (coordinate?: Coordinate, zoom = 14, delay = 1): void => {
        setTimeout(() => {
            ref.current.animateCamera({center: CoordinateToLatLngLiteralConverter(coordinate), zoom});
        }, delay);
    };

    const setMapBoundary = (locations: Array<LabLocation>): void => {
        const bounds = CoordinatesToLatLngBoundsConverter(locations.map(location => location.coordinate));
        ref.current?.map.fitBounds(bounds);

        if (locations.length === 1) {
            setZoom();
        }
    };

    const onCloseDirections = async () => {
        setShowDirections(false);
        setSelectedLocation(undefined);
        setRouteSummary(undefined);
        setRouteDuration(0);
        setRouteDistance(0);
        directionsRenderer.current.set('directions', null);
        setMapBoundary(filtered);
    };

    const onGetDirections = async (locationId: number) => {
        setSelectedLocation(locations.find(location => location.id === locationId));
        setShowDirections(true);
    };

    const getGoogleMapInstance = () => {
        return ref.current.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    };

    const onNavigatePressed = async (mode: TravelMode) => {
        try {
            const theDestination = CoordinateToLatLngLiteralConverter(selectedLocation?.coordinate);

            const results = await directionsService.current.route({
                origin: fromAddress,
                destination: theDestination as LatLngLiteral,
                travelMode: mode,
            });

            const totalDistance = results.routes[0].legs.reduce((accumulator, current) => {
                return accumulator + (current.distance ? current.distance.value : 0);
            }, 0);
            setRouteDistance(totalDistance);

            const totalDuration = results.routes[0].legs.reduce((accumulator, current) => {
                return accumulator + (current.duration ? current.duration.value : 0);
            }, 0);
            setRouteDuration(totalDuration * 1000);

            setRouteSummary(results.routes[0].summary);

            if (!directionsRenderer.current.getMap()) {
                directionsRenderer.current.setMap(getGoogleMapInstance());
            }
            directionsRenderer.current.setDirections(results);
        } catch (ex) {
            directionsRenderer.current.set('directions', null);
            setRouteDistance(0);
            setRouteDuration(0);
            setRouteSummary(undefined);
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to retrieve directions for
                the selected travel mode. Please select another mode of transportation.</SubText>)
        }
    };

    const displaySearchError = () => {
        displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to find the specified location.
            Please check your search criteria or try again later.</SubText>)
    };

    const onPerformSearch = (filter: string): void => {
        if (filter) {
            try {
                const searchService = new google.maps.places.PlacesService(getGoogleMapInstance());
                searchService.findPlaceFromQuery({
                    query: filter,
                    fields: ['name', 'geometry']
                }, (placeResults, placeServiceStatus) => {
                    if (placeServiceStatus === google.maps.places.PlacesServiceStatus.OK && placeResults) {
                        const locationCoordinate = {
                            longitude: placeResults[0].geometry?.location?.lng(),
                            latitude: placeResults[0].geometry?.location?.lat()
                        } as Coordinate;

                        setZoom(locationCoordinate)
                    } else {
                        displaySearchError();
                    }
                });
            } catch (ex) {
                displaySearchError();
            }
        }
    };

    const onMapReady = (): void => {
        if (locations.length === 1) {
            onLocationSelected(locations[0]);
        } else {
            setMapBoundary(locations);
        }
    };

    const selectedAddress = (): string => {
        return `${selectedLocation?.address1} ${selectedLocation?.address2 ? selectedLocation?.address2 : ''} ${selectedLocation?.city} ${selectedLocation?.state} ${selectedLocation?.zip}`;
    };

    const onScrollToIndexFailed = (info: {
        index: number;
        highestMeasuredFrameIndex: number;
        averageItemLength: number;
    }): void => {
        const offset = info.averageItemLength * info.index;
        locationRef.current?.scrollToOffset({offset});
        setTimeout(() => locationRef.current?.scrollToIndex({index: info.index}), 400);
    }

    return (
        <Surface style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={styles.rootContainer}>
                <View style={styles.locationsContainer}>
                    {showDirections
                        ? <>
                            <Card>
                                <Card.Title title={'Directions'} titleStyle={styles.header}
                                            right={() => <IconButton icon={'close'} onPress={onCloseDirections}/>}/>
                                <Card.Content>
                                    <TextInput placeholder={'From'} value={fromAddress}
                                               onChangeText={(value: string) => setFromAddress(value)}/>
                                    <TextInput placeholder={'To'} value={selectedAddress()}/>
                                </Card.Content>
                                <Card.Actions style={{justifyContent: 'space-between'}}>
                                    <FAB icon={'car'} size={'small'}
                                         onPress={() => onNavigatePressed(TravelMode.DRIVING)}/>
                                    <FAB icon={'subway-variant'} size={'small'}
                                         onPress={() => onNavigatePressed(TravelMode.TRANSIT)}/>
                                    <FAB icon={'walk'} size={'small'}
                                         onPress={() => onNavigatePressed(TravelMode.WALKING)}/>
                                    <FAB icon={'bike'} size={'small'}
                                         onPress={() => onNavigatePressed(TravelMode.BICYCLING)}/>
                                </Card.Actions>
                            </Card>
                            {routeSummary
                                ? <Card>
                                    <Card.Title title={routeSummary} titleStyle={styles.locationHeader}/>
                                    <Card.Content>
                                        <SubText style={{lineHeight: 20}}>Distance {Math.round((routeDistance / 1609) * 10) / 10} miles</SubText>
                                        <SubText style={{lineHeight: 20}}>Duration {humanizeDuration(routeDuration)} minutes</SubText>
                                    </Card.Content>
                                </Card>
                                : <></>
                            }
                        </>
                        : <></>
                    }
                    {!showDirections
                        ? <>
                            <View>
                                <Header40 style={{margin: 10}}>Lab Locations</Header40>
                                <Searchbar value={filter}
                                           placeholder={'Search Location'}
                                           onChangeText={onLocationFilterChanged}
                                           onSubmitEditing={onLocationFilterSubmitEditing}
                                />
                            </View>
                            <FlatList data={filtered}
                                      ref={locationRef}
                                      renderItem={renderLocation}
                                      keyExtractor={(item) => item.id}
                                      onScrollToIndexFailed={onScrollToIndexFailed}
                            />
                        </>
                        : <></>
                    }
                </View>
                <View style={styles.mapContainer}>
                    {mapIsReady
                        ? <MapView
                            ref={ref}
                            style={styles.map}
                            initialRegion={{latitude: 0, longitude: 0, longitudeDelta: 0, latitudeDelta: 0}}
                            onMapReady={onMapReady}>
                            {
                                filtered.map(location => {
                                    return (
                                        <MapView.Marker key={`marker-${location.id}`}
                                                        coordinate={location.coordinate}
                                                        identifier={location.id}
                                                        title={`${location.state}, ${location.zip}`}
                                                        description={location.address1}
                                                        onPress={onMarkerPressed}
                                        />
                                    )
                                })
                            }
                        </MapView>
                        : <></>
                    }
                </View>
            </View>
            <PageFooter/>
        </Surface>
    );
};
