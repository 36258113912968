import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from "react-native";
import {Text} from "react-native-paper";
import React from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

export type PreviewListItemProps = {
    label?: string;
    value?: string;
    labelTextStyle?: StyleProp<TextStyle>;
    labelCellStyle?: StyleProp<ViewStyle>;
    valueTextStyle?: StyleProp<TextStyle>;
    valueCellStyle?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
};

export const PreviewListItem = (props: PreviewListItemProps) => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        rowStyle: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        labelCellStyle: {
            flex: 1,
            alignItems: 'flex-end',
            flexWrap: 'nowrap'
        },
        labelTextStyle: {
            fontSize: 18,
            color: theme.colors.primary
        },
        valueCellStyle: {
            flex: 1,
            paddingLeft: 10
        },
        valueTextStyle: {
            fontSize: 18
        }
    });
    return (
        <View style={[styles.rowStyle, props.containerStyle]}>
            <View style={[styles.labelCellStyle, props.labelCellStyle]}>
                <Text
                    style={[styles.labelTextStyle, props.labelTextStyle]}
                >
                    {props.label}
                </Text>
            </View>
            <View style={[styles.valueCellStyle, props.valueCellStyle]}>
                <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={[styles.valueTextStyle, props.valueTextStyle]}
                >
                    {props.value}
                </Text>
            </View>
        </View>
    );
};