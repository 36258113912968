import {ReactNode} from "react";
import {StyleProp, StyleSheet, TextStyle} from "react-native";
import {Text as PaperText} from "react-native-paper";

export type TextProps = {
    children?: ReactNode;
    style?: StyleProp<TextStyle>;
    onPress?: () => void;
};

export const Text = (props: TextProps) => {
    const styles = StyleSheet.create({
        text: {
            fontSize: 17,
            letterSpacing: 0.15,
        }
    });

    return (
        <PaperText style={[styles.text, props.style]} onPress={props.onPress}>{props.children}</PaperText>
    );
};