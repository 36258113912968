import React, {useState} from "react";
import {StyleSheet, View} from "react-native";

import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {Acknowledgments} from "../acknowledgments";

export interface CollectAcknowledgmentsProps {
    onContinue?: () => Promise<void> | undefined;
    onCancel?: () => void | undefined;
}

export const CollectAcknowledgments = (props: CollectAcknowledgmentsProps) => {
    const {theme} = useThemeContext();
    const {session} = useAuth();

    const [isAcknowledged, setIsAcknowledged] = useState(false);

    const styles = StyleSheet.create({
        buttonPadding: {
            margin: 10
        },
        textColor: {
            color: theme.colors.primary
        },
        heading: {
            paddingVertical: 10,
            paddingHorizontal: 5
        }
    });

    const onAcknowledgment = (acknowledged: boolean) => {
        setIsAcknowledged(acknowledged);
    };

    return (
        <View style={{alignItems: 'center'}}>
            <Acknowledgments labId={session?.lab?.id} onAcknowledgment={onAcknowledgment}/>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button style={styles.buttonPadding} disabled={!isAcknowledged} onPress={props.onContinue}>
                    Confirm
                </Button>
                <Button style={styles.buttonPadding} onPress={props.onCancel}>
                    Cancel
                </Button>
            </View>
        </View>
    );
};