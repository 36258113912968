import React, {useState} from "react";
import {LayoutChangeEvent, ScrollView, View} from "react-native";
import {Caption, Headline, Surface, Text, Title, TouchableRipple} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {LuminateStyles} from "../../styles/LuminateStyles";
import {TermsSection} from "../terms/TermsSection";
import {TermsParagraph} from "../terms/TermsParagraph";
import {UnorderedItem} from "../terms/UnorderedItem";
import {PageFooter} from "../common/PageFooter";
import {SubText} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";

export const PrivacyPolicyScreen = () => {
    const {theme} = useThemeContext();
    const navigation = useNavigation();
    const [headerPadding, setHeaderPadding] = useState(0);

    return (
        <>
            <ScrollView style={{paddingTop: headerPadding}}>
                <View style={{alignItems: 'center'}}>
                    <View style={{maxWidth: 768}}>
                        <View style={LuminateStyles.centeredInContainer}>
                            <Headline>Luminate Health, Inc.</Headline>
                            <Title>Privacy Policy</Title>
                            <Caption>Date of Last Revision: August 25, 2021</Caption>
                        </View>
                        <View style={{marginLeft: 10, marginRight: 10}}>
                            <TermsSection title={'Our Policy'}>
                                <TermsParagraph>
                                    Luminate Health, Inc. and its subsidiaries and affiliates (collectively, "Luminate",
                                    "we",
                                    "us" or "our") provide a web-based software to laboratories that allows their
                                    patients
                                    ("user", "users", "you", "your") to access, understand, and communicate lab test
                                    results
                                    (the "Services").
                                </TermsParagraph>
                                <TermsParagraph>
                                    This Product Privacy Statement ("Statement") explains how we collect, use, disclose,
                                    and
                                    otherwise process users' personal information on behalf of our laboratory customers
                                    ("customers") in connection with the Services. Luminate is a business
                                    associate/service
                                    provider and Luminate's customers are the covered entities/data controllers with
                                    respect
                                    to
                                    such personal information.
                                </TermsParagraph>
                                <TermsParagraph>
                                    Luminate's processing of personal information in connection with the Services is
                                    governed by
                                    this Statement and our customer agreements. In the event of any conflict between
                                    this
                                    Statement and a customer agreement, the customer agreement will control to the
                                    extent
                                    permitted by applicable law.
                                </TermsParagraph>
                                <TermsParagraph>
                                    Luminate customers will provide you a separate HIPAA privacy notice that will govern
                                    their
                                    collection, use and disclosure of your information. This Statement is not a
                                    substitute
                                    for
                                    any notice that Luminate's customers are required to provide to their users.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Information We Collect'}>
                                <TermsParagraph>
                                    We collect personal information directly from users, from our lab customers, and
                                    from
                                    users'
                                    health care providers. This information may include:
                                </TermsParagraph>
                                <UnorderedItem>
                                    <TermsParagraph title={'Account registration and contact details'}>
                                        , such as your first and last name, email and mailing addresses, phone number,
                                        username
                                        and password.
                                    </TermsParagraph>
                                </UnorderedItem>

                                <UnorderedItem>
                                    <TermsParagraph title={'Biographical and demographic information'}>
                                        , such as date of birth, age, and gender.
                                    </TermsParagraph>
                                </UnorderedItem>

                                <UnorderedItem>
                                    <TermsParagraph title={'Health and medical information'}>
                                        , such as information about health conditions and diagnoses, testing
                                        information,
                                        treatments, medical history, medications, and lab results.
                                    </TermsParagraph>
                                </UnorderedItem>

                                <UnorderedItem>
                                    <TermsParagraph title={'Communications '}>
                                        that we exchange with you, including when you contact us with questions,
                                        feedback,
                                        or
                                        otherwise.
                                    </TermsParagraph>
                                </UnorderedItem>

                                <UnorderedItem>
                                    <TermsParagraph title={'Payment and transactional data '}>
                                        needed to complete your test orders on or through the Services (including name,
                                        address,
                                        payment card information, bank account number, billing information), and your
                                        purchase
                                        history. Payment card information is processed by our payment service provider,
                                        Stripe,
                                        and we do not have access to full payment card numbers. Stripe may process your
                                        information in accordance with its privacy policy (https://stripe.com/privacy).
                                    </TermsParagraph>
                                </UnorderedItem>

                                <UnorderedItem>
                                    <TermsParagraph title={'Marketing data'}>
                                        , such as your preferences for receiving marketing communications from our
                                        customers,
                                        and details about your engagement with those communications.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <TermsParagraph title={'Automatic data collection.  '}>
                                    We and our service providers may automatically log information about you, your
                                    computer
                                    or
                                    mobile device, and your interaction over time with the Services, such as:
                                </TermsParagraph>
                                <UnorderedItem>
                                    <TermsParagraph title={'Device data'}>
                                        , such as your computer's or mobile device's operating system type and version,
                                        manufacturer and model, browser type, screen resolution, RAM and disk size, CPU
                                        usage,
                                        device type (e.g., phone, tablet), IP address, unique identifiers (including
                                        identifiers
                                        used for advertising purposes), language settings, mobile device carrier,
                                        radio/network
                                        information (e.g., WiFi, LTE, 3G), and general location information such as
                                        city,
                                        state
                                        or geographic area.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph title={'Online activity data'}>
                                        , such as pages or screens you viewed, how long you spent on a page or screen,
                                        the
                                        website you visited before browsing to the website, navigation paths between
                                        pages
                                        or
                                        screens, information about your activity on a page or screen, access times, and
                                        duration
                                        of access, and whether you have opened our marketing emails or clicked links
                                        within
                                        them.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <TermsParagraph title={'We use the following tools for automatic data collection:'}/>
                                <UnorderedItem>
                                    <TermsParagraph title={'Cookies'}>
                                        , which are text files that websites store on a visitor's device to uniquely
                                        identify
                                        the visitor's browser or to store information or settings in the browser for the
                                        purpose
                                        of helping you navigate between pages efficiently, remembering your preferences,
                                        enabling functionality, and helping us understand user activity and patterns.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph title={'Local storage technologies'}>
                                        , like HTML5, that provide cookie-equivalent functionality but can store larger
                                        amounts
                                        of data, including on your device outside of your browser in connection with
                                        specific
                                        applications.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph title={'Web beacons'}>
                                        , also known as pixel tags or clear GIFs, which are used to demonstrate that a
                                        webpage
                                        or email was accessed or opened, or that certain content was viewed or clicked.
                                    </TermsParagraph>
                                </UnorderedItem>
                            </TermsSection>
                            <TermsSection title={'How We Use Information'}>
                                <TermsParagraph>
                                    We use the information we collect at the instruction of our customers and in
                                    accordance
                                    with
                                    our customer agreements, to provide the Services and for related internal purposes,
                                    including:
                                </TermsParagraph>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To enable you to access and use the Services, including making your health
                                        information
                                        and test results available to you and your healthcare providers;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To provide information about the Services, such as important updates, security
                                        alerts or
                                        changes to the Services;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To inform you of our customers' new products and services that may be of
                                        interest
                                        you;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To respond to your inquiries, complaints, and requests for customer support;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To customize the user experience, such as personalizing content and features and
                                        informing you about content we believe may be of interest to you;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To conduct research regarding the Services;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To improve the Services and develop new products and services; and
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        To (a) comply with applicable law; (b) enforce the terms and conditions that
                                        govern
                                        the
                                        Services; (c) protect our rights, privacy, safety or property, and/or that of
                                        you or
                                        others; and (d) protect, investigate and deter against fraudulent, harmful,
                                        unauthorized, unethical or illegal activity.
                                    </TermsParagraph>
                                </UnorderedItem>
                            </TermsSection>
                            <TermsSection title={'How We Share Information'}>
                                <TermsParagraph>
                                    We share the information we collect:
                                </TermsParagraph>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        With Luminate's customers, to the extent the information pertains to the labs'
                                        own
                                        patients;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        With your health care providers, including access to your health information for
                                        treatment and healthcare operations as permitted under applicable law. For
                                        example,
                                        on
                                        behalf of customers, we make the results of current and prior lab tests
                                        available to
                                        your healthcare providers for treatment;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        With third party service providers that help us manage and improve the Services;
                                    </TermsParagraph>
                                </UnorderedItem>
                                <UnorderedItem>
                                    <TermsParagraph>
                                        With Luminate's subsidiaries and corporate affiliates.
                                    </TermsParagraph>
                                </UnorderedItem>
                                <TermsParagraph>
                                    We may also share personal information with government, law enforcement officials or
                                    private
                                    parties as required by law, when we believe such disclosure is necessary or
                                    appropriate
                                    to
                                    (a) comply with applicable law; (b) enforce the terms and conditions that govern the
                                    Service; (c) protect our rights, privacy, safety or property, and/or that of you or
                                    others;
                                    and (d) protect, investigate and deter against fraudulent, harmful, unauthorized,
                                    unethical
                                    or illegal activity.
                                </TermsParagraph>
                                <TermsParagraph>
                                    We may sell, transfer or otherwise share some or all of Luminate's business or
                                    assets,
                                    including personal information, in connection with a business deal (or potential
                                    business
                                    deal) such as a merger, consolidation, acquisition, reorganization or sale of assets
                                    or
                                    in
                                    the event of bankruptcy.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Information Security'}>
                                <TermsParagraph>
                                    Luminate uses appropriate, commercially reasonable physical, electronic, and
                                    procedural
                                    safeguards to protect personal data from loss, theft, misuse, and unauthorized
                                    access,
                                    disclosure, alteration, and destruction in accordance with applicable law. We
                                    cannot,
                                    however, guarantee that any safeguards or security measures will be sufficient to
                                    prevent a
                                    security problem. We recommend that our users and customers take steps to protect
                                    against
                                    unauthorized access to any devices or networks used to access the Services.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Children'}>
                                <TermsParagraph>
                                    The Services are not intended for use by children under 13 years of age. If we learn
                                    that we
                                    have collected personal information through the Services from a child under 13
                                    without
                                    the
                                    consent of the child's parent or guardian as required by law, we will delete it.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'International Data Transfer'}>
                                <TermsParagraph>
                                    We are headquartered in the United States and may use service providers that operate
                                    in
                                    other countries. Your personal information may be transferred to locations where
                                    privacy
                                    laws may not be as protective as those in your state, province, or country. Luminate
                                    may
                                    transfer personal information about users outside of the country in which users are
                                    located,
                                    including to the United States.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Data Subject Rights'}>
                                <TermsParagraph>
                                    Luminate's customers are responsible for receiving and responding to users' requests
                                    to
                                    exercise any rights afforded to them under applicable data protection law.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Creation of Anonymous and Aggregated Data for Analytics'}>
                                <TermsParagraph>
                                    In an ongoing effort to better understand and serve the users of the our Service,
                                    Luminate
                                    often conducts research on its customer demographics, interests and behavior based
                                    on
                                    the
                                    personal information and other information provided to us. This research may be
                                    compiled
                                    and
                                    analyzed on an aggregate basis, and Luminate may share this aggregate data with its
                                    affiliates, agents and business partners. This aggregate information does not
                                    identify
                                    you
                                    personally. Luminate may also disclose aggregated user statistics in order to
                                    describe
                                    our
                                    services to current and prospective business partners, and to other third parties
                                    for
                                    other
                                    lawful purposes.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Other Sites And Services'}>
                                <TermsParagraph>
                                    The Service may contain links to websites and other online services operated by
                                    third
                                    parties. In addition, our content may be integrated into web pages or other online
                                    services
                                    that are not associated with us. These links and integrations are not an endorsement
                                    of,
                                    or
                                    representation that we are affiliated with, any third party. We do not control
                                    websites
                                    or
                                    online services operated by third parties, and we are not responsible for their
                                    actions.
                                </TermsParagraph>
                            </TermsSection>
                            <TermsSection title={'Contact Us'}>
                                <TermsParagraph>
                                    If you have any question about this Statement, you can contact us at
                                    admin@luminatehealth.com or through your account online on the Contact Us page.
                                </TermsParagraph>

                                <View style={{paddingBottom: 15}}>
                                    <Text>Additionally, you may contact us by mail:</Text>
                                    <Text>Attn: Legal</Text>
                                    <Text>Luminate Health, Inc.</Text>
                                    <Text>c/o Hero City</Text>
                                    <Text>55 E 3rd Ave.</Text>
                                    <Text>San Mateo, CA 94401</Text>
                                </View>
                            </TermsSection>
                        </View>
                    </View>
                </View>
                <PageFooter/>
            </ScrollView>
            <Surface
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0
                }}
                onLayout={(event: LayoutChangeEvent) => {
                    setHeaderPadding(event.nativeEvent.layout.height);
                }}
            >
                <TouchableRipple onPress={() => navigation.goBack()}>
                    <View style={{paddingVertical: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <MaterialIcons name={'chevron-left'} size={32} color={theme.colors.primary}/>
                        <SubText style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            textDecorationLine: 'underline',
                        }}>
                            Back
                        </SubText>
                    </View>
                </TouchableRipple>
            </Surface>
        </>
    );
};