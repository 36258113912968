import React, { useState } from "react";
import { Drawer, Searchbar, Surface } from "react-native-paper";
import { LayoutChangeEvent, Text, TouchableOpacity, View } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { MaterialIcons } from "@expo/vector-icons";

import { SubText } from "../typography";
import { useThemeContext } from "../../contexts/ThemeContext";
import { DeviceType, useResponsive } from "../hooks/useResponsive";
import { Hero } from "./heros";

export type BrowseTestNavigatorProps = {
    menuItems: Array<string>;
    selectedMenu: string;
    testFilter?: string;
    onFilterChange: (searchTerm: string) => void;
    onNavigationChange: (navigator: string) => void;
};

export const BrowseTestNavigator = (props: BrowseTestNavigatorProps) => {
    const { theme } = useThemeContext();
    const { screenWidth, deviceType } = useResponsive();
    const [menuVisible, setMenuVisible] = useState(false);
    const [heroImageHeight, setHermoImageHeight] = useState(deviceType === DeviceType.DESKTOP ? 400 : 200);

    const isSelected = (menuItem: string) => menuItem === props.selectedMenu;
    const displayMenu = screenWidth < 956;

    const onNavigationChange = (value: string): void => {
        setMenuVisible(false);
        if (props.onNavigationChange) {
            props.onNavigationChange(value);
        }
    };

    const onFilterSubmitEditing = (): void => {
        if (props.onNavigationChange) {
            props.onNavigationChange(props.menuItems[props.menuItems.length - 1]);
        }
    };

    const onFilterTextChanged = (value: string): void => {
        props.onFilterChange(value);
    };

    const onSearchLayoutChanged = (event: LayoutChangeEvent) => {
        const imageHeightBase = deviceType === DeviceType.DESKTOP ? 400 : 200;
        setHermoImageHeight(event.nativeEvent.layout.height > 72 ? (imageHeightBase + (event.nativeEvent.layout.height - 72)) : imageHeightBase)
    };

    return (
        <View>
            <Hero heroImageHeight={heroImageHeight} />
            <Surface style={{
                backgroundColor: theme.colors.primary,
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center'
            }}
                onLayout={onSearchLayoutChanged}
            >
                {displayMenu
                    ?
                    <TouchableOpacity onPress={() => setMenuVisible(!menuVisible)}>
                        <View style={{ margin: 10, flexDirection: 'row', alignItems: 'center' }}>
                            <MaterialIcons name={'menu'} size={24} color={theme.colors.surface} style={{ padding: 10 }} />
                            <SubText style={{
                                fontWeight: 'bold',
                                color: theme.colors.surface,
                                borderBottomWidth: 3,
                                borderColor: theme.colors.secondary
                            }}>{props.selectedMenu}</SubText>
                        </View>
                    </TouchableOpacity>
                    : <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', marginLeft: 10 }}>
                        {
                            props.menuItems.map(navigator => {
                                return (
                                    <TouchableOpacity
                                        key={`navigation-tab-touch-opacity-${navigator}`}
                                        style={[{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }, isSelected(navigator) ? {
                                            borderBottomWidth: 3,
                                            backgroundColor: theme.colors.primary,
                                            borderColor: theme.colors.secondary
                                        } : {
                                            backgroundColor: theme.colors.primary,
                                        }]}
                                        onPress={() => onNavigationChange(navigator)}>
                                        <Text
                                            key={`navigation-tab-text-${navigator}`}
                                            style={{
                                                color: theme.colors.surface,
                                                fontSize: 15,
                                                fontWeight: 'bold',
                                                marginHorizontal: 10
                                            }}
                                        >
                                            {navigator}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                }
                <View style={{ marginVertical: 5, marginHorizontal: 10 }}>
                    <Searchbar value={props.testFilter as string}
                        placeholder={'Search Tests'}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                        iconColor={theme.colors.surface}
                        placeholderTextColor={theme.colors.surface}
                        style={{
                            backgroundColor: theme.colors.primary,
                            borderWidth: 1,
                            borderColor: theme.colors.surface
                        }}
                        inputStyle={{
                            fontSize: 15,
                            color: theme.colors.surface,
                            borderColor: theme.colors.surface
                        }}
                        onChangeText={onFilterTextChanged}
                        onSubmitEditing={onFilterSubmitEditing}
                        clearIcon={
                            () => <MaterialCommunityIcons
                                color={theme.colors.surface}
                                onPress={() => onFilterTextChanged('')}
                                name={'close'} />
                        }
                    />
                    <SubText style={{ color: theme.colors.surface, fontSize: 10, paddingLeft: 30, marginTop: 5 }}>
                        ex: wellness, allergies, etc ...
                    </SubText>
                </View>
            </Surface>
            {menuVisible ?
                <Drawer.Section style={{ marginTop: 10 }}>
                    {
                        props.menuItems.map(navigator => {
                            return (
                                <Drawer.Item
                                    label={navigator}
                                    active={isSelected(navigator)}
                                    onPress={() => onNavigationChange(navigator)}
                                />
                            )
                        })
                    }
                </Drawer.Section>
                : <></>
            }
        </View>
    );
}