import React, {ReactNode} from "react";
import {StyleSheet, View} from "react-native";
import {Divider, Headline} from "react-native-paper";

export type TermsSectionProps = {
    title: string;
    hideDivider?: boolean;
    children: ReactNode;
};

export const TermsSection = (props: TermsSectionProps) => {
    const styles = StyleSheet.create({
        padding: {
            marginTop: 5,
            marginBottom: 5,
        }
    });
    return (
        <View style={styles.padding}>
            <Headline style={styles.padding} >{props.title}</Headline>
            {props.children}
            {!props.hideDivider ? <Divider/> : <></>}
        </View>
    );
};