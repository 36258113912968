import {Text} from "react-native-paper";
import {StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle} from "react-native";
import {ReactNode, useEffect, useState} from "react";
import {useNavigationState} from "@react-navigation/native";

import {useThemeContext} from "../../contexts/ThemeContext";
import {DatNavigationUtils} from "./DatNavigator";

export type NavigationLinkProps = {
    screen: string;
    label: string;
    dark?: boolean;
    onNavigate: (screen: string) => void;
    style?: StyleProp<ViewStyle>
    right?: ReactNode;
    visible?: boolean;
};

export const NavigationLink = (props: NavigationLinkProps) => {
    const {theme} = useThemeContext();
    const navigationState = useNavigationState(state => state);
    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        setSelected(DatNavigationUtils.isSelected(props.screen, navigationState))
    }, [navigationState]);

    const styles = StyleSheet.create({
        selectedItem: props.dark ? {
            borderStyle: 'solid',
            borderBottomWidth: 3,
            borderColor: theme.colors.surface
        } : {
            borderStyle: 'solid',
            borderBottomWidth: 3,
            borderColor: theme.colors.secondary
        },
        item: {
            marginHorizontal: 10
        },
        itemText: props.dark ? {
            fontWeight: 'bold',
            margin: 10,
            fontSize: 15,
            color: theme.colors.surface
        } : {
            fontWeight: 'bold',
            margin: 10,
            fontSize: 15,
            color: theme.colors.primary
        }
    });

    return (
        <TouchableOpacity
            key={`navigation-tab-touch-opacity-${props.screen}`}
            style={[
                styles.item,
                selected ? styles.selectedItem : {},
                props.visible !== undefined && !props.visible ? {display: 'none'} : {},
                props.style
            ]}
            onPress={() => props.onNavigate(props.screen)}
        >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text key={`navigation-tab-text-${props.screen}`} style={styles.itemText}>
                    {props.label}
                </Text>
                {props.right
                    ? <View style={{marginRight: 10}}>
                        {props.right}
                    </View>
                    : <></>
                }
            </View>
        </TouchableOpacity>
    );
};