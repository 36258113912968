import React, {useEffect, useState} from "react";
import {ScrollView} from "react-native";
import {useRoute} from "@react-navigation/native";
import {pdfjs, Document, Page} from 'react-pdf';
import {ActivityIndicator, IconButton, Surface} from "react-native-paper";
import {FontAwesome5} from '@expo/vector-icons';
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import {OrderPatientService} from "@luminate/luminate-ts-sdk";

import {useAuth} from "../../contexts/AuthContext";
import {OrderConfirmationPdfWebScreenRouteProp} from "../routes/CartStackWeb";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {PageFooter} from "../common/PageFooter";
import {SubText} from "../typography";
import Environment from "../../models/Environment";

export const OrderConfirmationPdfWebScreen = () => {
    const {authData, displaySnack} = useAuth();
    const {theme} = useThemeContext();
    const route = useRoute<OrderConfirmationPdfWebScreenRouteProp>();
    const [pdf, setPdf] = useState<Blob>();
    const [downloading, setDownloading] = useState(false);
    const [zoomScale, setZoomScale] = useState(0.5);

    const loadPdf = async () => {
        try {
            const pdfBlob = await OrderPatientService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).downloadOrder(route.params.orderId);
            setPdf(pdfBlob);
        } catch (ex) {
            showDownloadError();
        }
    };

    const showDownloadError = () => {
        displaySnack(() => <SubText style={{color: theme.colors.surface}}>We are unable to download the order confirmation pdf at this time. Please try again later.</SubText>);
    };

    const renderLoading = () => {
        return (
            <ActivityIndicator animating={true} size="large"/>
        );
    };

    const onDownloadPdf = async () => {
        setDownloading(true);
        try {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(pdf);
            fileReader.onerror = () => {
                showDownloadError();
            };
            fileReader.onload = () => {
                const hiddenDownloadLink = document.createElement('a');
                hiddenDownloadLink.href = fileReader.result as string;
                hiddenDownloadLink.target = '_blank';
                hiddenDownloadLink.download = `${route.params.oderNumber}.pdf`;
                hiddenDownloadLink.click();
                setDownloading(false);
            };
        } catch (ex) {
            showDownloadError();
            setDownloading(false);
        }
    };

    useEffect(() => {
        loadPdf();
    }, []);

    return (
        <>
            <Surface style={{alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: 5}}>
                <Button disabled={downloading}
                        loading={downloading}
                        icon={() => <FontAwesome5 name={'file-download'} size={24} color={theme.colors.surface}/>}
                        onPress={onDownloadPdf}>
                    Download
                </Button>
                <IconButton icon={() => <MaterialIcons name={'zoom-in'} size={32} color={theme.colors.primary}/>}
                            disabled={zoomScale > 1.0}
                            onPress={() => setZoomScale(zoomScale + 0.1)}/>
                <IconButton icon={() => <MaterialIcons name={'zoom-out'} size={32} color={theme.colors.primary}/>}
                            disabled={zoomScale < 0.11}
                            onPress={() => setZoomScale(zoomScale - 0.1)}/>
                <IconButton
                    icon={() => <MaterialIcons name={'aspect-ratio'} size={32} color={theme.colors.primary}/>}
                    onPress={() => setZoomScale(0.5)}
                    disabled={zoomScale === 0.5}
                />
            </Surface>
            <ScrollView>
                <ScrollView style={{paddingTop: 5}} horizontal={true}>
                    <Document file={pdf}
                              renderMode='canvas'
                              loading={renderLoading}
                              noData={() => <></>}
                              onSourceError={() => setDownloadError(true)}>
                        <Page scale={zoomScale} pageNumber={1}/>
                    </Document>
                </ScrollView>
                <PageFooter/>
            </ScrollView>
        </>
    );
};