import {View} from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import {Header16} from "../typography";
import React from "react";

export const ServiceAreaItem = (props: {text: string}) => {
    return (
           <Header16 style={{marginLeft: 20, marginBottom: 5}}>
                <MaterialIcons name={'circle'} style={{marginRight: 5}}/>
                <View>
                    {props.text}
                </View>
           </Header16>
    );
};