import React, {ReactNode} from "react";
import {Linking} from "react-native";
import {A} from "@expo/html-elements";

export type ExternalLinkProps = {
    children: ReactNode,
    url: string;
};

export const ExternalLink = (props: ExternalLinkProps) => {
    const openInNewTab = async (url: string): Promise<void> => {
        await Linking.openURL(url);
    };
    return (
        <A style={{textDecorationLine: 'underline'}}
           onPress={() => openInNewTab(props.url)}>
            {props.children}
        </A>
    );
};