import {ScrollView, View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {OrderableTest, IndividualTest} from "@luminate/luminate-ts-sdk";

import {SubText, Text} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";

export interface DuplicateTestFoundProps {
    testName: string;
    duplicateTests: Array<OrderableTest | IndividualTest>;
    onClose: () => void | undefined;
    onKeepSelection: () => Promise<void> | undefined;
}

export const DuplicateTestFound = (props: DuplicateTestFoundProps) => {
    const {theme} = useThemeContext();

    const renderTestComponentList = (tests: Array<OrderableTest | IndividualTest>) => {
        return (
            <ScrollView>
                <ul>
                    {tests.map(test =>
                        <li key={`test-component-${test.testId}`}>
                            <SubText>{test.name}</SubText>
                        </li>
                    )}
                </ul>
            </ScrollView>
        );
    };

    return (
        <View style={{alignItems: 'center'}}>
            <View style={{marginHorizontal: 20}}>
                <View style={{flexDirection: 'row', marginVertical: 20}}>
                    <MaterialIcons name={'warning'} size={24} color={theme.colors.primary}/>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: theme.colors.primary,
                        marginLeft: 10
                    }}>
                        Duplicate Test Found</Text>
                </View>
                <SubText>
                    <SubText style={{fontWeight: 'bold'}}>{props.testName}</SubText> cannot
                    be added to shopping cart as one or more analytes overlap. If you choose to keep
                    this test, we'll remove the following from your cart:
                </SubText>
                {renderTestComponentList(props.duplicateTests)}
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button style={{padding: 5}} onPress={props.onKeepSelection}>Keep Selection</Button>
                <Button style={{padding: 5}} onPress={props.onClose}>Cancel</Button>
            </View>
        </View>
    );
};