import {Platform} from "react-native";
import * as SecureStore from "expo-secure-store";
import Cookies from "js-cookie";

export enum StorageKeys {
    AUTH_TOKEN = 'lhjwt',
    SESSION = 'SESSION',
    ACK_COLLECTED = 'ACK_COLLECTED',
    CART_TRANSACTION_ID = 'CART_TRANSACTION_ID'
}

export class StorageService {
    public static create = (): StorageService => {
        return new StorageService();
    };

    public isWebPlatform = (): boolean => {
        return Platform.OS === 'web';
    };

    public getFromStorage = async (key: string): Promise<string | undefined> => {
        if (!this.isWebPlatform()) {
            const keyValue = await SecureStore.getItemAsync(key);
            return keyValue ? keyValue : undefined;
        } else {
            return Cookies.get(key);
        }
    };

    public addToStorage = async (key: string, value: string): Promise<void> => {
        if (!this.isWebPlatform()) {
            await SecureStore.setItemAsync(key, value);
        } else {
            Cookies.set(key, value);
        }
    };

    public removeFromStorage = async (key: string): Promise<void> => {
        if (!this.isWebPlatform()) {
            await SecureStore.deleteItemAsync(key);
        } else {
            Cookies.remove(key);
        }
    };
}