import React from "react";
import {useNavigation} from "@react-navigation/native";

import {SubText} from "../typography";
import {useAuth} from "../../contexts/AuthContext";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const DefaultPurchaseConditions = () => {
    const {session} = useAuth();
    const navigation = useNavigation();

    return (
        <ul>
            <li><SubText>Purchaser must be 18 years old or older.</SubText></li>
            <li><SubText>Purchaser can only purchase tests for themselves.</SubText></li>
            <li>
                <SubText>{session?.appConfig?.residenceMsg} <SubText
                    style={{textDecorationLine: 'underline'}}
                    onPress={() => navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS)}>Lab
                    Locations</SubText>
                </SubText>
            </li>
        </ul>
    );
}