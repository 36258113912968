import {LinearGradient} from "expo-linear-gradient";
import {useThemeContext} from "../../contexts/ThemeContext";

export const GradientBar = () => {
    const {theme} = useThemeContext();
    return(
        <LinearGradient
            colors={[theme.colors.primary, theme.colors.secondary, theme.colors.tertiary]}
            style={{height: 3}}
            start={[0, 0]}
            end={[1, 0]}
        />
    );
}