import {createMaterialTopTabNavigator} from "@react-navigation/material-top-tabs";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {OrderHistoryStack} from "./OrderHistoryStack";
import {AuthStack} from "./AuthStack";
import {ProfileStack} from "./ProfileStack";

export type MyAccountTopNavigationParams = {
    UserProfileTab: undefined,
    OrderHistoryTab: undefined,
};

const Tab = createMaterialTopTabNavigator<MyAccountTopNavigationParams>();

export const MyAccountTabStack = () => {
    const {theme} = useThemeContext();
    const {authData} = useAuth();
    return (
        <>
            {!authData
                ? <AuthStack/>
                : <Tab.Navigator screenOptions={{
                    tabBarActiveTintColor: theme.colors.surface,
                    tabBarStyle: {backgroundColor: theme.colors.primary},
                    tabBarIndicatorStyle: {backgroundColor: theme.colors.secondary},
                    tabBarLabelStyle: {textTransform: 'none'},
                    swipeEnabled: false
                }}>
                    <Tab.Screen options={{title: 'Order History', lazy: true}}
                                name={'OrderHistoryTab'}
                                component={OrderHistoryStack}/>
                    <Tab.Screen options={{title: 'My Profile'}}
                                name={'UserProfileTab'}
                                component={ProfileStack}/>
                </Tab.Navigator>}
        </>
    );
};