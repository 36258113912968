import {useState} from "react";
import {StyleSheet} from "react-native";
import {List} from "react-native-paper";

import {Faq} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {HtmlView} from "../common/HtmlView";

export const FaqListItem = (props: Faq) => {
    const {theme} = useThemeContext();
    const [expanded, setExpanded] = useState<boolean>(false);
    const styles = StyleSheet.create({
        underline: {
            borderBottomWidth: 2,
            borderBottomColor: theme.colors.surfaceDisabled
        }
    });

    return (
        <List.Accordion key={`faq-item-${props.id}`}
                        title={props.question}
                        titleStyle={{fontSize: 18}}
                        titleNumberOfLines={0}
                        expanded={expanded}
                        onPress={() => setExpanded(!expanded)}
                        style={[!expanded ? styles.underline : {}, {width: '100%'}]}
        >
            <HtmlView style={{marginVertical: 20, marginTop: 20, marginBottom: 20, paddingLeft: 20}}>
                {props.answer}
            </HtmlView>
        </List.Accordion>
    );
};