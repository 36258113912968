import {Divider} from "react-native-paper";
import React, {
    FlatList,
    ListRenderItemInfo,
    StyleSheet,
    View
} from "react-native";

import {OrderTest} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {SummaryLineItem} from "./SummaryLineItem";
import {useAuth} from "../../contexts/AuthContext";

export type OrderSummaryProps = {
    orderId: number;
    oderNumber: string;
    tests: Array<OrderTest>;
    fees: number;
    taxes: number;
    total: number;
    refundFee?: number;
};

export const OrderSummary = (props: OrderSummaryProps) => {
    const renderTestItem = (test: ListRenderItemInfo<OrderTest>) => {
        return (
            <SummaryLineItem description={test.item.name} value={test.item.price}/>
        );
    };
    const {session} = useAuth();
    const {theme} = useThemeContext();
    const styles = StyleSheet.create({
        totalLine: {
            fontSize: 17,
            color: theme.colors.primary,
            fontWeight: 'bold'
        }
    });

    return (
        <View style={{flex: 1}}>
            <FlatList data={props.tests}
                      renderItem={renderTestItem}
                      keyExtractor={(item) => item.testId.toString()}
            />
            {((session?.appConfig?.checkoutFeeInPennies || 0) != 0) && <SummaryLineItem description={session?.appConfig?.checkoutFeeLabel || 'Fees'} value={props.fees || 0}/>}
            {props.refundFee ?
                <SummaryLineItem description={'Refund'} value={props.refundFee * -1}/>
                : <></>
            }
            <SummaryLineItem description={'Taxes'} value={props.taxes || 0}/>

            <Divider style={{backgroundColor: theme.colors.primary}}/>
            <SummaryLineItem description={'Total'} value={props.total} style={styles.totalLine}
                             currencyStyle={styles.totalLine}/>
        </View>
    );
};