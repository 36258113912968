import {View, StyleSheet, ViewStyle} from "react-native";
import {Card, Paragraph} from "react-native-paper";
import {ReactNode} from "react";

export type ErrorDisplayProps = {
    title?: string;
    message?: string;
    children?: ReactNode;
    style?: ViewStyle;
};

export const ErrorDisplay = (props: ErrorDisplayProps) => {
    const styles = StyleSheet.create({
        screenContainer: {
            flex: 1,
            justifyContent: 'center',
        },
        messageContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

    const displayTitle = props.title ? props.title : 'Oops!  Something went wrong.';
    const displayMessage = props.message ? props.message : 'Please try again later.';

    return (
        <View style={[styles.screenContainer, props.style]}>
            <View style={styles.messageContainer}>
                <Card>
                    <Card.Title title={displayTitle}/>
                    <Card.Content>
                        <Paragraph style={{margin: 10}}>{displayMessage}</Paragraph>
                        {props.children}
                    </Card.Content>
                </Card>
            </View>
        </View>
    );
};