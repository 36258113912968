import {View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {SubText, Text} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";

export interface AddToCartErrorProps {
    testName: string;
    onClose: () => void | undefined;
}

export const AddToCartError = (props: AddToCartErrorProps) => {
    const {theme} = useThemeContext();

    return (
        <View style={{alignItems: 'center'}}>
            <View style={{marginHorizontal: 20}}>
                <View style={{flexDirection: 'row', marginVertical: 20}}>
                    <MaterialIcons name={'error'} size={24} color={theme.colors.notification}/>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: theme.colors.notification,
                        marginLeft: 10
                    }}>
                        Unable To Add Test To Cart</Text>
                </View>
                <SubText>
                    We were unable to add <SubText style={{fontWeight: 'bold'}}>{props.testName}</SubText> due
                    to an unexpected error. Please try again later or contact support if the problem persists.
                </SubText>
            </View>
            <Button style={{paddingVertical: 10}} onPress={props.onClose}>Continue Browsing</Button>
        </View>
    );
};