import {StyleSheet, View} from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText} from "../typography";

export type FastingIndicatorProps = {
    size?: number;
}

export const FastingIndicator = (props: FastingIndicatorProps) => {
    const {theme} = useThemeContext();
    const DEFAULT_ICON_SIZE = 32;

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });
    return (
        <View style={styles.container}>
            <MaterialIcons name="no-food" size={props.size || DEFAULT_ICON_SIZE} color={theme.colors.primary}/>
            <SubText style={{color: theme.colors.primary, fontWeight: 'bold', marginLeft: 10, textAlign: 'center'}}>Fasting Required</SubText>
        </View>
    );
};