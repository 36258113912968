import React, {
    FlatList,
    ListRenderItemInfo,
    StyleSheet,
    TextStyle,
    View
} from "react-native";
import {List, Divider} from "react-native-paper";

import {CartTest} from "@luminate/luminate-ts-sdk";

import {Currency} from "../common/Currency";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";

export type PurchaseSummaryProps = {
    tests: Array<CartTest>;
    fees: number;
    taxes: number;
    total: number;
};

export const PurchaseSummary = (props: PurchaseSummaryProps) => {
    const renderTestItem = (test: ListRenderItemInfo<CartTest>) => {
        return (
            <List.Item titleStyle={styles.lineItem} title={test.item.name}
                       right={() => renderCurrency(test.item.price)}/>
        );
    };
    const {session} = useAuth();
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        lineItem: {
            fontSize: 15,
            color: theme.colors.primary
        },
        totalLine: {
            fontSize: 17,
            color: theme.colors.primary,
            fontWeight: 'bold'
        },
        currency: {
            fontSize: 17,
            color: theme.colors.primary,
        }
    });

    const renderCurrency = (currency: number | undefined, style?: TextStyle) => {
        return (
            <Currency style={[styles.currency, style || {}]} value={currency || 0}/>
        );
    };


    return (
        <View>
            <FlatList data={props.tests}
                      renderItem={renderTestItem}
                      keyExtractor={(item) => item.testId.toString()}
            />
            {((session?.appConfig?.checkoutFeeInPennies || 0) != 0) &&
                <List.Item titleStyle={styles.lineItem}
                           title={session?.appConfig?.checkoutFeeLabel || 'Fees'}
                           right={() => renderCurrency(props.fees)}/>
            }
            <List.Item titleStyle={styles.lineItem}
                       title={'Taxes'}
                       right={() => renderCurrency(props.taxes)}/>
            <Divider style={{backgroundColor: theme.colors.primary}}/>
            <List.Item titleStyle={styles.totalLine} title={'Total'}
                       right={() => renderCurrency(props.total, {
                           fontSize: 17,
                           fontWeight: 'bold',
                           color: theme.colors.primary
                       })}/>
        </View>
    );
};