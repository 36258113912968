import React, {useEffect, useState} from "react";
import {ScrollView, View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {ActivityIndicator, Card, Surface} from "react-native-paper";
import {MaterialIcons} from '@expo/vector-icons';
import {DatComponent, DatComponentName, DatComponentService, OrderStatus} from "@luminate/luminate-ts-sdk"

import {OrderDetails} from "../order/OrderDetails";
import {OrderSummary} from "../order/OrderSummary";
import {OrderConfirmationNavigationScreenProps} from "../routes/CartStack";
import {OrderConfirmationWebNavigationScreenProps, OrderPdfWebNavigationScreenProps} from "../routes/CartStackWeb";
import {PreparingForYourTests} from "../order/PreparingForYourTests";
import {CancellationPolicy} from "../order/CancellationPolicy";
import {ExpirationPolicy} from "../order/ExpirationPolicy";
import {FaqInstructions} from "../order/FaqInstructions";
import {Header18, Header30, SubText} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {PageFooter} from "../common/PageFooter";
import {useResponsive} from "../hooks/useResponsive";
import Environment from "../../models/Environment";
import {ReviewingResults, SchedulingTests} from "../cart";
import {OrderCheckEmail} from "../order/OrderCheckEmail";

export const OrderConfirmationScreen = () => {
    const orderConfirmationComponents: DatComponentName[] = [
        DatComponentName.DAT_CANCELLATION_POLICY,
        DatComponentName.DAT_EXPIRATION_POLICY,
        DatComponentName.DAT_RECEIVING_RESULTS,
        DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING,
        DatComponentName.DAT_ORDER_CHECK_EMAIL,
        DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS,
    ];
    const navigation = useNavigation<OrderConfirmationNavigationScreenProps | OrderConfirmationWebNavigationScreenProps | OrderPdfWebNavigationScreenProps>();
    const {session, newOrder, displaySnack} = useAuth();
    const {theme} = useThemeContext();

    const [loading, setLoading] = useState(false);
    const [cancellationPolicyComponent, setCancellationPolicyComponent] = useState<DatComponent>();
    const [expirationPolicyComponent, setExpirationPolicyComponent] = useState<DatComponent>();
    const [receivingResultsComponent, setReceivingResultsComponent] = useState<DatComponent>();
    const [schedulingTestComponent, setSchedulingTestComponent] = useState<DatComponent>();
    const [checkEmailComponent, setCheckEmailComponent] = useState<DatComponent>();
    const [preparingTestsComponent, setPreparingTestsComponent] = useState<DatComponent>();
    const [components, setComponents] = useState<Array<DatComponentName>>();

    const loadComponentsContent = async () => {
        setLoading(true);
        try {
            const componentsMap: Map<DatComponentName, DatComponent> = await DatComponentService.create(Environment.apiBaseUrl as string).getDatComponents(orderConfirmationComponents);
            setComponents(Array.from(componentsMap.keys()));
            setCancellationPolicyComponent(componentsMap.get(DatComponentName.DAT_CANCELLATION_POLICY));
            setExpirationPolicyComponent(componentsMap.get(DatComponentName.DAT_EXPIRATION_POLICY));
            setReceivingResultsComponent(componentsMap.get(DatComponentName.DAT_RECEIVING_RESULTS));
            setSchedulingTestComponent(componentsMap.get(DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING));
            setCheckEmailComponent(componentsMap.get(DatComponentName.DAT_ORDER_CHECK_EMAIL));
            setPreparingTestsComponent(componentsMap.get(DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS));
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>We were unable to load the order confirmation
                content.
                Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const renderComponent = (componentName: DatComponentName) => {
        switch (componentName) {
            case DatComponentName.DAT_CANCELLATION_POLICY:
                return <CancellationPolicy componentData={cancellationPolicyComponent}/>;
            case DatComponentName.DAT_ORDER_CHECK_EMAIL:
                return <OrderCheckEmail componentData={checkEmailComponent}/>;
            case DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING:
                return <SchedulingTests componentData={schedulingTestComponent} withLabLocations={true}/>;
            case DatComponentName.DAT_RECEIVING_RESULTS:
                return <ReviewingResults componentData={receivingResultsComponent}/>;
            case DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS:
                return <PreparingForYourTests componentData={preparingTestsComponent}/>;
            case DatComponentName.DAT_EXPIRATION_POLICY:
                return <ExpirationPolicy componentData={expirationPolicyComponent}/>;
        }
    }

    useEffect(() => {
        loadComponentsContent();
    }, []);

    const onContinueShopping = () => {
        navigation.popToTop();
        navigation.navigate('BrowseTab');
    };

    const onViewPdf = async () => {
        navigation.push('OrderPdfWeb', {orderId: newOrder?.id, oderNumber: newOrder?.orderNumber});
    };

    const MIN_SIDE_BY_SIDE = 250;
    const MAX_SIDE_BY_SIDE = 520;
    const MAX_TOTAL = MAX_SIDE_BY_SIDE * 2;
    const {screenWidth} = useResponsive();

    return (
        <ScrollView>
            <Surface style={{padding: 5, alignItems: 'center'}}>
                <View style={{maxWidth: MAX_TOTAL}}>
                    <Header30 style={{marginVertical: 10}} numberOfLines={1}>Order Confirmation</Header30>
                    <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap-reverse',
                        justifyContent: 'space-evenly',
                        padding: 5
                    }}>
                        {
                            loading
                                ? <ActivityIndicator animating={true} size={'large'}/>
                                : <View style={{
                                    flex: 8,
                                    minWidth: MIN_SIDE_BY_SIDE,
                                    maxWidth: MAX_SIDE_BY_SIDE
                                }}>
                                    <Card style={{backgroundColor: theme.colors.secondaryAccent, margin: 10}}>
                                        <Card.Content>
                                            <SubText>Thank you! Your purchase is
                                                complete!</SubText>
                                        </Card.Content>
                                    </Card>
                                    <OrderDetails
                                        patientFirstName={newOrder?.patientFirstName}
                                        patientLastName={newOrder?.patientLastName}
                                        orderNumber={newOrder?.orderNumber}
                                        purchaseDate={newOrder?.createdDateTime}
                                        labName={newOrder?.lab?.name}
                                        labAddress1={newOrder?.lab?.address1}
                                        labAddress2={newOrder?.lab?.address2}
                                        labCity={newOrder?.lab?.city}
                                        labState={newOrder?.lab?.state}
                                        labZip={newOrder?.lab?.zip}
                                        labPhone={newOrder?.lab?.phone}
                                    />
                                    <Header18>Here's What To Do Next</Header18>
                                    {components?.map((name) => renderComponent(name))}
                                    <FaqInstructions/>
                                </View>
                        }
                        <View style={[{
                            flex: 4,
                            minWidth: MIN_SIDE_BY_SIDE,
                            maxWidth: MAX_SIDE_BY_SIDE
                        }, screenWidth >= MAX_SIDE_BY_SIDE ? {paddingLeft: 10} : {paddingBottom: 10}]}>
                            <Card style={{backgroundColor: theme.colors.secondaryAccent}}>
                                <Card.Content>
                                    <Header18 style={{marginLeft: 10}}>Order Summary</Header18>
                                    <OrderSummary orderId={newOrder?.id || 0}
                                                  oderNumber={newOrder?.orderNumber || ''}
                                                  tests={newOrder?.orderTests || []}
                                                  fees={newOrder?.fees || 0}
                                                  taxes={newOrder?.taxes || 0}
                                                  total={newOrder?.totals || 0}
                                                  refundFee={newOrder?.status === OrderStatus.REFUNDED
                                                           || newOrder?.status === OrderStatus.EXPIRED ? (session?.appConfig?.nonRefundableFee || 0) : 0}
                                    />
                                </Card.Content>
                                <Card.Actions style={{justifyContent: 'center'}}>
                                    <View>
                                        <Button style={{paddingVertical: 5}} onPress={onViewPdf}
                                                icon={() => <MaterialIcons name="preview" size={16}
                                                                           color={theme.colors.surface}/>}
                                        >
                                            View PDF
                                        </Button>
                                        <Button onPress={onContinueShopping}>Continue
                                            Shopping</Button>
                                    </View>
                                </Card.Actions>
                                <SubText style={{
                                    fontSize: 10,
                                    textAlign: 'left',
                                    padding: 20
                                }}>{session?.appConfig.feesMsg}</SubText>
                            </Card>
                        </View>
                    </View>
                </View>
            </Surface>
            <PageFooter/>
        </ScrollView>
    );
};