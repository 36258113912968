import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {SignInScreen} from '../screens/SignInScreen';
import {RegisterScreen} from "../screens/RegisterScreen";
import {ConnectHealthDataScreen} from "../screens/ConnectHealthDataScreen";
import {ForgotUsernameScreen} from "../screens/ForgotUsernameScreen";
import {ForgotPasswordScreen} from "../screens/ForgotPasswordScreen";
import {RegistrationProvider} from "../../contexts/RegistrationContext";
import {AgreeToTermsOfServiceScreen} from "../screens/AgreeToTermsOfServiceScreen";
import {SCREEN_NAMES} from "./AppStackWeb";

const Stack = createStackNavigator();

export const AuthStack = () => {
    return (
        <RegistrationProvider>
            <Stack.Navigator>
                <Stack.Screen options={{headerShown: false}} name={SCREEN_NAMES.SIGN_IN} component={SignInScreen}/>
                <Stack.Screen options={{headerShown: false}} name={SCREEN_NAMES.REGISTER} component={RegisterScreen}/>
                <Stack.Screen name={SCREEN_NAMES.TERMS_OF_SERVICE} component={AgreeToTermsOfServiceScreen}/>
                <Stack.Screen options={{headerShown: false}} name={SCREEN_NAMES.CONNECT} component={ConnectHealthDataScreen}/>
                <Stack.Screen options={{headerShown: false}} name={SCREEN_NAMES.FORGOT_USERNAME} component={ForgotUsernameScreen}/>
                <Stack.Screen options={{headerShown: false}} name={SCREEN_NAMES.FORGOT_PASSWORD} component={ForgotPasswordScreen}/>
            </Stack.Navigator>
        </RegistrationProvider>
    );
};
