import {ReactNode} from "react";
import {StyleProp, StyleSheet, TextStyle} from "react-native";
import {Text} from "react-native-paper";

export type SubTextProps = {
    children?: ReactNode;
    style?: StyleProp<TextStyle>;
    onPress?: () => void;
    numberOfLines?: number;
    ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip' | undefined;
};

export const SubText = (props: SubTextProps) => {
    const styles = StyleSheet.create({
        text: {
            fontSize: 15,
            letterSpacing: 0.15,
        }
    });

    return (
        <Text numberOfLines={props.numberOfLines} ellipsizeMode={props.ellipsizeMode} style={[styles.text, props.style]}
              onPress={props.onPress}>
            {props.children}
        </Text>
    );
};