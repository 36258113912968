import React, {ColorValue, TouchableOpacity, View, ViewStyle} from "react-native";
import {MD2Colors} from "react-native-paper";
import Animated, {Extrapolate, interpolate, useAnimatedStyle} from "react-native-reanimated";

import {useThemeContext} from "../../contexts/ThemeContext";

export type CarouselPageProps = {
    pageIndex: number;
    pageCount: number;
    selectProgression: Animated.SharedValue<number>;
    width?: number;
    backgroundColor?: ColorValue;
    color?: ColorValue;
    onSelected?: (selectedIndex: number) => void;
};

export const CarouselPage = (props: CarouselPageProps) => {
    const {theme} = useThemeContext();
    const backgroundColor = props.backgroundColor || MD2Colors.white;
    const color = props.color || theme.colors.secondary;
    const width = props.width || 10;

    //@ts-ignore
    const animStyle = useAnimatedStyle<ViewStyle>(() :ViewStyle => {
        let inputRange = [props.pageIndex - 1, props.pageIndex, props.pageIndex + 1];
        let outputRange = [-width, 0, width];

        if (props.pageIndex === 0 && props.selectProgression.value > props.pageCount - 1) {
            inputRange = [props.pageCount - 1, props.pageCount, props.pageCount + 1];
            outputRange = [-width, 0, width];
        }

        return {
            transform: [
                {
                    translateX: interpolate(
                        props.selectProgression.value,
                        inputRange,
                        outputRange,
                        Extrapolate.CLAMP
                    ),
                }],
        } as ViewStyle;
    }, [props.selectProgression, props.pageIndex, props.pageCount]);


    const onSelected = () => {
        if (props.onSelected) {
            props.onSelected(props.pageIndex);
        }
    };
    return (
        <TouchableOpacity onPress={onSelected}>
            <View style={{
                backgroundColor: backgroundColor,
                margin: 5,
                width: 10,
                height: 10,
                borderRadius: 50,
                overflow: 'hidden'
            }}>
                <Animated.View
                    key={props.pageIndex}
                    style={[
                        {
                            borderRadius: 50,
                            backgroundColor: color,
                            flex: 1
                        },
                        animStyle
                    ]}
                />

            </View>
        </TouchableOpacity>
    );
};