import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";

import {PhoneType} from "@luminate/luminate-ts-sdk";

export type RegistrationContextData = {
    firstName: string;
    setFirstName(value: string): void;
    middleName: string;
    setMiddleName(value: string): void;
    lastName: string;
    setLastName(value: string): void;
    dateOfBirth: string | undefined;
    setDateOfBirth(value: string | undefined): void;
    email: string;
    setEmail(value: string): void;
    password: string;
    setPassword(value: string): void;
    userName: string;
    setUserName(value: string): void;
    guardianRequest: boolean;
    setGuardianRequest(value: boolean): void;
    guardianFirstName: string;
    setGuardianFirstName(value: string): void;
    guardianLastName: string;
    setGuardianLastName(value: string): void;
    guardianDateOfBirth: string | undefined;
    setGuardianDateOfBirth(value: string | undefined): void;
    gender: string;
    setGender: Dispatch<SetStateAction<string>>;
    last4: string;
    setLast4(value: string): void;
    phoneType: PhoneType;
    setPhoneType: Dispatch<SetStateAction<PhoneType>>;
    phone: string;
    setPhone(value: string): void;
    addressOne: string;
    setAddressOne(value: string): void;
    addressTwo: string;
    setAddressTwo(value: string): void;
    city: string;
    setCity(value: string): void;
    state: string;
    setState: Dispatch<SetStateAction<string>>;
    zip: string;
    setZip(value: string): void;
    agreeToTerms: boolean;
    setAgreeToTerms(value: boolean): void;
    reset(): void;
};

export const RegistrationContext = createContext<RegistrationContextData>({} as RegistrationContextData);
// @ts-ignore
export const RegistrationProvider = ({children}) => {
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined);
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [guardianRequest, setGuardianRequest] = useState(false);
    const [guardianFirstName, setGuardianFirstName] = useState('');
    const [guardianLastName, setGuardianLastName] = useState('');
    const [guardianDateOfBirth, setGuardianDateOfBirth] = useState<Date | undefined>();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [gender, setGender] = useState<string | undefined>();
    const [last4, setLast4] = useState('');
    const [phoneType, setPhoneType] = useState<string>(PhoneType.MOBILE);
    const [phone, setPhone] = useState('');
    const [addressOne, setAddressOne] = useState<string>('');
    const [addressTwo, setAddressTwo] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>();
    const [zip, setZip] = useState<string>('');

    const reset = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setDateOfBirth(undefined);
        setEmail('');
        setUserName('');
        setPassword('');
        setGuardianRequest(false);
        setGuardianFirstName('');
        setGuardianLastName('');
        setGuardianDateOfBirth(undefined);
        setAgreeToTerms(false);
        setGender('');
        setLast4('');
        setPhoneType(PhoneType.MOBILE);
        setPhone('');
        setAddressOne('');
        setAddressTwo('');
        setCity('');
        setState('');
        setZip('');
    };

    return <RegistrationContext.Provider
        value={
            {
                firstName, setFirstName,
                middleName, setMiddleName,
                lastName, setLastName,
                dateOfBirth, setDateOfBirth,
                email, setEmail,
                password, setPassword,
                userName, setUserName,
                guardianRequest, setGuardianRequest,
                guardianFirstName, setGuardianFirstName,
                guardianLastName, setGuardianLastName,
                guardianDateOfBirth, setGuardianDateOfBirth,
                agreeToTerms, setAgreeToTerms,
                gender, setGender,
                last4, setLast4,
                phoneType, setPhoneType,
                phone, setPhone,
                addressOne, setAddressOne,
                addressTwo, setAddressTwo,
                city, setCity,
                state, setState,
                zip, setZip,
                reset
            } as RegistrationContextData}>{children}</RegistrationContext.Provider>;
};

export const useRegistrationContext = (): RegistrationContextData => {
    const context = useContext(RegistrationContext);
    if (!context) {
        throw Error('useRegistrationContext must be used with RegistrationProvider');
    }
    return context;
};