import React from "react";
import {CompositeNavigationProp} from "@react-navigation/native";
import {createStackNavigator, StackNavigationProp} from "@react-navigation/stack";
import {MaterialTopTabNavigationProp} from "@react-navigation/material-top-tabs";

import {MyAccountTopNavigationParams} from "./MyAccountTabStack";
import {ViewProfileScreen} from "../screens/ViewProfileScreen";
import {EditProfileScreen} from "../screens/EditProfileScreen";
import {ChangeEmailScreen} from "../screens/ChangeEmailScreen";
import {ForgotPasswordScreen} from "../screens/ForgotPasswordScreen";
import {ForgotUsernameScreen} from "../screens/ForgotUsernameScreen";

export type ProfileStackParams = {
    ViewProfile: undefined,
    EditProfile: undefined,
    ChangeEmail: undefined,
    ForgotUsername: undefined,
    ForgotPassword: undefined,
};
const Stack = createStackNavigator<ProfileStackParams>();
export type ViewProfileScreenNavigationProps = CompositeNavigationProp<StackNavigationProp<ProfileStackParams, 'ViewProfile'>, MaterialTopTabNavigationProp<MyAccountTopNavigationParams, 'UserProfileTab'>>;
export type EditProfileScreenNavigationProps = CompositeNavigationProp<StackNavigationProp<ProfileStackParams, 'EditProfile'>, MaterialTopTabNavigationProp<MyAccountTopNavigationParams, 'UserProfileTab'>>;
export type ChangeEmailScreenNavigationProps = CompositeNavigationProp<StackNavigationProp<ProfileStackParams, 'ChangeEmail'>, MaterialTopTabNavigationProp<MyAccountTopNavigationParams, 'UserProfileTab'>>;

export const ProfileStack = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen options={{headerShown: false, title: 'My Profile'}}
                          name={'ViewProfile'}
                          component={ViewProfileScreen}
            />
            <Stack.Screen options={{headerShown: false, title: 'Edit My Profile'}}
                          name={'EditProfile'}
                          component={EditProfileScreen}
            />
            <Stack.Screen options={{headerShown: false, title: 'Change Email Address'}}
                          name={'ChangeEmail'}
                          component={ChangeEmailScreen}
            />
            <Stack.Screen options={{headerShown: false, title: 'Change Password'}}
                          name={'ForgotPassword'}
                          component={ForgotPasswordScreen}
            />
            <Stack.Screen options={{headerShown: false, title: 'Forgot Password'}}
                          name={'ForgotUsername'}
                          component={ForgotUsernameScreen}
            />
        </Stack.Navigator>
    );
};