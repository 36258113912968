import {StyleSheet, View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {Text} from '../typography';

export const FaqInstructions = () => {
    const navigation = useNavigation();
    const styles = StyleSheet.create({
        padding: {marginVertical: 5}
    });
    return (
        <View style={styles.padding}>
            <Text>
                Read our <Text style={{fontWeight: 'bold', textDecorationLine: 'underline'}}
                               onPress={() => navigation.navigate('FaqTab')}>FAQs</Text> for more information.
            </Text>
        </View>
    );
};