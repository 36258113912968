import React, {useState} from "react";
import {TextInput} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {ScrollView, View} from "react-native";

import {PhoneType, PhoneTypes} from "@luminate/luminate-ts-sdk";

import {useAuth} from "../../contexts/AuthContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {ViewProfileScreenNavigationProps} from "../routes/ProfileStack";
import {Dialog} from "../common/Dialog";
import {Button} from "../common/Button";
import {Header30} from "../typography";
import {PageFooter} from "../common/PageFooter";

export const ViewProfileScreen = () => {
    const navigation = useNavigation<ViewProfileScreenNavigationProps>();
    const {signOut, patientProfile, setCart} = useAuth();
    const {theme} = useThemeContext();

    const onSignOut = async () => {
        await signOut();
        setCart(undefined);
    };

    const onEditMyProfile = () => {
        navigation.push('EditProfile');
    };

    const onChangeEmailAddress = () => {
        navigation.push('ChangeEmail');
    };

    const onChangeMyPassword = () => {
        navigation.push('ForgotPassword');
    };

    const getPhoneType = (type: PhoneType | undefined): string | undefined => {
        return PhoneTypes.find(item => item.value === type)?.label;
    };

    const [changePasswordButtonLength, setChangePasswordButtonLength] = useState<number>();
    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <Dialog style={{marginVertical: 20}} actions={<View style={{flex: 1, flexDirection: 'row', flexWrap:'wrap', justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{margin: 5, width: changePasswordButtonLength}}
                        onPress={onEditMyProfile}>Edit My Profile</Button>
                <Button style={{margin: 5}} onLayout={(event) => {
                    setChangePasswordButtonLength(event.nativeEvent.layout.width);
                }} onPress={onChangeMyPassword}>Change Password</Button>
                <Button style={{margin: 5, width: changePasswordButtonLength}} onPress={onSignOut}>Sign Out</Button>
            </View>}>
                <Header30 style={{marginVertical: 10}}>My Account</Header30>
                <TextInput value={patientProfile?.firstName}
                           label={'First Name'}
                           disabled={true}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput value={patientProfile?.lastName}
                           label={'Last Name'}
                           disabled={true}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput value={patientProfile?.email}
                           label={'Email'}
                           disabled={true}
                           right={<TextInput.Icon icon="pencil" onPress={onChangeEmailAddress}/>}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput value={patientProfile?.phone}
                           label={'Phone Number'}
                           disabled={true}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput value={getPhoneType(patientProfile?.phoneType)}
                           label={'Phone Type'}
                           disabled={true}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput
                    label={'Address'}
                    value={patientProfile?.address1}
                    disabled={true}
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput label="Address (Continued)"
                           value={patientProfile?.address2}
                           disabled={true}
                           mode={'outlined'}
                           style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput
                    label={'City'}
                    value={patientProfile?.city}
                    disabled={true}
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput
                    label={'State'}
                    value={patientProfile?.state}
                    disabled={true}
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <TextInput
                    label="Zip"
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    value={patientProfile?.zip}
                    disabled={true}
                />
            </Dialog>
            <PageFooter/>
        </ScrollView>
    );
};