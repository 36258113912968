import React, {useEffect, useState} from "react";
import {ScrollView, View} from "react-native";
import {ActivityIndicator, Card, Surface} from "react-native-paper";
import {useNavigation, useRoute} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";

import {
    DatComponent,
    DatComponentName,
    DatComponentService,
    Order,
    OrderPatientService,
    OrderStatus
} from "@luminate/luminate-ts-sdk";

import {OrderDetails} from "../order/OrderDetails";
import {CancelListScreenNavigationProps, OrderDetailsRouteProps} from "../routes/OrderHistoryStack";
import {useAuth} from "../../contexts/AuthContext";
import {OrderSummary} from "../order/OrderSummary";
import {OrderPdfWebNavigationScreenProps} from "../routes/CartStackWeb";
import {PreparingForYourTests} from "../order/PreparingForYourTests";
import {CancellationPolicy} from "../order/CancellationPolicy";
import {ExpirationPolicy} from "../order/ExpirationPolicy";
import {FaqInstructions} from "../order/FaqInstructions";
import {Header18, Header30, SubText} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {PageFooter} from "../common/PageFooter";
import {useResponsive} from "../hooks/useResponsive";
import Environment from "../../models/Environment";
import {OrderStatusDetailCard} from "../order/OrderStatusDetailCard";
import {ReviewingResults, SchedulingTests} from "../cart";
import {OrderCheckEmail} from "../order/OrderCheckEmail";

export const OrderDetailsScreen = () => {
    const orderConfirmationComponents: DatComponentName[] = [
        DatComponentName.DAT_CANCELLATION_POLICY,
        DatComponentName.DAT_EXPIRATION_POLICY,
        DatComponentName.DAT_RECEIVING_RESULTS,
        DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING,
        DatComponentName.DAT_ORDER_CHECK_EMAIL,
        DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS,
    ];
    const navigation = useNavigation<OrderPdfWebNavigationScreenProps | CancelListScreenNavigationProps>();
    const {session, authData, displaySnack} = useAuth();
    const {theme} = useThemeContext();
    const route = useRoute<OrderDetailsRouteProps>();
    const [loading, setLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order>();
    const [cancellationPolicyComponent, setCancellationPolicyComponent] = useState<DatComponent>();
    const [expirationPolicyComponent, setExpirationPolicyComponent] = useState<DatComponent>();
    const [receivingResultsComponent, setReceivingResultsComponent] = useState<DatComponent>();
    const [schedulingTestComponent, setSchedulingTestComponent] = useState<DatComponent>();
    const [checkEmailComponent, setCheckEmailComponent] = useState<DatComponent>();
    const [preparingTestsComponent, setPreparingTestsComponent] = useState<DatComponent>();
    const [components, setComponents] = useState<Array<DatComponentName>>();

    const loadComponentsContent = async () => {
        setLoading(true);
        try {
            const componentsMap: Map<DatComponentName, DatComponent> = await DatComponentService.create(Environment.apiBaseUrl as string).getDatComponents(orderConfirmationComponents);
            setComponents(Array.from(componentsMap.keys()));
            setCancellationPolicyComponent(componentsMap.get(DatComponentName.DAT_CANCELLATION_POLICY));
            setExpirationPolicyComponent(componentsMap.get(DatComponentName.DAT_EXPIRATION_POLICY));
            setReceivingResultsComponent(componentsMap.get(DatComponentName.DAT_RECEIVING_RESULTS));
            setSchedulingTestComponent(componentsMap.get(DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING));
            setCheckEmailComponent(componentsMap.get(DatComponentName.DAT_ORDER_CHECK_EMAIL));
            setPreparingTestsComponent(componentsMap.get(DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS));
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>We were unable to load the order details
                content. Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const loadOrderDetails = async () => {
        setLoading(true);
        setSelectedOrder(await OrderPatientService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).getOrderDetails(route.params.orderId));
        setLoading(false);
    };

    const onViewPdf = async () => {
        navigation.push('OrderPdfWeb', {orderId: selectedOrder?.id, oderNumber: selectedOrder?.orderNumber});
    };

    const onCancelOrder = () => {
        navigation.push('CancelOrder', {order: selectedOrder});
    };

    const statusOpenRequired = (componentName: DatComponentName) => {
        return [DatComponentName.DAT_RECEIVING_RESULTS, DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING,
            DatComponentName.DAT_ORDER_CHECK_EMAIL, DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS].includes(componentName);
    }

    const renderComponent = (componentName: DatComponentName) => {
        switch (componentName) {
            case DatComponentName.DAT_CANCELLATION_POLICY:
                return <CancellationPolicy componentData={cancellationPolicyComponent}/>;
            case DatComponentName.DAT_ORDER_CHECK_EMAIL:
                return <OrderCheckEmail componentData={checkEmailComponent}/>;
            case DatComponentName.DAT_SCHEDULING_TESTS_PURCHASING:
                return <SchedulingTests componentData={schedulingTestComponent} withLabLocations={true}/>;
            case DatComponentName.DAT_RECEIVING_RESULTS:
                return <ReviewingResults componentData={receivingResultsComponent}/>;
            case DatComponentName.DAT_PREPARING_FOR_YOUR_TESTS:
                return <PreparingForYourTests componentData={preparingTestsComponent}/>;
            case DatComponentName.DAT_EXPIRATION_POLICY:
                return <ExpirationPolicy componentData={expirationPolicyComponent}/>;
        }
    }

    useEffect(() => {
        loadComponentsContent();
    }, []);

    useEffect(() => {
        return navigation.addListener('focus', () => {
            loadOrderDetails();
        });
    }, [navigation]);

    const MIN_SIDE_BY_SIDE = 250;
    const MAX_SIDE_BY_SIDE = 520;
    const MAX_TOTAL = MAX_SIDE_BY_SIDE * 2;
    const {screenWidth} = useResponsive();

    return (
        <>
            {loading
                ? <ActivityIndicator animating={true} size={'large'}/>
                : <ScrollView>
                    <Surface style={{padding: 5, alignItems: 'center'}}>
                        <View style={{maxWidth: MAX_TOTAL}}>
                            <Header30>Order Details</Header30>
                            <View style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap-reverse',
                                justifyContent: 'space-evenly',
                                padding: 5
                            }}>
                                <View style={{
                                    flex: 8,
                                    minWidth: MIN_SIDE_BY_SIDE,
                                    maxWidth: MAX_SIDE_BY_SIDE
                                }}>

                                    <OrderStatusDetailCard
                                        refundTotal={selectedOrder?.refundTotal}
                                        status={selectedOrder?.status}
                                    />

                                    <OrderDetails
                                        patientFirstName={selectedOrder?.patientFirstName}
                                        patientLastName={selectedOrder?.patientLastName}
                                        orderNumber={selectedOrder?.orderNumber}
                                        purchaseDate={selectedOrder?.createdDateTime}
                                        status={selectedOrder?.status}
                                        labName={selectedOrder?.lab?.name}
                                        labAddress1={selectedOrder?.lab?.address1}
                                        labAddress2={selectedOrder?.lab?.address2}
                                        labCity={selectedOrder?.lab?.city}
                                        labState={selectedOrder?.lab?.state}
                                        labZip={selectedOrder?.lab?.zip}
                                        labPhone={selectedOrder?.lab?.phone}
                                    />
                                    {selectedOrder?.status === OrderStatus.OPEN
                                        ? <>
                                            <Header18>Here's What To Do Next</Header18>
                                            {components?.map((name) => statusOpenRequired(name) && renderComponent(name))}
                                        </>
                                        : <></>
                                    }
                                    {components?.map((name) => !statusOpenRequired(name) && renderComponent(name))}
                                    <FaqInstructions/>
                                </View>
                                <View style={[{
                                    flex: 4,
                                    minWidth: MIN_SIDE_BY_SIDE,
                                    maxWidth: MAX_SIDE_BY_SIDE
                                }, screenWidth >= MAX_SIDE_BY_SIDE ? {paddingLeft: 10} : {paddingBottom: 10}]}>
                                    <Card style={{backgroundColor: theme.colors.secondaryAccent}}>
                                        <Card.Content>
                                            <Header18 style={{marginLeft: 10, marginVertical: 10}}>Order
                                                Summary</Header18>
                                            <OrderSummary orderId={selectedOrder?.id || 0}
                                                          oderNumber={selectedOrder?.orderNumber || ''}
                                                          tests={selectedOrder?.orderTests || []}
                                                          fees={selectedOrder?.fees || 0}
                                                          taxes={selectedOrder?.taxes || 0}
                                                          total={selectedOrder?.totals || 0}
                                                          refundFee={selectedOrder?.refundTotal}
                                            />
                                        </Card.Content>
                                        <Card.Actions style={{justifyContent: 'center'}}>
                                            <View>
                                                <Button style={{marginVertical: 5}} onPress={onViewPdf}
                                                        icon={() => <MaterialIcons name="preview" size={16}
                                                                                   color={theme.colors.surface}/>}>
                                                    View PDF
                                                </Button>
                                                {selectedOrder?.status === OrderStatus.OPEN && session?.appConfig.userInitiatedOrderCancellationEnabled &&
                                                    <Button style={{marginVertical: 5}} onPress={onCancelOrder}>
                                                        Cancel Order</Button>
                                                }
                                            </View>
                                        </Card.Actions>
                                        <SubText style={{
                                            fontSize: 10,
                                            textAlign: 'left',
                                            padding: 20
                                        }}>{session?.appConfig.feesMsg}</SubText>
                                    </Card>
                                </View>
                            </View>
                        </View>
                    </Surface>
                    <PageFooter/>
                </ScrollView>
            }
        </>
    );
};