import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {ActivityIndicator, Card} from "react-native-paper";
import moment from 'moment';
import {DateUtilities, SessionService} from "@luminate/luminate-ts-sdk";

import {useAuth} from "../contexts/AuthContext";
import {StorageService} from "../services/StorageService";
import {Header24, SubText} from "./typography";
import {Opacity, ThemeUtilities} from "../styles/LuminateTheme";
import {useThemeContext} from "../contexts/ThemeContext";
import {Button} from './common/Button';

export const SessionTimeout = () => {
    const {theme} = useThemeContext();
    const {authData, session, signOut} = useAuth();
    const startTimerInterval = useRef();
    const warningInterval = useRef();

    const events = ['wheel', 'mousedown', 'keypress'];
    const LAST_ACTIVITY_TIMESTAMP_KEY = 'lastActivityTimestamp';
    const ONE_SECOND = 1000;
    const storageService = StorageService.create();
    const [showWarning, setShowWarning] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState<number | undefined>();

    const onStartInactivityTimer = () => {
        // @ts-ignore
        startTimerInterval.current = setTimeout(async () => {
            const storedLastActivityTimestamp = await storageService.getFromStorage(LAST_ACTIVITY_TIMESTAMP_KEY);
            onInactiveWarningCheck(storedLastActivityTimestamp as string);
        }, session?.appConfig.inactivityTimeoutIntervalInMs || SessionService.DEFAULT_TIME_OUT_INTERVAL);
    };

    const onInactiveWarningCheck = (timeStamp: string) => {
        clearTimeout(startTimerInterval.current);
        setShowWarning(true);

        // @ts-ignore
        warningInterval.current = setInterval(async () => {
            const milliSecondsOfStart = moment(timeStamp).toDate().getTime();
            const milliSecondsOfNow = moment().toDate().getTime()
            const diffInMs = milliSecondsOfNow - milliSecondsOfStart;
            setSecondsLeft(DateUtilities.millisecondsToSeconds((session?.appConfig.inactivityWarningIntervalInMs || SessionService.DEFAULT_TIME_OUT_WARNING_INTERVAL) - diffInMs));

            if (diffInMs >= (session?.appConfig.inactivityWarningIntervalInMs || SessionService.DEFAULT_TIME_OUT_WARNING_INTERVAL)) {
                clearInterval(warningInterval.current);
                setShowWarning(false);
                setSecondsLeft(undefined);
                await storageService.removeFromStorage(LAST_ACTIVITY_TIMESTAMP_KEY);
                signOut();
            }
        }, ONE_SECOND);
    };

    const onResetTimer = useCallback(async () => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInterval.current);
        setShowWarning(false);
        setSecondsLeft(undefined);
        if (authData) {
            await storageService.addToStorage(LAST_ACTIVITY_TIMESTAMP_KEY, moment().toISOString());
            onStartInactivityTimer();
        } else {
            await storageService.removeFromStorage(LAST_ACTIVITY_TIMESTAMP_KEY);
        }

    }, [authData]);

    useEffect(() => {
        events.forEach((event: string) => {
            window.addEventListener(event, onResetTimer);
        });

        onResetTimer();

        return () => {
            clearTimeout(startTimerInterval.current);
        };
    }, [onResetTimer]);

    return (
        <>
            {showWarning ? <Card>
                <Card.Content style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: ThemeUtilities.getOpacityForColor(theme.colors.secondary, Opacity.PERCENT_20)
                }}>
                    <SubText style={{margin: 10, color: theme.colors.primary}}>Due to inactivity, you will be logged out
                        for security concerns in:</SubText>
                    <ActivityIndicator animating={true} size={'small'}/>
                    <Header24 style={{margin: 10}}>{secondsLeft}</Header24>
                    <Button>Need More Time ?</Button>
                </Card.Content>
            </Card> : <Fragment/>}
        </>
    );
};