import {LabIds} from "../../models";
import {useAuth} from "../../contexts/AuthContext";
import {AlvernoAcknowledgments} from "./AlvernoAcknowledgments";
import {DefaultAcknowledgments} from "./DefaultAcknowledgments";
import {PplAcknowledgments} from "./PplAcknowledgments";

export type AcknowledgmentsProps = {
    labId?: number;
    onAcknowledgment: (acknowledged: boolean) => void;
};

export const Acknowledgments = (props: AcknowledgmentsProps) => {
    const {useLab, useDefaultLab} = useAuth();
    return (
        <>
            {useLab(LabIds.ALVERNO)
                ? <AlvernoAcknowledgments onAcknowledgment={props.onAcknowledgment}/>
                : <></>
            }
            {useLab(LabIds.PPL)
                ? <PplAcknowledgments onAcknowledgment={props.onAcknowledgment}/>
                : <></>
            }
            {useDefaultLab([LabIds.ALVERNO, LabIds.PPL])
                ? <DefaultAcknowledgments onAcknowledgment={props.onAcknowledgment}/>
                : <></>
            }
        </>
    );
}