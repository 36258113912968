import {FlatList, ListRenderItemInfo, View} from "react-native";
import {ActivityIndicator, Title} from "react-native-paper";
import {useEffect, useState} from "react";

import {FaqService, Faq} from "@luminate/luminate-ts-sdk";

import {FaqListItem} from "./FaqListItem";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import Environment from "../../models/Environment";
import {SubText} from "../typography";

export type FaqCategoryProps = {
    id: number;
    title: string;
};

export const FaqCategoryList = (props: FaqCategoryProps) => {
    const [questions, setQuestions] = useState<Array<Faq>>([]);
    const [loading, setLoading] = useState(false);
    const {theme} = useThemeContext();
    const {displaySnack} = useAuth();

    const loadFaqs = async () => {
        setLoading(true);
        try {
            setQuestions(await FaqService.create(Environment.apiBaseUrl as string).getCategoryFaqs(props.id));
        } catch(ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to load some of the frequently asked questions.  Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const renderFaq = (faqItem: ListRenderItemInfo<Faq>) => {
        return <FaqListItem id={faqItem.item.id} question={faqItem.item.question} answer={faqItem.item.answer}/>
    };

    useEffect(() => {
        loadFaqs();
    }, []);

    return (
        <>
            {loading
                ? <ActivityIndicator animating={true} size={'large'}/>
                : <View style={{paddingVertical: 20, marginLeft: 20, marginRight: 20}}>
                    <Title style={{marginVertical: 20, marginLeft: 15, fontSize: 24, fontWeight: 'bold', color: theme.colors.primary}}>{props.title}</Title>
                    <FlatList data={questions}
                              renderItem={renderFaq}
                              keyExtractor={faq => `faq-question-${faq.question}`}
                    />
                </View>
            }
        </>
    );
};