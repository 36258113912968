import {Paragraph, Text} from "react-native-paper";
import {StyleSheet} from "react-native";
import {ReactNode} from "react";

export type TermsParagraphProps = {
    title?: string;
    bold?: boolean;
    children?: ReactNode;
};

export const TermsParagraph = (props: TermsParagraphProps) => {
    const styles = StyleSheet.create({
        padding: {
            paddingTop: 5,
            paddingBottom: 5
        },
        bold: {
            fontWeight: 'bold'
        }
    });
    return (
        <Paragraph style={[styles.padding, props.bold ? styles.bold : {}]}>
            {props.title
                ? <Text style={styles.bold}>{props.title}</Text>
                : <></>
            }
            {props.children}
        </Paragraph>
    );
};