import {ScrollView, View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {IndividualTest, OrderableTest} from "@luminate/luminate-ts-sdk";

import {SubText, Text} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";

export interface AddToCartSuccessfulProps {
    testName: string;
    testComponents: Array<OrderableTest | IndividualTest>;
    onClose: () => void | undefined;
    onShowCart: () => void | undefined;
}

export const AddToCartSuccessful = (props: AddToCartSuccessfulProps) => {
    const {theme} = useThemeContext();

    const renderTestComponentList = (tests: Array<OrderableTest | IndividualTest>) => {
        return (
            <ScrollView>
                <ul>
                    {tests.map(test =>
                        <li key={`test-component-${test.testId}`}>
                            <SubText>{test.name}</SubText>
                        </li>
                    )}
                </ul>
            </ScrollView>
        );
    };

    return (
        <View style={{alignItems: 'center'}}>
            <View style={{marginHorizontal: 20}}>
                <View style={{flexDirection: 'row', marginVertical: 20}}>
                    <MaterialIcons name={'check'} size={24} color={theme.colors.primary}/>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: theme.colors.primary,
                        marginLeft: 10
                    }}>
                        Added To Cart!</Text>
                </View>
                <Text>{props.testName}</Text>
                {renderTestComponentList(props.testComponents ? props.testComponents : [])}
            </View>
            <Button style={{paddingVertical: 5, width: 186}} onPress={props.onClose}>Continue Browsing</Button>
            <Button style={{paddingVertical: 5, width: 186}} onPress={props.onShowCart}>Checkout Now</Button>
        </View>
    );
};