import {Card} from "react-native-paper";
import {View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {NumberToCurrencyConverter} from "@luminate/luminate-ts-sdk";

import {SubText} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";

export type OrderHistorySummaryProps = {
    orderDate?: string;
    orderId: number;
    oderNumber: string;
    status: string;
    total: number;
    compactView?: boolean;
};

export const OrderHistorySummary = (props: OrderHistorySummaryProps) => {
        const {theme} = useThemeContext();
        const currencyConverter = NumberToCurrencyConverter.create();
        return (
            <Card style={{margin: 5}}>
                {props.compactView
                    ? <Card.Content>
                        <View style={{flex: 1, flexDirection: 'row', marginVertical: 5, minWidth: 232 }}>
                            <SubText style={{color: theme.colors.primary}}>Purchase Date:</SubText>
                            <SubText style={{marginLeft: 5}}>{props.orderDate}</SubText>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', marginVertical: 5, minWidth: 149}}>
                            <SubText style={{color: theme.colors.primary}}>Order #:</SubText>
                            <SubText style={{marginLeft: 5}}>{props.oderNumber}</SubText>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', marginVertical: 5, alignItems: 'center'}}>
                            <SubText style={{color: theme.colors.primary}}>Status:</SubText>
                            <SubText style={{marginLeft: 5}}>{props.status}</SubText>
                        </View>
                        <View style={{
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <View style={{flex: 1, flexDirection: 'row', marginTop: 10, marginBottom: 5, minWidth: 90}}>
                                <SubText style={{color: theme.colors.primary}}>Total:</SubText>
                                <SubText style={{marginLeft: 5}}>{currencyConverter.toModel(props.total)}</SubText>
                            </View>
                            <MaterialIcons name="preview" size={24} color={theme.colors.primary}
                                           style={{marginHorizontal: 5}}/>
                        </View>
                    </Card.Content>
                    : <Card.Content>
                        <View style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}>
                            <View style={{flex: 1, margin: 5}}>
                                <SubText>{props.orderDate}</SubText>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <SubText>{props.oderNumber}</SubText>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <SubText style={{maxWidth: 90}}>{props.status}</SubText>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <SubText>{currencyConverter.toModel(props.total)}</SubText>
                            </View>
                            <MaterialIcons name="preview" size={24} color={theme.colors.primary}
                                           style={{marginHorizontal: 5}}/>
                        </View>
                    </Card.Content>
                }
            </Card>
        )
    }
;