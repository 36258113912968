import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {Text} from "react-native-paper";
import {useThemeContext} from "../../contexts/ThemeContext";

export type AgeIndicatorProps = {
    style?: StyleProp<ViewStyle>;
};

export const AgeIndicator = (props: AgeIndicatorProps) => {
    const {theme} = useThemeContext();
    const styles = StyleSheet.create({
        ageLabel: {
            fontSize: 15,
            fontWeight: 'bold',
            color: theme.colors.primary
        },
        ageValue: {
            fontSize: 24,
            fontWeight: 'bold',
            marginLeft: 10,
            color: theme.colors.primary
        },
        container: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });
    return (
        <View style={[styles.container, props.style]}>
            <Text style={styles.ageValue} >18+</Text>
            <Text style={styles.ageLabel} > Age</Text>
        </View>
    );
};