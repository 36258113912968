import React, {useState} from "react";
import {ScrollView, View} from "react-native";
import {Card} from "react-native-paper";
import {MaterialIcons} from "@expo/vector-icons";
import {Strong} from "@expo/html-elements";
import {useNavigation} from "@react-navigation/native";

import {isEmail, isRequired, SessionService} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {ValidatedInput} from "../common/ValidatedInput";
import {SubText} from "../typography";
import {Button} from "../common/Button";
import {PageFooter} from "../common/PageFooter";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import Environment from "../../models/Environment";

export const ForgotUsernameScreen = () => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const [email, setEmail] = useState('');
    const [emailErrors, setEmailErrors] = useState<Array<string>>([]);
    const [emailValid, setEmailValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const onSubmit = async () => {
        try {
            setError(false);
            await SessionService.create(Environment.apiBaseUrl as string,).forgotUsername(email);
            setSuccess(true);
        } catch (ex) {
            setError(true);
        }
    };

    const onSignIn = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.SIGN_IN);
    };

    const onCancel = () => {
        // @ts-ignore
        navigation.pop();
    };

    const onEmailChanged = (value: string) => {
        setEmail(value);
    };

    const onEmailValidated = (errors: Array<string>) => {
        setEmailErrors(errors);
        setEmailValid(errors.length === 0);
    };

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{justifyContent: 'center'}}>
                <View style={{alignItems: 'center'}}>
                    {!success ?
                        <Card style={{maxWidth: 350, margin: 30}}>
                            <Card.Title title={'Forgot Username'}
                                        style={{backgroundColor: theme.colors.primary, borderRadius: 5}}
                                        titleStyle={{fontSize: 24, fontWeight: 'bold', color: theme.colors.surface}}/>
                            <Card.Content>
                                <SubText style={{marginVertical: 10}}>Enter the e-mail for your account below and we'll
                                    send you your username.</SubText>
                                <ValidatedInput label={'Email'}
                                                errors={emailErrors}
                                                value={email}
                                                onChangeText={onEmailChanged}
                                                onValidate={onEmailValidated}
                                                rules={[isEmail, isRequired]}
                                                mode={'outlined'}
                                                style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                />
                                {error
                                    ? <SubText style={{color: theme.colors.error, textAlign: 'center'}}>
                                        Some error occurred when attempting to retrieve your username. Please try again
                                        later.
                                    </SubText>
                                    : <></>
                                }
                            </Card.Content>
                            <Card.Actions style={{justifyContent: 'space-between'}}>
                                <Button disabled={!emailValid} onPress={onSubmit}>Submit</Button>
                                <Button onPress={onCancel}>Cancel</Button>
                            </Card.Actions>
                        </Card>
                        :
                        <Card style={{maxWidth: 350, margin: 30}}>
                            <Card.Title title={'Confirmation'}
                                        style={{backgroundColor: theme.colors.primary, borderRadius: 5}}
                                        titleStyle={{fontSize: 24, fontWeight: 'bold', color: theme.colors.surface}}
                                        left={() => <MaterialIcons name={'lock'} size={24}
                                                                   color={theme.colors.surface}/>}/>
                            <Card.Content>
                                <SubText style={{margin: 10}}>
                                    If our database contains a record that matches the information you provided we will
                                    send you an email to the address we have on file.
                                </SubText>
                                <SubText style={{margin: 10}}>
                                    Please check your email and look for a message with the subject <Strong>Username
                                    Reminder</Strong>, it will contain your username.
                                </SubText>
                                <SubText style={{margin: 10}}>
                                    If you do not receive an email, you may not have signed up for an account
                                    yet, <Strong style={{textDecorationLine: 'underline'}}
                                                 onPress={onSignIn}>Sign Up</Strong>.
                                </SubText>
                            </Card.Content>
                            <Card.Actions>
                                <Button style={{width: '100%'}} onPress={onCancel}>Close</Button>
                            </Card.Actions>
                        </Card>
                    }
                </View>
            </View>
            <PageFooter/>
        </ScrollView>
    );
};