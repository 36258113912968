import {createNativeStackNavigator} from "@react-navigation/native-stack";

import {useAuth} from "../../contexts/AuthContext";
import {CartStackWeb} from "./CartStackWeb";
import {HowItWorksScreen} from "../screens/HowItWorksScreen";
import {FaqScreen} from "../screens/FaqScreen";
import {LabLocationsScreenWeb} from "../screens/LabLocationsScreenWeb";
import {MyAccountTabStack} from "./MyAccountTabStack";
import {TermsOfServiceScreen} from "../screens/TermsOfServiceScreen";
import {PrivacyPolicyScreen} from "../screens/PrivacyPolicyScreen";
import {OrderableTestDetailsScreen} from "../screens/OrderableTestDetailsScreen";
import {BrowseScreen} from "../screens/BrowseScreen";
import {AppHeader} from "../common/AppHeader";
import {SessionTimeout} from "../SessionTimeout";
import {MAX_SCREEN_WIDTH} from "../../models";
import {ContactUsScreen} from "../screens/ContactUsScreen";
import {LabServiceAreasScreenWeb} from "../screens/LabServiceAreasScreenWeb";

export const SCREEN_NAMES = {
    BROWSE: 'BrowseTab',
    CART: 'CartTab',
    HOW_IT_WORKS: 'HowItWorksTab',
    HELP: 'FaqTab',
    LAB_LOCATIONS: 'LabLocationsTab',
    SERVICE_AREAS: 'ServiceAreasTab',
    CONTACT_US: 'ContactUsTab',
    RESULTS: 'ResultsTab',
    ACCOUNT: 'SettingsTab',
    TERMS_OF_SERVICE: 'TermsOfService',
    CONNECT: 'Connect',
    PRIVACY_POLICY: 'PrivacyPolicy',
    TEST_DETAILS: 'OrderableTestDetailsScreen',
    FORGOT_USERNAME: 'ForgotUsername',
    FORGOT_PASSWORD: 'ForgotPassword',
    SIGN_IN: 'SignIn',
    REGISTER: 'Register',
};

const Stack = createNativeStackNavigator();

export const AppStackWeb = () => {
    const {authData, session} = useAuth();

    return (
        <>
            <SessionTimeout/>
            <Stack.Navigator
                screenOptions={{
                    header: () => {
                        return <AppHeader/>
                    },
                    contentStyle: {width: '100%', maxWidth: MAX_SCREEN_WIDTH, alignSelf: 'center'}
                }}
            >
                <Stack.Screen
                    options={{title: 'Browse Tests'}}
                    name={SCREEN_NAMES.BROWSE}
                    component={BrowseScreen}
                />
                <Stack.Screen
                    options={{title: 'How It Works'}}
                    name={SCREEN_NAMES.HOW_IT_WORKS}
                    component={HowItWorksScreen}
                />
                <Stack.Screen
                    options={{title: 'Help / FAQ'}}
                    name={SCREEN_NAMES.HELP}
                    component={FaqScreen}
                />
                <Stack.Screen
                    options={{title: 'Lab Locations'}}
                    name={SCREEN_NAMES.LAB_LOCATIONS}
                    component={LabLocationsScreenWeb}
                />
                {session?.appConfig.serviceAreasEnabled &&
                    <Stack.Screen
                        options={{title: 'Service Areas'}}
                        name={SCREEN_NAMES.SERVICE_AREAS}
                        component={LabServiceAreasScreenWeb}
                    />
                }
                <Stack.Screen
                    options={{title: 'Contact Us'}}
                    name={SCREEN_NAMES.CONTACT_US}
                    component={ContactUsScreen}
                />
                {!session?.appConfig.guestCheckoutEnabled &&
                    <Stack.Screen
                        options={{title: authData ? 'My Account' : 'Sign In'}}
                        name={SCREEN_NAMES.ACCOUNT}
                        component={MyAccountTabStack}
                    />
                }
                <Stack.Screen
                    options={{title: 'Cart'}}
                    name={SCREEN_NAMES.CART}
                    component={CartStackWeb}
                />
                <Stack.Screen
                    options={{title: 'Terms Of Service'}}
                    name={SCREEN_NAMES.TERMS_OF_SERVICE}
                    component={TermsOfServiceScreen}
                />
                <Stack.Screen
                    options={{title: 'Privacy Policy'}}
                    name={SCREEN_NAMES.PRIVACY_POLICY}
                    component={PrivacyPolicyScreen}
                />
                <Stack.Screen
                    options={{title: 'Order-able Test Details'}}
                    name={SCREEN_NAMES.TEST_DETAILS}
                    component={OrderableTestDetailsScreen}
                />
            </Stack.Navigator>
        </>
    );
};
