import {Paragraph} from "react-native-paper";
import {StyleSheet, View} from "react-native";

export const DefaultFastingContent = () => {
    const styles = StyleSheet.create({
        fastingDescription: {
            fontSize: 15
        }
    });

    return (
        <View>
            <Paragraph style={styles.fastingDescription}>
                This test requires you to be fasting prior to collection. Blood composition is
                significantly altered after meals and is not suitable for many clinical chemistry tests.
                Improper preparation for this test may cause the results to be inaccurate for diagnostic
                purposes.
            </Paragraph>
            <Paragraph style={styles.fastingDescription}>
                <ol>
                    <li>Abstain from all food for a minimum of 10 – 12 hours (12 hours for triglyceride
                        analysis). Only water may be consumed during this time.
                    </li>
                    <li>Check with your doctor to determine if you should continue to take your usual
                        medication(s) during this time. The laboratory cannot advise you on medications.
                    </li>
                    <li>As soon as the laboratory has collected the required specimen, you may resume
                        normal eating unless otherwise directed by your doctor.
                    </li>
                </ol>
            </Paragraph>
        </View>
    );
}