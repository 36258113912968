import {StyleSheet} from 'react-native';
import {LuminateColors} from "./LuminateColors";

export const LuminateStyles = StyleSheet.create({
    container: {
        backgroundColor: LuminateColors.blue,
    },
    fullHeightContainer: {
        flex: 1,
    },
    centerText: {
        textAlign: 'center',
    },
    centeredInContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});