import {useEffect, useState} from "react";
import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";
import {A} from "@expo/html-elements";
import {useNavigation} from "@react-navigation/native";

import {AcknowledgmentsProps} from "./Acknowledgements";
import {LabeledSwitch} from "../common/LabeledSwitch";
import {SubText} from "../typography";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {useThemeContext} from "../../contexts/ThemeContext";

export const PplAcknowledgments = (props: AcknowledgmentsProps) => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const [over18, setOver18] = useState(false);
    const [forMyself, setForMyself] = useState(false);
    const [resideInState, setResideInState] = useState(false);
    const [agree, setAgree] = useState(false);
    const [pplTos, setPplTos] = useState(false);

    const styles = StyleSheet.create({
        buttonPadding: {
            margin: 10
        },
        textColor: {
            color: theme.colors.primary
        },
        heading: {
            paddingVertical: 10,
            paddingHorizontal: 5,
            fontSize: 17
        }
    });

    useEffect(() => {
        props.onAcknowledgment(over18 && forMyself && resideInState && agree && pplTos);
    }, [over18, forMyself, resideInState, agree, pplTos]);

    return (
        <View style={{margin: 5}}>
            <Text style={[styles.heading, styles.textColor]}>Before adding items to your cart, please confirm the
                following information:</Text>
            <LabeledSwitch value={over18}
                           onValueChanged={() => setOver18(!over18)}>
                <SubText>I am over 18 years old.</SubText>
            </LabeledSwitch>
            <LabeledSwitch value={forMyself}
                           onValueChanged={() => setForMyself(!forMyself)}>
                <SubText>I am purchasing this for myself.</SubText>
            </LabeledSwitch>
            <LabeledSwitch value={resideInState}
                           onValueChanged={() => setResideInState(!resideInState)}>
                <SubText>I live within the United States.</SubText>
            </LabeledSwitch>
            <LabeledSwitch value={agree}
                           onValueChanged={() => setAgree(!agree)}>
                <SubText>
                    I agree to the <A style={[styles.textColor, {textDecorationLine: 'underline'}]}
                                      onPress={() => navigation.navigate(SCREEN_NAMES.TERMS_OF_SERVICE)}>Luminate
                    Health Terms of
                    Service</A>. Your information will be handled by Luminate Health in accordance with its <A
                    style={[styles.textColor, {textDecorationLine: 'underline'}]}
                    onPress={() => navigation.navigate(SCREEN_NAMES.PRIVACY_POLICY)}>Privacy Policy</A>.
                </SubText>
            </LabeledSwitch>

            <LabeledSwitch value={pplTos}
                           onValueChanged={() => setPplTos(!pplTos)}>
                <SubText>I agree to all of the following:</SubText>
                <ul>
                    <li>
                        <SubText>
                            I understand that, because a physician has not ordered these tests, my health insurance
                            (including
                            Medicare and Medicaid) will not pay for these tests. I will not ask my health insurer,
                            Medicare,
                            Medicaid, or any other federal or state health program to pay for these tests.
                        </SubText>
                    </li>
                    <li>
                        <SubText>
                            I understand that PPL Walk-In Wellness Lab Testing does not replace the advice and care of
                            my
                            physician. It is intended for educational purposes only. A PPL Walk-In Wellness Lab Test
                            result is not a
                            medical diagnosis, a treatment, or a form of medical advice. I am solely responsible for
                            promptly
                            talking with a physician about my lab test results. I understand that only my physician can
                            interpret my test results.
                        </SubText>
                    </li>
                    <li>
                        <SubText>
                            I release and will not hold PPL Walk-In Wellness Lab responsible if I do not promptly
                            communicate
                            the results of these test to my physician.
                        </SubText>
                    </li>
                    <li>
                        <SubText>
                            I understand that I am not entering a lab/patient relationship by ordering this test.
                        </SubText>
                    </li>
                    <li>
                        <SubText>
                            I understand that I am responsible for accessing my results and providing them to my
                            physician.
                        </SubText>
                    </li>
                    <li>
                        <SubText>
                            In the event of a critical result, I agree that the APG PPL on-call physician will attempt
                            to contact me to inform me of my result. I understand that these results should be
                            communicated to my primary care physician in the case that the on-call physician is able to
                            reach me as well as if the on-call physician is not able to reach me.
                        </SubText>
                    </li>
                </ul>
            </LabeledSwitch>
        </View>
    );
};