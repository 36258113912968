import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {StripeCardElement} from "@stripe/stripe-js/types/stripe-js/elements";

import {
    AuthData,
    Cart,
    JwtUtils,
    LuminateRuntimeException,
    Order,
    OrderableTest,
    OrderCancellationReason,
    OrderPatientService,
    Page,
    Pagination,
    PatientProfile,
    PatientProfileService,
    PatientShoppingCartService,
    PaymentException,
    PhoneType,
    PreCheckoutStatus,
    PublicShoppingCartService,
    RegistrationRequest,
    Session,
    SessionService,
    ValidUsernameResponseType
} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "./ThemeContext";
import {StorageKeys, StorageService} from "../services/StorageService";
import {StripeService} from "../services/StripeService";
import Environment from "../models/Environment";
import {StripePaymentMetadata} from "../models";
import {
    AnalyticsService,
    LoginSuccessfulEvent,
    PaymentEvent,
    RefundEvent,
    RemoveFromCartEvent,
    SignUpEvent
} from "../services/AnalyticsService";
import {TestToAnalyticsItemConverter} from "../services/converter/TestToAnalyticsItemConverter";
import {LuminateTheme} from "../styles/LuminateTheme";
import {DropDownItem} from '@luminate/luminate-native-components';

type AuthContextData = {
    session: Session | null;
    genders: Array<DropDownItem>;
    selectSession(value: Session): void;
    authData: AuthData | undefined;
    loading: Boolean;
    signIn(username: string, password: string, captcha?: string): Promise<void>;
    signOut(): void;
    register(request: RegistrationRequest): Promise<void>;
    isValidUsername(username: string): Promise<ValidUsernameResponseType>;
    patientProfile?: PatientProfile,
    loadPatientProfile(): Promise<void>;
    loadPatientShoppingCart(): Promise<void>;
    updatePatientProfile(address1: string, city: string, state: string, zip: string, phone: string, phoneType: PhoneType, address2?: string): Promise<void>;
    changeEmailAddress(email: string, confirmEmail: string, password: string): Promise<void>;
    updatePatientGender(gender: string): Promise<void>;
    useLab(labId: number): boolean;
    useDefaultLab(excludedLabIds: Array<number>): boolean;

    cart?: Cart;
    setCart: (value?: Cart) => void;
    newOrder?: Order;
    setNewOrder: (value: Order) => void;
    orders: Array<Order>;
    ordersFilter: string;
    onOrdersFilterChanged: (value: string) => void;
    addNewOrder: (value: Order) => void;
    loadOrders: () => void;
    loadingOrders: boolean;
    cancelOrder: (toCancel: Order, reason: OrderCancellationReason, additionalInfo?: string) => void;
    testToAdd?: OrderableTest;
    setTestToAdd: (value: OrderableTest | undefined) => void;
    acknowledgmentsCollected: boolean;
    recordAcknowledgementsCollected: () => void;
    countItemsInCart: () => number;
    addTestToCart: (testId: number) => void;
    removeTestFromCart: (testId: number) => void;
    submitPaymentNative: () => void;
    submitPayment: (billingName: string, card: StripeCardElement, stripeService: StripeService) => void;
    assignCart: () => void;
    performPrecheckoutValidation: () => Promise<PreCheckoutStatus>;
    showSnack: boolean;
    snackMessage?: ReactNode;
    displaySnack: (message: ReactNode) => void;
    hideSnack: () => void;
};

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);
// @ts-ignore
export const AuthProvider = ({children}) => {
    const {switchTheme} = useThemeContext();
    const [session, setSession] = useState<Session | null>(null);
    const [authData, setAuthData] = useState<AuthData | undefined>(undefined);
    const [patientProfile, setPatientProfile] = useState<PatientProfile>();
    const [cart, setCart] = useState<Cart | undefined>();
    const [newOrder, setNewOrder] = useState<Order | undefined>();
    const [orders, setOrders] = useState<Array<Order>>([]);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [ordersFilter, setOrdersFilter] = useState<string>('');
    const [testToAdd, setTestToAdd] = useState<OrderableTest | undefined>();
    const [acknowledgmentsCollected, setAcknowledgmentsCollected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showSnack, setShowSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState<ReactNode>();
    const [genders, setGenders] = useState<Array<DropDownItem>>([]);
    const storageService = StorageService.create();

    useEffect(() => {
        loadFromStorage();
    }, []);

    const convertGenders = (genders: Record<string, string>): Array<DropDownItem> => {
        if (!genders) return [];

        return Object.keys(genders)
            .map((key) => {
                return {value: key, label: genders[key]} as DropDownItem;
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    };
    const useLab = (labId: number): boolean => {
        return !!(session?.lab?.id && session.lab.id === labId);
    };

    const useDefaultLab = (excludedLabIds: Array<number>): boolean => {
        return !excludedLabIds.includes(session?.lab?.id || 3);
    };

    const selectSession = async (session: Session) => {
        await storageService.addToStorage(StorageKeys.SESSION, JSON.stringify(session));
        setSession(session);
        switchTheme(session.lab.theme as LuminateTheme);
        setGenders(convertGenders(session.appConfig.genders));
    };

    const updatePatientProfile = async (address1: string, city: string, state: string, zip: string, phone: string, phoneType: PhoneType, address2?: string): Promise<void> => {
        const response = await PatientProfileService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).updateProfile(address1, city, state, zip, phone, phoneType, address2);
        setPatientProfile(response);
    };

    const changeEmailAddress = async (email: string, confirmEmail: string, password: string) => {
        const response = await PatientProfileService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).changeEmailAddress(email, confirmEmail, password);
        setPatientProfile(response);
    };

    const loadPatientProfile = async (encodedJwt?: string): Promise<void> => {
        setPatientProfile(await PatientProfileService.create(Environment.apiBaseUrl as string, encodedJwt ? encodedJwt : authData?.encodedJwt as string).getProfile());
    };

    const loadPatientShoppingCart = async (encodedJwt?: string): Promise<void> => {
        const previousCart = await PatientShoppingCartService.create(Environment.apiBaseUrl as string, encodedJwt ? encodedJwt : authData?.encodedJwt as string).retrieveMostRecentCart();
        if (previousCart) {
            setCart(previousCart);
            await storageService.addToStorage(StorageKeys.CART_TRANSACTION_ID, previousCart.transactionId);
        }
    }

    const updatePatientGender = async (gender: string): Promise<void> => {
        setPatientProfile(await PatientProfileService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).updateGender(gender));
    };

    const loadFromStorage = async (): Promise<void> => {
        try {
            const session = await SessionService.create(Environment.apiBaseUrl as string).getSession();
            switchTheme(session.appConfig.theme as LuminateTheme);
            setSession(session);
            setGenders(convertGenders(session.appConfig.genders));
            AnalyticsService.create().setVariables({lab_id: session.lab.id});

            const authToken = await storageService.getFromStorage(StorageKeys.AUTH_TOKEN);
            const convertedAuthData = JwtUtils.toAuthData(authToken);
            setAuthData(convertedAuthData);
            if (convertedAuthData) {
                await loadPatientProfile(authToken);
                await loadPatientShoppingCart(authToken);
                AnalyticsService.create().setVariables({patient_id: convertedAuthData.patientId});
            } else {
                const cartTransactionId = await storageService.getFromStorage(StorageKeys.CART_TRANSACTION_ID);
                if (cartTransactionId) {
                    const cart = await PublicShoppingCartService.create(Environment.apiBaseUrl as string).getCart(cartTransactionId)
                    setCart(cart);
                }
            }

            const storedAckCollection = await storageService.getFromStorage(StorageKeys.ACK_COLLECTED);
            if (storedAckCollection) {
                setAcknowledgmentsCollected(storedAckCollection === 'true');
            }
        } finally {
            setLoading(false);
        }
    };

    const signIn = async (username: string, password: string, captcha?: string): Promise<void> => {
        const sessionService = SessionService.create(Environment.apiBaseUrl as string);
        const response = await sessionService.authenticate(username, password, captcha);

        if (response) {
            setAuthData(response.authData);
            const encodedJwt = response.authData?.encodedJwt;
            await storageService.addToStorage(StorageKeys.AUTH_TOKEN, encodedJwt!);
            await loadPatientProfile(encodedJwt);
            await loadPatientShoppingCart(encodedJwt);
            AnalyticsService.create().sendEvent(new LoginSuccessfulEvent(session?.lab.id as number, response.authData?.patientId!));
        }
    };

    const register = async (request: RegistrationRequest): Promise<void> => {
        const sessionService = SessionService.create(Environment.apiBaseUrl as string);
        const response = await sessionService.signup(request);
        if (response) {
            await storageService.addToStorage(StorageKeys.AUTH_TOKEN, response.authData?.encodedJwt!);
            setAuthData(response.authData);
            /*
             * Load new account profile into state
             */
            await loadPatientProfile(response.authData?.encodedJwt!);
            AnalyticsService.create().sendEvent(new SignUpEvent(session?.lab.id as number, response.authData?.patientId!));
        }
    };

    const isValidUsername = async (username: string): Promise<ValidUsernameResponseType> => {
        const sessionService = SessionService.create(Environment.apiBaseUrl as string);
        return await sessionService.isValidUsername(username);
    };

    const signOut = async (): Promise<void> => {
        await clearAllCookies();
        setAuthData(undefined);
        setOrders([]);

        const sessionService = SessionService.create(Environment.apiBaseUrl as string);
        await sessionService.signout();
    };

    const clearAllCookies = async (): Promise<void> => {
        const cookies = Object.values(StorageKeys);

        setCart(undefined);
        setAcknowledgmentsCollected(false);

        for await (const cookie of cookies) {
            await storageService.removeFromStorage(cookie);
        }
    };

    const countItemsInCart = (): number => {
        return cart ? cart.cartTests.length : 0;
    };

    const recordAcknowledgementsCollected = async () => {
        await storageService.addToStorage(StorageKeys.ACK_COLLECTED, true.toString());
        setAcknowledgmentsCollected(true);
    };

    const addTestToCart = async (testId: number) => {
        const shoppingCart = await PublicShoppingCartService.create(Environment.apiBaseUrl as string, authData?.encodedJwt).addTestToCart(testId, cart?.transactionId);
        setCart(shoppingCart);
        await storageService.addToStorage(StorageKeys.CART_TRANSACTION_ID, shoppingCart.transactionId)
    };

    const removeTestFromCart = async (testId: number) => {
        if (cart?.transactionId) {
            const testToRemove = cart.cartTests.filter(test => test.testId === testId)[0];
            setCart(await PublicShoppingCartService.create(Environment.apiBaseUrl as string, authData?.encodedJwt).removeTestFromCart(testId, cart?.transactionId));

            AnalyticsService.create().sendEvent(new RemoveFromCartEvent(session?.lab.id as number, testToRemove.price / 100, [TestToAnalyticsItemConverter(testToRemove)], cart.patientId));
        }
    };

    const submitPaymentNative = async () => {
        const patientShoppingCartService = PatientShoppingCartService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string);
        if (!cart?.patientId) {
            await patientShoppingCartService.assignShoppingCartToUser(cart?.transactionId as string);
        }

        const updatedCart = await patientShoppingCartService.createOrUpdatePaymentIntent(cart?.transactionId as string);
        setCart(updatedCart);
    };

    const assignCart = async () => {
        if (!cart?.patientId) {
            setCart(await PatientShoppingCartService
                .create(Environment.apiBaseUrl as string, authData?.encodedJwt as string)
                .assignShoppingCartToUser(cart?.transactionId as string)
            );
        }
    };

    const performPrecheckoutValidation = async (): Promise<PreCheckoutStatus> => {
        if (!cart?.patientId) {
            throw new LuminateRuntimeException('Cart must first be assigned to a patient.');
        }

        return await PatientShoppingCartService
            .create(Environment.apiBaseUrl as string, authData?.encodedJwt as string)
            .performPrecheckoutValidation(cart?.transactionId as string);
    };

    const submitPayment = async (billingName: string, card: StripeCardElement, stripeService: StripeService) => {
        const patientShoppingCartService = PatientShoppingCartService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string);

        const updatedCart = await patientShoppingCartService.createOrUpdatePaymentIntent(cart?.transactionId as string);
        setCart(updatedCart);

        const submissionResult = await stripeService.submitPayment(updatedCart.stripeClientSecret, {name: billingName}, card, {
            labId: cart?.labId as number,
            transactionId: cart?.transactionId as string
        } as StripePaymentMetadata);
        if (submissionResult.error) {
            throw new PaymentException(`Unable to process payment for transactionId: ${cart?.transactionId}`, submissionResult.error);
        } else {
            const itemsOnPayment = updatedCart.cartTests.map(test => TestToAnalyticsItemConverter(test));
            AnalyticsService.create().sendEvent(
                new PaymentEvent(session?.lab.id as number,
                    authData?.patientId as number,
                    submissionResult.paymentIntent.id,
                    submissionResult.paymentIntent.amount / 100,  // Convert into USD
                    itemsOnPayment));
        }
    };

    const addNewOrder = async (value: Order) => {
        setNewOrder(value);
        setOrders(orders.concat([value]));
    };

    const onOrdersFilterChanged = async (filter: string): Promise<void> => {
        if (filter !== ordersFilter) {
            setOrders([]);
            setOrdersFilter(filter);
        }
    };

    const loadOrders = async () => {
        setLoadingOrders(true);
        try {
            if (authData) {
                const filter = ordersFilter.length > 0 ? ordersFilter : undefined;

                setOrders(await Pagination.loadPage(orders, async (page: Page): Promise<Array<Order>> => {
                    return await OrderPatientService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).getOrdersListByPatientId(page.nextPage, page.pageSize, filter);
                }, (a, b) => a.id === b.id));
            }
        } finally {
            setLoadingOrders(false);
        }
    };

    const cancelOrder = async (toCancel: Order, reason: OrderCancellationReason, additionalInfo?: string) => {
        const cancelResponse = await OrderPatientService.create(Environment.apiBaseUrl as string, authData?.encodedJwt as string).refundPayment(toCancel.stripePaymentIntentId, reason, additionalInfo);
        if (cancelResponse.order && cancelResponse.refundId) {
            const cancelledOrder = cancelResponse.order;
            const ordersClone = [...orders];
            const cancelledOrderIndex = ordersClone.findIndex(order => order.id === toCancel.id);
            ordersClone.splice(cancelledOrderIndex, 1, cancelledOrder);
            setOrders(ordersClone);
            AnalyticsService.create().sendEvent(
                new RefundEvent(
                    session?.lab.id as number,
                    cancelledOrder.patientId,
                    cancelResponse.refundId,
                    cancelledOrder.totals / 100,
                    cancelledOrder.orderTests.map(test => TestToAnalyticsItemConverter(test))
                )
            );
        }
    };

    const displaySnack = (message: ReactNode): void => {
        setSnackMessage(message);
        setShowSnack(true);
    }

    const hideSnack = (): void => {
        setSnackMessage(undefined);
        setShowSnack(false);
    };

    return <AuthContext.Provider
        value={{
            session,
            genders,
            selectSession,
            authData,
            loading,
            signIn,
            signOut,
            register,
            isValidUsername,
            patientProfile,
            loadPatientProfile,
            loadPatientShoppingCart,
            updatePatientProfile,
            changeEmailAddress,
            updatePatientGender,
            cart,
            setCart,
            newOrder: newOrder,
            orders,
            ordersFilter,
            onOrdersFilterChanged,
            addNewOrder,
            setNewOrder,
            loadOrders,
            loadingOrders,
            cancelOrder,
            testToAdd,
            setTestToAdd,
            acknowledgmentsCollected,
            recordAcknowledgementsCollected,
            countItemsInCart,
            addTestToCart,
            removeTestFromCart,
            submitPaymentNative,
            submitPayment,
            assignCart,
            performPrecheckoutValidation,
            showSnack,
            snackMessage,
            displaySnack,
            hideSnack,
            useDefaultLab,
            useLab,
        }}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}
