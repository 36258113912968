import {StyleSheet, View} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText} from "../typography";

export type TurnAroundTimeIndicatorProps = {
    turnAroundTime: string;
    size?: number;
}

export const TurnAroundTimeIndicator = (props: TurnAroundTimeIndicatorProps) => {
    const {theme} = useThemeContext();
    const DEFAULT_ICON_SIZE = 32;

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });
    return (
        <View style={styles.container}>
            <MaterialCommunityIcons name={'clock'} size={props.size || DEFAULT_ICON_SIZE} color={theme.colors.primary}/>
            <SubText style={{color: theme.colors.primary, fontWeight: 'bold', marginLeft: 10, textAlign: 'center'}}>{props.turnAroundTime}</SubText>
        </View>
    );
};