import {
    Theme as NavigationTheme,
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import {
    MD3Theme as PaperTheme,
    MD3DarkTheme as PaperDarkTheme,
    MD3LightTheme as PaperDefaultTheme, adaptNavigationTheme,
} from 'react-native-paper';
import merge from 'deepmerge';

import {LuminateColors} from "./LuminateColors";

export type LuminateTheme = PaperTheme & NavigationTheme & {
    colors: {
        secondaryAccent?: string;
    }
};

const {LightTheme, DarkTheme} = adaptNavigationTheme({reactNavigationDark: NavigationDarkTheme, reactNavigationLight: NavigationDefaultTheme});

const CombinedDefaultTheme = merge(merge(PaperDefaultTheme, LightTheme), {colors: {secondaryAccent: '#FFFFFF'}} as LuminateTheme);
const CombinedDarkTheme = merge(merge(PaperDarkTheme, DarkTheme), {colors: {secondaryAccent: '#FFFFFF'}} as LuminateTheme);

// @ts-ignore
export const LuminateDarkTheme: LuminateTheme = merge(CombinedDarkTheme, {
    colors: {
        primary: LuminateColors.blue,
        secondary: LuminateColors.accent
    }
});

// @ts-ignore
export const LuminateDefaultTheme: LuminateTheme = merge(CombinedDefaultTheme, {
    colors: {
        primary: LuminateColors.blue,
        secondary: LuminateColors.accent
    }
});

// Hexadecimal color code for transparency: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export enum Opacity {
    PERCENT_100 = 'FF',
    PERCENT_99 = 'FC',
    PERCENT_98 = 'FA',
    PERCENT_97 = 'F7',
    PERCENT_96 = 'F5',
    PERCENT_95 = 'F2',
    PERCENT_94 = 'F0',
    PERCENT_93 = 'ED',
    PERCENT_92 = 'EB',
    PERCENT_91 = 'E8',
    PERCENT_90 = 'E6',
    PERCENT_89 = 'E3',
    PERCENT_88 = 'E0',
    PERCENT_87 = 'DE',
    PERCENT_86 = 'DB',
    PERCENT_85 = 'D9',
    PERCENT_84 = 'D6',
    PERCENT_83 = 'D4',
    PERCENT_82 = 'D1',
    PERCENT_81 = 'CF',
    PERCENT_80 = 'CC',
    PERCENT_79 = 'C9',
    PERCENT_78 = 'C7',
    PERCENT_77 = 'C4',
    PERCENT_76 = 'C2',
    PERCENT_75 = 'BF',
    PERCENT_74 = 'BD',
    PERCENT_73 = 'BA',
    PERCENT_72 = 'B8',
    PERCENT_71 = 'B5',
    PERCENT_70 = 'B3',
    PERCENT_69 = 'B0',
    PERCENT_68 = 'AD',
    PERCENT_67 = 'AB',
    PERCENT_66 = 'A8',
    PERCENT_65 = 'A6',
    PERCENT_64 = 'A3',
    PERCENT_63 = 'A1',
    PERCENT_62 = '9E',
    PERCENT_61 = '9C',
    PERCENT_60 = '99',
    PERCENT_59 = '96',
    PERCENT_58 = '94',
    PERCENT_57 = '91',
    PERCENT_56 = '8F',
    PERCENT_55 = '8C',
    PERCENT_54 = '8A',
    PERCENT_53 = '87',
    PERCENT_52 = '85',
    PERCENT_51 = '82',
    PERCENT_50 = '80',
    PERCENT_49 = '7D',
    PERCENT_48 = '7A',
    PERCENT_47 = '78',
    PERCENT_46 = '75',
    PERCENT_45 = '73',
    PERCENT_44 = '70',
    PERCENT_43 = '6E',
    PERCENT_42 = '6B',
    PERCENT_41 = '69',
    PERCENT_40 = '66',
    PERCENT_39 = '63',
    PERCENT_38 = '61',
    PERCENT_37 = '5E',
    PERCENT_36 = '5C',
    PERCENT_35 = '59',
    PERCENT_34 = '57',
    PERCENT_33 = '54',
    PERCENT_32 = '52',
    PERCENT_31 = '4F',
    PERCENT_30 = '4D',
    PERCENT_29 = '4A',
    PERCENT_28 = '47',
    PERCENT_27 = '45',
    PERCENT_26 = '42',
    PERCENT_25 = '40',
    PERCENT_24 = '3D',
    PERCENT_23 = '3B',
    PERCENT_22 = '38',
    PERCENT_21 = '36',
    PERCENT_20 = '33',
    PERCENT_19 = '30',
    PERCENT_18 = '2E',
    PERCENT_17 = '2B',
    PERCENT_16 = '29',
    PERCENT_15 = '26',
    PERCENT_14 = '24',
    PERCENT_13 = '21',
    PERCENT_12 = '1F',
    PERCENT_11 = '1C',
    PERCENT_10 = '1A',
    PERCENT_9 = '17',
    PERCENT_8 = '14',
    PERCENT_7 = '12',
    PERCENT_6 = '0F',
    PERCENT_5 = '0D',
    PERCENT_4 = '0A',
    PERCENT_3 = '08',
    PERCENT_2 = '05',
    PERCENT_1 = '03',
    PERCENT_0 = '00',
}

export class ThemeUtilities {
    public static getOpacityForColor = (hexColor: string, opacity: Opacity) => {
        return `${hexColor}${opacity}`
    };
}