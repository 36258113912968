import {ActivityIndicator, Headline, Surface} from "react-native-paper";
import {FlatList, ListRenderItemInfo, StyleSheet, useWindowDimensions, View, ScrollView} from "react-native";
import {useEffect, useState} from "react";

import {FaqService, FaqCategory} from "@luminate/luminate-ts-sdk";

import {FaqCategoryList} from "../faq/FaqCategoryList";
import {useAuth} from "../../contexts/AuthContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {PageFooter} from "../common/PageFooter";
import {SubText} from "../typography";
import Environment from "../../models/Environment";

export const FaqScreen = () => {
    const [categories, setCategories] = useState<Array<FaqCategory>>([]);
    const [loading, setLoading] = useState(false);
    const {session, displaySnack} = useAuth();
    const {theme} = useThemeContext();
    const {width} = useWindowDimensions();

    const loadFaqCategories = async () => {
        setLoading(true);
        try {
            setCategories(await FaqService.create(Environment.apiBaseUrl as string).getFaqCategories(session?.lab?.id as number));
        } catch (ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to load the frequently
                asked questions. Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const renderFaqCategory = (categoryItem: ListRenderItemInfo<FaqCategory>) => {
        return <FaqCategoryList id={categoryItem.item.id} title={categoryItem.item.name}/>
    };

    useEffect(() => {
        loadFaqCategories();
    }, []);

    const styles = StyleSheet.create({
        centerPage: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        boldText: {
            padding: 10,
            fontSize: 40,
            fontWeight: 'bold',
            color: theme.colors.primary
        },
        rootContainer: {
            marginVertical: 10,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });
    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{alignItems: 'center'}}>
                <View style={{maxWidth: 955, width: '100%'}}>
                    <Surface>
                        <View style={styles.rootContainer}>
                            {loading
                                ? <ActivityIndicator animating={true} size={'large'}/>
                                : <FlatList data={categories}
                                            renderItem={renderFaqCategory}
                                            ListHeaderComponent={<View style={[styles.centerPage]}>
                                                <Headline style={styles.boldText}>Frequently Asked Questions</Headline>
                                            </View>}
                                            keyExtractor={item => `faq-category-${item.name}`}
                                            style={{width: '100%'}}
                                />
                            }
                        </View>
                        <PageFooter/>
                    </Surface>
                </View>
            </View>
        </ScrollView>
    );
};