import React, {useEffect, useState} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";
import {ActivityIndicator, Surface, Text} from "react-native-paper";
import {FlatList, ScrollView, StyleSheet, View} from "react-native";
import {PageFooter} from "../common/PageFooter";
import {Header24, SubText} from "../typography";
import {ServiceAreaItem} from "../contact/ServiceAreaItem";
import {DatServiceAreasContent, DatServiceAreasService} from "@luminate/luminate-ts-sdk";
import {useAuth} from "../../contexts/AuthContext";
import Environment from "../../models/Environment";

export const LabServiceAreasScreenWeb = () => {
    const [serviceAreasContent, setServiceAreasContent] = useState<DatServiceAreasContent | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const {theme} = useThemeContext();
    const {displaySnack} = useAuth();

    useEffect(() => {
        loadServiceAreasContent();
    }, []);

    const loadServiceAreasContent = async () => {
        setLoading(true);
        try {
            setServiceAreasContent(await DatServiceAreasService.create(Environment.apiBaseUrl as string).getDatServiceAreasContent());
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>We were unable to load the Service Areas content.
                Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const styles = StyleSheet.create({
        flatList: {
            paddingLeft: 20,
            paddingTop: 20
        },
        rootContainer: {
            marginVertical: 10,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    const renderEmptyServiceAreasContent = () => {
        return (
            <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: theme.colors.errorContainer
            }}>
                <Text style={{color: theme.colors.onErrorContainer}}>Could not load Service Areas Content.</Text>
            </View>
        );
    };

    return (
        <>
            <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
                <View style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    <View style={{maxWidth: 955, width: '100%'}}>
                        <Surface>
                            <View style={styles.rootContainer}>
                                {loading
                                    ? <ActivityIndicator animating={true} size={'large'}/>
                                    : serviceAreasContent
                                    ? <FlatList style={styles.flatList} data={serviceAreasContent?.locations}
                                                renderItem={location => <ServiceAreaItem text={location.item}/>}
                                                ListHeaderComponent={<Header24
                                                    style={{marginBottom: 15}}>{serviceAreasContent?.header}</Header24>}
                                                ListEmptyComponent={renderEmptyServiceAreasContent}
                                                keyExtractor={area => `${area}`}/>
                                    : <></>
                                }
                            </View>

                        </Surface>
                    </View>
                </View>
            </ScrollView>
            <PageFooter/>
        </>
    );
};
