import {List} from "react-native-paper";
import {ColorValue, StyleProp, StyleSheet, TextStyle} from "react-native";
import {useThemeContext} from "../../contexts/ThemeContext";
import {Currency} from "../common/Currency";

export type SummaryLineItemProps = {
    description: string;
    value: number;
    style?: StyleProp<TextStyle>;
    currencyStyle?: StyleProp<TextStyle>;
    debitColor?: ColorValue | undefined;
    creditColor?: ColorValue | undefined;
};
export const SummaryLineItem = (props: SummaryLineItemProps) => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        debit: {
            color: props.debitColor || theme.colors.primary
        },
        credit: {
            color: props.creditColor || theme.colors.error
        },
        description: {
            fontSize: 15,
            paddingRight: 10
        },
        currency: {
            fontSize: 17
        }
    });

    const isCredit = (props.value && props.value < 0);
    const creditStyle = isCredit ? styles.credit : styles.debit;

    return (
        <List.Item style={{justifyContent: 'space-between'}} titleStyle={[styles.description, creditStyle, props.style]}
                   title={props.description}
                   right={() => <Currency style={[styles.currency, creditStyle, props.currencyStyle]}
                                          value={props.value || 0}/>}
        />
    )
};