import {ScrollView, View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {OrderableTest, IndividualTest} from "@luminate/luminate-ts-sdk";

import {SubText, Text} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";

export interface KeepSelectionSuccessfulProps {
    testName: string;
    testComponents: Array<OrderableTest | IndividualTest>;
    duplicateTests: Array<OrderableTest | IndividualTest>;
    onClose: () => void | undefined;
    onShowCart: () => void | undefined;
    onUndo: () => Promise<void> | undefined;
}

export const KeepSelectionSuccessful = (props: KeepSelectionSuccessfulProps) => {
    const {theme} = useThemeContext();

    const renderTestComponentList = (tests: Array<OrderableTest | IndividualTest>) => {
        return (
            <ScrollView>
                <ul>
                    {tests.map(test =>
                        <li key={`test-component-${test.testId}`}>
                            <SubText>{test.name}</SubText>
                        </li>
                    )}
                </ul>
            </ScrollView>
        );
    };

    return (
        <View style={{alignItems: 'center'}}>
            <View style={{marginHorizontal: 20}}>
                <View style={{flexDirection: 'row', marginVertical: 20}}>
                    <MaterialIcons name={'check'} size={24} color={theme.colors.primary}/>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: theme.colors.primary,
                        marginLeft: 10
                    }}>
                        Added To Cart!</Text>
                </View>
                <Text>
                    {props.testName}
                </Text>
                {renderTestComponentList(props.testComponents)}

                <SubText style={{marginVertical: 10}}>
                    Duplicate tests are not permitted. We removed the following tests from your cart.
                </SubText>
                {renderTestComponentList(props.duplicateTests)}
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center '}}>
                <Button style={{padding: 5, width: 180}} onPress={props.onClose}>Continue Browsing</Button>
                <Button style={{padding: 5, width: 90}} onPress={props.onUndo}>Undo</Button>
            </View>
            <Button style={{padding: 5, width: 270}} onPress={props.onShowCart}>Checkout Now</Button>
        </View>
    );
};