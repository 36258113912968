import React, {useState} from "react";
import {ScrollView, View} from "react-native";
import {Card} from "react-native-paper";
import {Strong} from "@expo/html-elements";
import {MaterialIcons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";

import {isRequired, SessionService} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {ValidatedInput} from "../common/ValidatedInput";
import {SubText} from "../typography";
import {Button} from "../common/Button";
import {PageFooter} from "../common/PageFooter";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import Environment from "../../models/Environment";

export const ForgotPasswordScreen = () => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const [userName, setUserName] = useState('');
    const [userNameErrors, setUserNameErrors] = useState<Array<string>>([]);
    const [userNameValid, setUserNameValid] = useState(false);
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState(false);

    const onForgotUsername = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.FORGOT_USERNAME);
    };

    const onSignIn = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.SIGN_IN);
    };

    const onSubmit = async () => {
        try {
            setError(false);
            await SessionService.create(Environment.apiBaseUrl as string).forgotPassword(userName);
            setSuccess(true);
        } catch (ex) {
            setError(true);
        }
    };

    const onCancel = () => {
        // @ts-ignore
        navigation.pop();
    };

    const onUserNameChanged = (value: string) => {
        setUserName(value);
    };

    const onUserNameValidated = (errors: Array<string>) => {
        setUserNameErrors(errors);
        setUserNameValid(errors.length === 0);
    };

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{justifyContent: 'center'}}>
                <View style={{alignItems: 'center'}}>
                    {!success
                        ?
                        <Card style={{maxWidth: 350, margin: 30}}>
                            <Card.Title title={'Forgot Password'}
                                        style={{backgroundColor: theme.colors.primary, borderRadius: 5}}
                                        titleStyle={{fontSize: 24, fontWeight: 'bold', color: theme.colors.surface}}/>
                            <Card.Content style={{justifyContent: 'space-between'}}>
                                <SubText style={{marginVertical: 10}}>
                                    Enter your username below and we'll send you password reset instructions.
                                </SubText>
                                <ValidatedInput label='Username'
                                                value={userName}
                                                rules={[isRequired]}
                                                errors={userNameErrors}
                                                onChangeText={onUserNameChanged}
                                                onValidate={onUserNameValidated}
                                                mode={'outlined'}
                                                style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                />
                                {error ?
                                    <SubText style={{color: theme.colors.error, textAlign: 'center'}}>
                                        Some error occurred when attempting to reset your password. Please try again
                                        later.
                                    </SubText> : <></>
                                }
                                <SubText style={{marginVertical: 10}}>
                                    If you don't remember your username please goto <Strong
                                    style={{textDecorationLine: 'underline'}}
                                    onPress={onForgotUsername}>
                                    Username Reminder
                                </Strong> to
                                    receive an email containing your username.
                                </SubText>
                            </Card.Content>
                            <Card.Actions>
                                <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <View
                                        style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Button style={{margin: 5}}
                                                disabled={!userNameValid}
                                                onPress={onSubmit}>
                                            Submit
                                        </Button>
                                        <Button style={{margin: 5}} onPress={onCancel}>Cancel</Button>
                                    </View>
                                </View>
                            </Card.Actions>
                        </Card>
                        :
                        <Card style={{maxWidth: 350, margin: 30}}>
                            <Card.Title title={'Check Your Email'}
                                        style={{backgroundColor: theme.colors.primary, borderRadius: 5}}
                                        titleStyle={{fontSize: 24, fontWeight: 'bold', color: theme.colors.surface}}
                                        left={() => <MaterialIcons name={'lock'} size={24}
                                                                   color={theme.colors.surface}/>}/>
                            <Card.Content style={{justifyContent: 'space-between'}}>
                                <SubText style={{margin: 10}}>
                                    If our database contains a record that matches the information you provided we will
                                    send you an email to the address we have on file.
                                </SubText>
                                <SubText style={{margin: 10}}>
                                    Please check your email and look for a message with the subject <Strong>Reset
                                    Password Link</Strong>, for a link to reset your password.
                                </SubText>
                                <SubText style={{margin: 10}}>
                                    If you do not receive an email, you may not have your username correct,
                                    goto <Strong style={{textDecorationLine: 'underline'}}
                                                 onPress={onForgotUsername}>Username
                                    Reminder</Strong> for your username. Of if you have not signed up for an
                                    account, <Strong style={{textDecorationLine: 'underline'}}
                                                     onPress={onSignIn}>Sign
                                    Up</Strong>.
                                </SubText>
                            </Card.Content>
                            <Card.Actions>
                                <Button style={{width: '100%'}} onPress={onCancel}>Close</Button>
                            </Card.Actions>
                        </Card>
                    }
                </View>
            </View>
            <PageFooter/>
        </ScrollView>
    );
};