/*
 * Data model taken from: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
 */
export type CurrencyCode =
    'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRU'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'CNY'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLE'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STN'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UYW'
    | 'UZS'
    | 'VED'
    | 'VES'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWL';

export abstract class AnalyticsEvent {
    protected constructor(public readonly event: string, public readonly lab_id: number) {
    }
}

export type AnalyticsItem = {
    item_id: string;
    item_name: string;
    index?: number;
    item_brand?: string;
    item_category?: string;
    price?: number;
    quantity?: number;
};

export const GUEST_PATIENT_ID = 0;

export class AddToCardEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'add_to_cart';

    constructor(lab_id: number,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly currency: CurrencyCode = 'USD') {
        super(AddToCardEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class RemoveFromCartEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'remove_from_cart';

    constructor(lab_id: number,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly currency: CurrencyCode = 'USD') {
        super(RemoveFromCartEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class LoginSuccessfulEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'login';

    constructor(lab_id: number, public readonly patient_id: number) {
        super(LoginSuccessfulEvent.EVENT_NAME, lab_id);
    }
}

export class SignUpEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'sign_up';

    constructor(lab_id: number, public readonly patient_id: number) {
        super(SignUpEvent.EVENT_NAME, lab_id);
    }
}

export class LoginFailedEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'lh_dat_login_failed';

    constructor(lab_id: number) {
        super(LoginFailedEvent.EVENT_NAME, lab_id);
    }
}

export class BeginCheckoutEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'begin_checkout';

    constructor(lab_id: number,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly currency: CurrencyCode = 'USD') {
        super(BeginCheckoutEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class ViewCartEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'view_cart';

    constructor(lab_id: number,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly currency: CurrencyCode = 'USD',
    ) {
        super(ViewCartEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class SearchEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'search';

    constructor(lab_id: number,
                public readonly search_term: string,
                public readonly patient_id?: number,
    ) {
        super(SearchEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class ViewItemEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'view_item';

    constructor(lab_id: number,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly currency: CurrencyCode = 'USD') {
        super(ViewItemEvent.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class ViewItemList extends AnalyticsEvent {
    public static EVENT_NAME = 'view_item_list';

    constructor(lab_id: number,
                public readonly item_list_id: string,
                public readonly item_list_name: string,
                public readonly items: Array<AnalyticsItem>,
                public readonly patient_id?: number,
                public readonly page = 0,
                public readonly page_size = 0) {
        super(ViewItemList.EVENT_NAME, lab_id);
        this.patient_id = patient_id || GUEST_PATIENT_ID;
    }
}

export class PaymentEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'purchase';

    constructor(lab_id: number,
                public readonly patient_id: number,
                public readonly transaction_id: string,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly currency: CurrencyCode = 'USD'
    ) {
        super(PaymentEvent.EVENT_NAME, lab_id);
    }
}

export class RefundEvent extends AnalyticsEvent {
    public static EVENT_NAME = 'refund';

    constructor(lab_id: number,
                public readonly patient_id: number,
                public readonly transaction_id: string,
                public readonly value: number,
                public readonly items: Array<AnalyticsItem>,
                public readonly currency: CurrencyCode = 'USD'
    ) {
        super(RefundEvent.EVENT_NAME, lab_id);
    }
}

export class AnalyticsService {
    constructor() {
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];
    }

    public static create = (): AnalyticsService => {
        return new AnalyticsService();
    };

    public setVariables = (data: object): void => {
        //@ts-ignore
        window.dataLayer.push(data);
    };

    public sendEvent = (event: AnalyticsEvent): void => {
        //@ts-ignore
        window.dataLayer.push({
            lab_id: undefined,
            patient_id: undefined,
            transaction_id: undefined,
            items: undefined,
            currency: undefined,
            value: undefined,
            page: undefined,
            page_size: undefined,
            item_list_id: undefined,
            item_list_name: undefined,
            search_term: undefined
        });
        //@ts-ignore
        window.dataLayer.push(event);
    };
}