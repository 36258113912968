import {StyleSheet, View} from "react-native";
import {PreviewListItem} from "../common/PreviewListItem";
import {useThemeContext} from "../../contexts/ThemeContext";

export const OrderLineItem = ({label, value}) => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        previewItemLabelStyle: {
            color: theme.colors.primary,
            textAlign: 'right',
            width: '100%',
        },
    });

    return (
        <PreviewListItem label={label} value={value} labelTextStyle={styles.previewItemLabelStyle}/>
    );
};