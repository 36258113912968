import {useState} from "react";
import {Badge, Divider, Menu, Snackbar, Surface, Text, TouchableRipple} from "react-native-paper";
import {Image, LayoutChangeEvent, Linking, TouchableOpacity, View} from "react-native";
import {useNavigation, useNavigationState} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";

import {GradientBar} from "./GradientBar";
import {DatImageUtility} from "../../services/DatImageUtility";
import {Images, MAX_SCREEN_WIDTH} from "../../models";
import {NavigationLink} from "./NavigationLink";
import Environment from "../../models/Environment";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {useAuth} from "../../contexts/AuthContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {DatNavigationUtils} from "./DatNavigator";
import {DeviceType, useResponsive} from "../hooks/useResponsive";

export const AppHeader = () => {
    const {session, authData, countItemsInCart, showSnack, snackMessage, hideSnack} = useAuth();
    const {theme} = useThemeContext();
    const navigation = useNavigation();
    const [menuVisible, setMenuVisible] = useState(false);
    const navigationState = useNavigationState(state => state);
    const {deviceType} = useResponsive();

    const [displayMenu, setDisplayMenu] = useState(false);
    const [displayViewMyResultsNavLink, setDisplayViewMyResultsNavLink] = useState(!session?.appConfig.guestCheckoutEnabled);
    const [displayLabLocationsNavLink, setDisplayLabLocationsNavLink] = useState(!session?.appConfig.serviceAreasEnabled);
    const [displayServiceAreasNavLink, setDisplayServiceAreasNavLink] = useState(!!session?.appConfig.serviceAreasEnabled);
    const [displayHelpNavLink, setDisplayHelpNavLink] = useState(true);
    const [displayHowItWorksNavLink, setDisplayHowItWorksNavLink] = useState(true);
    const [displayBrowseNavLink, setDisplayBrowseNavLink] = useState(true);
    const [displayAuthNavLink, setDisplayAuthNavLink] = useState(!session?.appConfig.guestCheckoutEnabled);
    const [displayCartNavLink, setDisplayCartNavLink] = useState(true);
    const [displayContactNavLink, setDisplayContactNavLink] = useState(true);
    const [displayLabLogo, setDisplayLabLogo] = useState(true);

    const LAB_LOGO_WIDTH = 202;
    const LAB_LOGO_HEIGHT = 92;
    const MIN_MENU_DISPLAY_RESOLUTION = 1252;

    const VIEW_RESULTS_DISPLAY_RESOLUTION = 1135;
    const VIEW_HELP_DISPLAY_RESOLUTION = 1020;
    const VIEW_HOW_IT_WORKS_DISPLAY_RESOLUTION = 920;
    const VIEW_BROWSE_DISPLAY_RESOLUTION = 820;
    const VIEW_AUTH_DISPLAY_RESOLUTION = 700;
    const VIEW_CART_DISPLAY_RESOLUTION = 500;
    const VIEW_CONTACT_DISPLAY_RESOLUTION = 425;
    const MIN_LOGO_DISPLAY_RESOLUTION = 280;

    const onAppHeaderLayoutChanged = (event: LayoutChangeEvent) => {
        const screenWidth = event.nativeEvent.layout.width;
        setDisplayMenu(screenWidth < MIN_MENU_DISPLAY_RESOLUTION);
        setDisplayViewMyResultsNavLink(screenWidth > MIN_MENU_DISPLAY_RESOLUTION && !session?.appConfig.guestCheckoutEnabled);
        setDisplayLabLocationsNavLink(screenWidth > VIEW_RESULTS_DISPLAY_RESOLUTION && !session?.appConfig.serviceAreasEnabled);
        setDisplayServiceAreasNavLink(screenWidth > VIEW_RESULTS_DISPLAY_RESOLUTION && !!session?.appConfig.serviceAreasEnabled);
        setDisplayHelpNavLink(screenWidth > VIEW_HELP_DISPLAY_RESOLUTION);
        setDisplayHowItWorksNavLink(screenWidth > VIEW_HOW_IT_WORKS_DISPLAY_RESOLUTION);
        setDisplayBrowseNavLink(screenWidth > VIEW_BROWSE_DISPLAY_RESOLUTION);
        setDisplayAuthNavLink(screenWidth > VIEW_AUTH_DISPLAY_RESOLUTION && !session?.appConfig.guestCheckoutEnabled);
        setDisplayCartNavLink(screenWidth > VIEW_CART_DISPLAY_RESOLUTION);
        setDisplayContactNavLink(screenWidth > VIEW_CONTACT_DISPLAY_RESOLUTION);
    };

    const isMenuSelected = (screen: string): boolean => {
        return DatNavigationUtils.isSelected(screen, navigationState);
    };

    const onNavigateToScreen = (screen: string) => {
        setMenuVisible(false);
        navigation.navigate(screen);
    };

    return (
        <View style={{flex: 1, flexWrap: 'wrap', width: '100%', maxWidth: MAX_SCREEN_WIDTH, alignSelf: 'center'}}
              onLayout={onAppHeaderLayoutChanged}
        >
            <GradientBar/>
            <Surface style={{flexDirection: 'row', paddingTop: 10, alignItems: 'center'}}>
                <TouchableOpacity onPress={() => onNavigateToScreen(SCREEN_NAMES.BROWSE)}>
                    <Image
                        source={{uri: DatImageUtility.getImageUrl(session?.lab.id, 'logo.png')}}
                        defaultSource={Images.placeholder}
                        style={[{
                            width: LAB_LOGO_WIDTH,
                            height: LAB_LOGO_HEIGHT,
                            marginLeft: 10
                        }, displayLabLogo ? {} : {display: 'none'}]}
                        resizeMode={'contain'}
                    />
                </TouchableOpacity>
                <View style={{
                    flex: 3,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <NavigationLink screen={SCREEN_NAMES.BROWSE}
                                    label={'Browse Tests'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayBrowseNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.HOW_IT_WORKS}
                                    label={'How It Works'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayHowItWorksNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.HELP}
                                    label={'Help / FAQ'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayHelpNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.LAB_LOCATIONS}
                                    label={'Lab Locations'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayLabLocationsNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.SERVICE_AREAS}
                                    label={'Service Areas'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayServiceAreasNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.CONTACT_US}
                                    label={'Contact Us'}
                                    onNavigate={onNavigateToScreen}
                                    visible={displayContactNavLink}
                    />
                    <NavigationLink screen={SCREEN_NAMES.RESULTS}
                                    label={'View Results'}
                                    visible={displayViewMyResultsNavLink}
                                    onNavigate={async () => {
                                        await Linking.openURL(Environment.apiBaseUrl as string);
                                    }}
                    />
                </View>
                <View style={{
                    flex: 1,
                    flexDirection: 'row-reverse',
                    flexWrap: 'wrap'
                }}>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center'}}>
                        <NavigationLink screen={SCREEN_NAMES.ACCOUNT}
                                        label={authData ? 'My Account' : 'Sign In'}
                                        onNavigate={onNavigateToScreen}
                                        visible={displayAuthNavLink}
                                        right={<MaterialIcons name={'account-circle'} size={24}
                                                              color={theme.colors.primary}
                                        />}
                        />
                        <NavigationLink screen={SCREEN_NAMES.CART}
                                        label={'Cart'}
                                        onNavigate={onNavigateToScreen}
                                        visible={displayCartNavLink}
                                        right={
                                            <View>
                                                <MaterialIcons name={'shopping-cart'} size={24}
                                                               color={theme.colors.primary}/>
                                                <Badge
                                                    style={{fontSize: 15, position: 'absolute', top: -10, left: 10}}
                                                    selectionColor={theme.colors.secondary}>
                                                    {countItemsInCart()}
                                                </Badge>
                                            </View>
                                        }
                        />
                        {displayMenu ?
                            <Menu
                                visible={menuVisible}
                                onDismiss={() => setMenuVisible(false)}
                                anchor={
                                    <TouchableOpacity onPress={() => setMenuVisible(true)}>
                                        <View style={{margin: 10}}>
                                            <MaterialIcons name={'menu'} size={24}/>
                                            <Badge
                                                style={[{
                                                    fontSize: 15,
                                                    position: 'absolute',
                                                    top: -5,
                                                    left: 10
                                                }, !displayCartNavLink && !menuVisible ? {} : {display: 'none'}]}
                                                selectionColor={theme.colors.secondary}>
                                                {countItemsInCart()}
                                            </Badge>
                                        </View>
                                    </TouchableOpacity>
                                }
                            >
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.BROWSE) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayBrowseNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.BROWSE)}
                                    title='Browse Tests'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.HOW_IT_WORKS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayHowItWorksNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.HOW_IT_WORKS)}
                                    title='How It Works'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.HELP) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayHelpNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.HELP)}
                                    title='Help / FAQ'
                                />
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.HELP) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayContactNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.CONTACT_US)}
                                    title='Contact Us'
                                />
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.LAB_LOCATIONS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayLabLocationsNavLink && !session?.appConfig.serviceAreasEnabled ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.LAB_LOCATIONS)}
                                    title='Lab Locations'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.SERVICE_AREAS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayServiceAreasNavLink && !!session?.appConfig.serviceAreasEnabled ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.SERVICE_AREAS)}
                                    title='Service Areas'/>
                                <Menu.Item onPress={async () => {
                                    await Linking.openURL(Environment.apiBaseUrl as string);
                                    setMenuVisible(false);
                                }} title='View Results'
                                           style={
                                               [
                                                   !displayViewMyResultsNavLink && !session?.appConfig.guestCheckoutEnabled ? {} : {display: 'none'}
                                               ]
                                           }/>
                                {!displayAuthNavLink && !session?.appConfig.guestCheckoutEnabled ? <Divider/> : <></>}
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.ACCOUNT) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayAuthNavLink && !session?.appConfig.guestCheckoutEnabled ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateToScreen(SCREEN_NAMES.ACCOUNT)}
                                    title={authData ? 'My Account' : 'Sign In'}
                                />
                                <TouchableRipple onPress={() => onNavigateToScreen(SCREEN_NAMES.CART)}>
                                    <View style={[{flexDirection: 'row', alignItems: 'center'},
                                        isMenuSelected(SCREEN_NAMES.CART) ? {
                                            borderLeftWidth: 2,
                                            borderLeftColor: theme.colors.secondary
                                        } : {},
                                        !displayCartNavLink ? {} : {display: 'none'}
                                    ]}>
                                        <Text style={{padding: 20, fontSize: 16, fontWeight: 400}}>Cart</Text>
                                        <MaterialIcons name={'shopping-cart'} size={20}
                                                       color={theme.colors.primary}/>
                                        <Badge
                                            style={{fontSize: 15, position: 'absolute', top: 5, left: 80}}
                                            selectionColor={theme.colors.secondary}>
                                            {countItemsInCart()}
                                        </Badge>
                                    </View>
                                </TouchableRipple>
                            </Menu>
                            : <></>
                        }
                    </View>
                </View>
            </Surface>
            <Snackbar visible={showSnack}
                      onDismiss={hideSnack}
                      action={{
                          label: <MaterialIcons name={'close'} size={16} color={theme.colors.surface}
                                                onPress={hideSnack}/>
                      }}>
                {snackMessage}
            </Snackbar>
        </View>
    );
};