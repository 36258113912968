import {View} from "react-native";

import {Header18} from "../typography";
import {useAuth} from "../../contexts/AuthContext";
import {HtmlView} from "../common/HtmlView";
import {LabIds} from "../../models";
import React from "react";
import {DatComponent} from "@luminate/luminate-ts-sdk";
import {HelperText} from "react-native-paper";

export const ReviewingResults = (props: { componentData?: DatComponent }) => {
    const {session, useLab} = useAuth();

    return (
        <View style={{marginVertical: 5}}>
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <Header18>Reviewing Your Test Results</Header18>}
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <HtmlView>{session?.appConfig.reviewingResults}</HtmlView>}
            {
                !useLab(LabIds.PPL) && !useLab(LabIds.ALVERNO) && props.componentData?.enabled ?
                    props.componentData?.error ?
                        <HelperText type={"error"}>{props.componentData?.content.content}</HelperText>
                        : <>
                            <Header18>{props.componentData?.content.header}</Header18>
                            <HtmlView>{props.componentData?.content.content}</HtmlView>
                        </>
                    :<></>
            }
        </View>
    );
}