import {Children, ReactNode, useEffect} from "react";
import {Surface, Text} from "react-native-paper";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {useNavigation, useNavigationState} from "@react-navigation/native";
import {createNativeStackNavigator, NativeStackNavigationOptions} from "@react-navigation/native-stack";
import {
    NativeStackNavigatorProps
} from "@react-navigation/native-stack/lib/typescript/src/types";
import {useThemeContext} from "../../contexts/ThemeContext";

const Stack = createNativeStackNavigator();

export class DatNavigationUtils  {
    public static isSelected = (screen: string, navState: any): boolean => {
        let isRouteSelected = false

        if (navState) {
            const theRoute = navState.routes[navState.index];
            if (theRoute && theRoute.name === screen) {
                isRouteSelected = true;
            } else {
                for (const route of navState.routes) {
                    isRouteSelected = DatNavigationUtils.isSelected(screen, route.state);
                    if (isRouteSelected) {
                        break;
                    }
                }
            }
        }

        return isRouteSelected;
    };
}

export type DatNavigatorProps = {
    dark?: boolean;
    appBar?: ReactNode;
};

export const DatNavigator = (props: DatNavigatorProps & NativeStackNavigatorProps) => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const theChildren = Children.toArray(props.children);

    const navigationState = useNavigationState(state => state);

    useEffect(() => {
        if (props.initialRouteName) {
            onNavigateToScreen(props.initialRouteName);
        }
    }, []);

    const ComponentNotSupportedError = (componentType: string) => {
        throw new Error(`DatNavigator does not support child components of type: ${componentType}`);
    };

    const tabScreens = theChildren.filter(child => {
        if (child.type !== DatTabScreen && child.type !== DatStackScreen) {
            ComponentNotSupportedError(child.type);
        }
        return child.type === DatTabScreen;
    });

    const stackScreens = theChildren.filter(child => {
        if (child.type !== DatTabScreen && child.type !== DatStackScreen) {
            ComponentNotSupportedError(child.type);
        }
        return child.type === DatStackScreen;
    });

    const onNavigateToScreen = (screen: string) => {
        navigation.navigate(screen);
    };

    const tabNavVisible = (): boolean => {
        return stackScreens.length === 0 || stackScreens.find(screen => {
            return !(navigationState && navigationState.routes && screen.props.name === navigationState.routes[navigationState.index].name);
        }) !== undefined;
    };

    const lightTheme = StyleSheet.create({
        appBar: {
            backgroundColor: theme.colors.surface,
        },
        tabBar: {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        selectedTabItem: {
            borderStyle: 'solid',
            borderBottomWidth: 3,
            borderColor: theme.colors.secondary
        },
        selectedTabItemText: {
            fontWeight: 'bold',
            fontSize: 15,
            margin: 10,
            color: theme.colors.primary
        },
        tabText: {
            color: theme.colors.primary,
        },
        tabItem: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tabItemText: {
            fontWeight: 'bold',
            fontSize: 15,
            margin: 10,
        }
    });

    const darkTheme = StyleSheet.create({
        appBar: {
            backgroundColor: theme.colors.primary,
        },
        tabBar: {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        selectedTabItem: {
            borderStyle: 'solid',
            borderBottomWidth: 3,
            borderColor: theme.colors.secondary
        },
        selectedTabItemText: {
            fontWeight: 'bold',
            fontSize: 15,
            margin: 10,
            color: theme.colors.surface
        },
        tabText: {
            color: theme.colors.surface,
        },
        tabItem: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tabItemText: {
            fontWeight: 'bold',
            fontSize: 15,
            margin: 10,
            color: theme.colors.surface
        }
    });

    const selectedTheme = props.dark ? darkTheme : lightTheme;

    return (
        <>
            {tabNavVisible()
                ? <Surface style={[selectedTheme.appBar]}>
                    <View style={[selectedTheme.tabBar]}>
                        {
                            tabScreens.map(screen => {
                                const isScreenSelected = DatNavigationUtils.isSelected(screen.props.name, navigationState);
                                return (
                                    <TouchableOpacity
                                        key={`navigation-tab-touch-opacity-${screen.props.name}`}
                                        style={[selectedTheme.tabItem, isScreenSelected ? selectedTheme.selectedTabItem : {}]}
                                        onPress={() => onNavigateToScreen(screen.props.name)}>
                                        <Text
                                            key={`navigation-tab-text-${screen.props.name}`}
                                            style={[isScreenSelected ? selectedTheme.selectedTabItemText : selectedTheme.tabItemText]}>
                                            {screen.props.options?.title ? screen.props.options?.title : screen.props.name}

                                        </Text>
                                        {screen.props.right
                                            ? <>
                                                {screen.props.right}
                                            </>
                                            : <></>
                                        }
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                    {props.appBar}
                </Surface>
                : <></>
            }
            <Stack.Navigator screenOptions={{headerShown: false}}>
                {
                    tabScreens.map(screen => {
                        return (
                            <Stack.Screen
                                options={{title: screen.props.options.title ? screen.props.options.title : screen.props.name}}
                                key={`navigation-tab-screen-${screen.props.name}`}
                                name={screen.props.name}
                                initialParams={screen.props.initialParams}
                                component={screen.props.component}
                            />
                        )
                    })
                }
                {
                    stackScreens.map(screen => {
                        return (
                            <Stack.Screen name={screen.props.name}
                                          key={`dat-stack-screen-${screen.props.name}`}
                                          component={screen.props.component}
                                          options={screen.props.options}
                            />
                        )
                    })
                }
            </Stack.Navigator>
        </>
    );
};

export type ParamListBase = Record<string, object | undefined>

export type TabScreenProps = {
    options?: {
        title?: string;
    },
    name: string;
    component: ReactNode;
    initialParams?: unknown | undefined;
    right?: ReactNode;
};

export type StackScreenProps = {
    options?: NativeStackNavigationOptions;
    name: string;
    component: ReactNode;
};

export const DatTabScreen = (_props: TabScreenProps) => null;
export const DatStackScreen = (_props: StackScreenProps) => null;