import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {FAB} from "react-native-paper";
import {IconSource} from "react-native-paper/lib/typescript/src/components/Icon";

import {LuminateStyles} from "../../styles/LuminateStyles";
import {Header24, Text} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";

export type HowItWorksStepProps = {
    wrapperStyle?: StyleProp<ViewStyle>,
    icon: IconSource;
    iconStyle?: StyleProp<ViewStyle>;
    stepCount?: number;
    title?: string,
    description: string;
};

export const HowItWorksStep = (props: HowItWorksStepProps) => {
    const {theme} = useThemeContext();
    const styles = StyleSheet.create({
        icon: {
            backgroundColor: theme.colors.primary,
        }
    });
    return (
        <View style={[{padding: 10, maxWidth: 238}, props.wrapperStyle]}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <FAB icon={props.icon} style={[styles.icon, props.iconStyle]}/>
                <Header24 style={{marginVertical: 10}}>{props.title ? props.title : <>Step {props.stepCount}</>}</Header24>
            </View>
            <View style={{flex: 1, paddingTop: 20, alignItems: 'center'}}>
                <Text style={LuminateStyles.centerText}>{props.description}</Text>
            </View>
        </View>
    );
};