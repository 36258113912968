import React, {useState} from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {Paragraph, RadioButton, Surface} from "react-native-paper";
import {useNavigation, useRoute} from "@react-navigation/native";

import {
    AddressUtilities,
    DateUtilities,
    isRequired,
    NumberToCurrencyConverter,
    OrderCancellationReason
} from "@luminate/luminate-ts-sdk";

import {CancelListScreenNavigationProps, CancelOrderRouteProps} from "../routes/OrderHistoryStack";
import {ValidatedInput} from "../common/ValidatedInput";
import {Header18, Header40, SubText} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {PageFooter} from "../common/PageFooter";

export const CancelOrderScreen = () => {
    const navigation = useNavigation<CancelListScreenNavigationProps>();
    const {theme} = useThemeContext();
    const route = useRoute<CancelOrderRouteProps>();
    const {session, cancelOrder, displaySnack} = useAuth();
    const [cancelReason, setCancelReason] = useState<OrderCancellationReason>();
    const [otherReason, setOtherReason] = useState<string>();
    const [otherReasonErrors, setOtherReasonErrors] = useState<Array<string>>([]);
    const [otherReasonValid, setOtherReasonValid] = useState(true);
    const [processing, setProcessing] = useState(false);
    const currencyConverter = NumberToCurrencyConverter.create();

    const isFormValid = cancelReason && cancelReason !== OrderCancellationReason.OTHER || cancelReason === OrderCancellationReason.OTHER && otherReasonValid;

    const onOtherReasonChanged = (value: string) => {
        setOtherReason(value);
    };

    const onOtherReasonValidated = (errors: Array<string>) => {
        setOtherReasonErrors(errors);
        setOtherReasonValid(errors.length === 0);
    };

    const onReasonChanged = (value: string) => {
        setCancelReason(OrderCancellationReason[value]);
        if (value === OrderCancellationReason.OTHER) {
            setOtherReason('');
            setOtherReasonValid(false);
        }
    };

    const styles = StyleSheet.create({
        labelStyle: {
            fontSize: 18,
            color: theme.colors.primary,
            marginVertical: 5,
        },
        plainTextStyle: {
            fontSize: 18,
            marginVertical: 5,
        },
        fontStyle: {
            fontSize: 18,
        },
    });

    const onCancelOrder = async () => {
        setProcessing(true);
        try {
            await cancelOrder(route.params.order, cancelReason as OrderCancellationReason, otherReason);
            navigation.goBack();
        } catch (ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to preform a refund at this
                time. Please try again later.</SubText>);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <ScrollView>
            <Surface style={{padding: 15}}>
                <View style={{alignItems: 'center'}}>
                    <Header40 style={{lineHeight: 40}}>Cancel Order</Header40>
                    <Paragraph style={[styles.plainTextStyle, {marginTop: 20}]}>
                        By cancelling this order, we will issue a refund
                        of {currencyConverter.toModel(route.params.order.totals >= (session?.appConfig?.nonRefundableFee || 0) ? route.params.order.totals - (session?.appConfig?.nonRefundableFee || 0) : 0)}.
                    </Paragraph>
                    <Paragraph style={[styles.plainTextStyle, {marginTop: 20}]}>
                        {session?.appConfig?.cancellationPolicy || 'Cancellation Policy Not Configured'}
                    </Paragraph>
                    <SubText style={[styles.plainTextStyle, {marginVertical: 20}]}>
                        Confirm the following order information is correct:
                    </SubText>
                    <View>
                        <ScrollView horizontal={true}>
                            <View style={{flexDirection: 'row', padding: 5}}>
                                <View style={{paddingRight: 10, alignItems: 'flex-end'}}>
                                    <SubText style={styles.labelStyle}>Purchaser:</SubText>
                                    <SubText style={styles.labelStyle}>Order #:</SubText>
                                    <SubText style={styles.labelStyle}>Date of Purchase:</SubText>
                                    <SubText style={styles.labelStyle}>Total:</SubText>
                                    <SubText style={styles.labelStyle}>Lab Contact:</SubText>
                                </View>
                                <View>
                                    <SubText style={styles.plainTextStyle}>
                                        {`${route.params.order.patientFirstName} ${route.params.order.patientLastName}`}
                                    </SubText>
                                    <SubText
                                        style={styles.plainTextStyle}>{route.params.order.orderNumber}</SubText>
                                    <SubText
                                        style={styles.plainTextStyle}>{route.params.order.createdDateTime ? DateUtilities.formatYYYYMMDDHHMM(route.params.order.createdDateTime) : undefined}</SubText>
                                    <SubText
                                        style={styles.plainTextStyle}>{currencyConverter.toModel(route.params.order.totals)}</SubText>
                                    <View>
                                        <SubText
                                            style={styles.plainTextStyle}>{route.params.order?.lab?.name}</SubText>
                                        <SubText
                                            style={styles.fontStyle}>{route.params.order?.lab?.address1}</SubText>
                                        {route.params.order?.lab?.address2
                                            ? <SubText
                                                style={styles.fontStyle}>{route.params.order?.lab?.address2}</SubText>
                                            : <></>
                                        }
                                        <SubText
                                            style={styles.fontStyle}>{AddressUtilities.compileCityStateZip(route.params.order?.lab?.city, route.params.order?.lab?.state, route.params.order?.lab?.zip)}</SubText>
                                        {route.params.order?.lab?.phone
                                            ? <SubText
                                                style={styles.fontStyle}>{route.params.order?.lab?.phone}</SubText>
                                            : <></>
                                        }
                                    </View>
                                </View>
                            </View>
                        </ScrollView>
                    </View>

                    <View style={{marginTop: 20}}>
                        <Header18 style={{textAlign: 'center'}}>
                            Reason For Cancellation
                        </Header18>
                        <RadioButton.Group value={cancelReason} onValueChange={onReasonChanged}>
                            <RadioButton.Item label={'I no longer need this test'}
                                              value={OrderCancellationReason.NO_LONGER_NEEDED}/>
                            <RadioButton.Item label={'I ordered the wrong test'}
                                              value={OrderCancellationReason.WRONG_TEST}/>
                            <RadioButton.Item label={'I was unable to fast for the test'}
                                              value={OrderCancellationReason.UNABLE_TO_FAST}/>
                            <RadioButton.Item
                                label={'I am unable to get my testing done within 60 days of ordering'}
                                value={OrderCancellationReason.UNABLE_TO_PERFORM}/>
                            <RadioButton.Item label={'Other'} value={OrderCancellationReason.OTHER}/>
                        </RadioButton.Group>
                        {cancelReason === OrderCancellationReason.OTHER
                            ? <ValidatedInput
                                label={'Please provide additional information'}
                                value={otherReason}
                                multiline={true}
                                style={{height: 100, backgroundColor: theme.colors.surface}}
                                onChangeText={onOtherReasonChanged}
                                rules={[isRequired]}
                                errors={otherReasonErrors}
                                onValidate={onOtherReasonValidated}
                            />
                            : <></>
                        }
                    </View>

                    <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 20}}>
                        <Button disabled={!isFormValid || processing} loading={processing} onPress={onCancelOrder}>
                            Cancel Order
                        </Button>
                    </View>
                    <View style={{marginVertical: 20}}>
                        <Header18>Confirm that your order is cancelled.</Header18>
                        <SubText>
                            After submitting the cancellation, you’ll receive a confirmation message on the screen.
                            You’ll
                            also receive an email confirming your cancellation.
                        </SubText>
                    </View>
                </View>
            </Surface>
            <PageFooter/>
        </ScrollView>
    );
};