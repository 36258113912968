import {Card, FAB} from "react-native-paper";
import {StyleSheet, View} from "react-native";

import {AddressUtilities, Coordinate} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {Header18, SubText} from "../typography";

export type LabLocationCardProps = {
    id: number;
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    fax?: string;
    coordinate: Coordinate
    businessHours: Array<string>;
    selected: boolean;
    onGetDirections: (locationId: number) => void;
};

export const LabLocationCard = (props: LabLocationCardProps) => {
    const {theme} = useThemeContext();
    const styles = StyleSheet.create({
        card: {
            margin: 5
        },
        selected: {
            borderRightColor: theme.colors.secondary,
            borderRightWidth: 3,
            borderStyle: 'solid',
        },
        subHeader: {
            fontWeight: 'bold',
            marginTop: 5
        }
    });
    return (
        <Card style={[styles.card, props.selected ? styles.selected : {}]}>
            <Card.Title title={<Header18>{props.name}</Header18>}/>
            <Card.Content>
                <SubText style={{lineHeight: 20}} key={`address-1-${props.id}`}>{props.address1}</SubText>
                {props.address2 &&
                    <SubText key={`address-2-${props.id}`}>{props.address2}</SubText>
                }
                <SubText style={{lineHeight: 20}}
                    key={`address-csz-${props.id}`}>
                    {AddressUtilities.compileCityStateZip(props.city, props.state, props.zip)}
                </SubText>

                {props.phone &&
                    <View style={{marginTop: 5}}>
                        <SubText style={styles.subHeader}>Phone: <SubText style={{lineHeight: 20}}
                            key={`phone-${props.id}`}>{props.phone}</SubText></SubText>
                    </View>
                }

                {props.fax
                    && <View style={{marginTop: 5}}>
                        <SubText style={styles.subHeader}>Fax: <SubText style={{lineHeight: 20}}
                            key={`fax-${props.id}`}>{props.fax}</SubText>
                        </SubText>
                    </View>
                }
                {props.businessHours && props.businessHours.length > 0 &&
                    <SubText style={styles.subHeader}>Business Hours</SubText>
                }
                {props.businessHours.map((hoursListing) => {
                    return <SubText style={{lineHeight: 20}} key={`${props.id}-hours-${hoursListing}`}>{hoursListing}</SubText>;
                })}
            </Card.Content>
            <Card.Actions>
                <FAB icon={'directions'} size={'small'} onPress={() => props.onGetDirections(props.id)}/>
            </Card.Actions>
        </Card>
    );
};