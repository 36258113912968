import {Card, Text} from "react-native-paper";
import {View} from "react-native";

import {NumberToCurrencyConverter, OrderStatus} from "@luminate/luminate-ts-sdk";
import {useThemeContext} from "../../contexts/ThemeContext";

export type OrderStatusDetailCardProps = {
    refundTotal?: number;
    status?: OrderStatus;
};

export const OrderStatusDetailCard = (props: OrderStatusDetailCardProps) => {
    const {theme} = useThemeContext();

    const currencyConverter = NumberToCurrencyConverter.create();
    const RefundText = ({total}) => (
        <Text style={{fontSize: 18}}>
            This order has been cancelled and a refund was issued
            for {currencyConverter.toModel(total)}.
        </Text>
    );
    const ExpiredText = () => (
        <Text style={{fontSize: 18}}>
            This order has been expired.
        </Text>
    );
    return (
        <>
            {(props.status && (props.status === OrderStatus.REFUNDED || props.status === OrderStatus.EXPIRED)) &&
                <Card style={{backgroundColor: theme.colors.secondaryAccent, margin: 10}}>
                    <Card.Content>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            {props.status === OrderStatus.REFUNDED
                                ? <RefundText total={props.refundTotal || 0}/>
                                : <ExpiredText/>
                            }
                        </View>
                    </Card.Content>
                </Card>}
        </>
    );
};
