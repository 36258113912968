import React from "react";
import {StyleSheet} from "react-native";
import {Button, Card, MD2Colors} from "react-native-paper";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {Currency} from "../common/Currency";
import {GradientBar} from "../common/GradientBar";
import {Header18, SubText} from "../typography";
import {Images} from "../../models";
import {DatImageUtility} from "../../services/DatImageUtility";
import {HtmlView} from "../common/HtmlView";

export type CartTestSummaryProps = {
    id: number;
    name: string;
    description: string;
    price: number;
    code: string;
    invalid?: boolean;
    onAfterRemovedFromCart?: () => void;
};

export const CartTestSummary = (props: CartTestSummaryProps) => {
    const {theme} = useThemeContext();
    const {session, removeTestFromCart, displaySnack} = useAuth();

    const onRemovTestToCart = async () => {
        try {
            await removeTestFromCart(props.id);
            if (props.onAfterRemovedFromCart) {
                props.onAfterRemovedFromCart();
            }
        } catch (ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to remove the test from your cart at this time.  Please try again later.</SubText>);
        }
    };

    return (
        <Card style={[props.invalid ? {borderColor: theme.colors.notification, borderWidth: 1} : {margin: 5}]}>
            <Card.Cover source={{uri: DatImageUtility.getJpgImageUrl(session?.lab.id || 0, props.code)}}
                        defaultSource={Images.placeholder}
                        resizeMode={"cover"}
                        style={{height: 'auto', aspectRatio: 16/9}}/>
            <GradientBar />
            <Card.Content>
                <Header18>{props.name}</Header18>
                <HtmlView style={{marginTop: 20, marginBottom: 2}}>
                    {props.description}
                </HtmlView>
            </Card.Content>
            <Card.Actions style={{flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {props.invalid
                    ? <Button labelStyle={{textTransform: 'none', color: theme.colors.inverseOnSurface}}
                              style={{alignItems: 'flex-start'}}
                              buttonColor={theme.colors.notification}
                              compact={true}
                              onPress={onRemovTestToCart}
                              icon={() => <MaterialIcons style={{color: theme.colors.notification}}
                                                         name={'delete'}
                                                         size={16}/>}>
                        Remove To Continue
                    </Button>
                    : <Button labelStyle={{textTransform: 'none'}}
                              compact={true}
                              style={{alignItems: 'flex-start'}}
                              onPress={onRemovTestToCart}
                              icon={() => <MaterialIcons style={{color: theme.colors.primary}}
                                                         name={'delete'}
                                                         size={16}/>}>
                        Remove
                    </Button>
                }
                <Currency style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    color: !props.invalid ? theme.colors.primary : theme.colors.notification
                }}
                          value={props.price}/>
            </Card.Actions>
        </Card>
    );
};
