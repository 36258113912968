import React from "react";
import {ScrollView} from "react-native";
import {useNavigation} from "@react-navigation/native";

import {useRegistrationContext} from "../../contexts/RegistrationContext";
import {TermsAndConditions} from "../terms/TermsAndConditions";
import {Button} from "../common/Button";

export const AgreeToTermsOfServiceScreen = () => {
    const navigation = useNavigation();

    const {
        setAgreeToTerms
    } = useRegistrationContext();

    const onAccept = () => {
        setAgreeToTerms(true);
        navigation.navigate('Connect');
    };

    return (
        <ScrollView>
            <TermsAndConditions />
            <Button onPress={onAccept}>Accept</Button>
        </ScrollView>
    );
};