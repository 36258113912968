import {Header18} from "../typography";
import {View} from "react-native";
import React from "react";
import {useAuth} from "../../contexts/AuthContext";
import {LabIds} from "../../models";
import {DefaultSchedulingTests} from "./DefaultSchedulingTests";
import {PplSchedulingTests} from "./PplSchedulingTests";
import {HtmlView} from "../common/HtmlView";
import {DatComponent} from "@luminate/luminate-ts-sdk";
import {HelperText} from "react-native-paper";
import {DefaultSchedulingTestsWithLabLocations} from "./DefaultSchedulingTestsWithLabLocations";

export const SchedulingTests = (props: { componentData?: DatComponent, withLabLocations?: boolean }) => {
    const {useLab} = useAuth();

    return (
        <View style={{marginVertical: 5}}>
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <Header18>Scheduling Tests</Header18>}
            {useLab(LabIds.PPL) && <PplSchedulingTests/>}
            {useLab(LabIds.ALVERNO) && (props.withLabLocations ? <DefaultSchedulingTestsWithLabLocations/> : <DefaultSchedulingTests/>)}
            {
                !(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && props.componentData?.enabled ?
                    props.componentData?.error ?
                        <HelperText type={"error"}>{props.componentData?.content.content}</HelperText>
                        : <>
                            <HtmlView>{props.componentData?.content.header}</HtmlView>
                            <HtmlView>{props.componentData?.content.content}</HtmlView>
                        </>
                    :<></>
            }
        </View>

    );
}