import {ScrollView, View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

import {OrderableTest, IndividualTest} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText, Text} from "../typography";
import {Button} from "../common/Button";

export interface UndoSelectionSuccessfulProps {
    testName: string;
    duplicateTests: Array<OrderableTest | IndividualTest>;
    onClose: () => void | undefined;
    onShowCart: () => void | undefined;
}

export const UndoSelectionSuccessful = (props: UndoSelectionSuccessfulProps) => {
    const {theme} = useThemeContext();

    const renderTestComponentList = (tests: Array<OrderableTest | IndividualTest>) => {
        return (
            <ScrollView>
                <ul>
                    {tests.map(test =>
                        <li key={`test-component-${test.testId}`}>
                            <SubText>{test.name}</SubText>
                        </li>
                    )}
                </ul>
            </ScrollView>
        );
    };

    return (
        <View style={{alignItems: 'center'}}>
            <View style={{marginHorizontal: 20}}>
                <View style={{flexDirection: 'row', marginVertical: 20}}>
                    <MaterialIcons name={'check'} size={24} color={theme.colors.primary}/>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: theme.colors.primary,
                        marginLeft: 10
                    }}>
                        Added To Cart!</Text>
                </View>
                <SubText>
                    We removed <SubText style={{fontWeight: 'bold'}}>
                    {props.testName}</SubText> from your cart and re-added the following test(s):
                </SubText>
                {renderTestComponentList(props.duplicateTests)}
            </View>
            <Button style={{paddingVertical: 5, width: 186}} onPress={props.onClose}>Continue Browsing</Button>
            <Button style={{paddingVertical: 5, width: 186}} onPress={props.onShowCart}>Checkout Now</Button>
        </View>
    );
};