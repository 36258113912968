import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";

import {SampleType} from "@luminate/luminate-ts-sdk";

import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText} from "../typography";

export const BLOOD_SAMPLE_DISPLAY_NAME = 'Blood Sample';
export const SALIVA_SAMPLE_DISPLAY_NAME = 'Saliva Sample';
export const URINE_SAMPLE_DISPLAY_NAME = 'Urine Sample';
export const BLOOD_AND_URINE_SAMPLE_DISPLAY_NAME = "Blood and Urine Samples"
export const DEFAULT_SAMPLE_DISPLAY_NAME = '';
export const BLOOD_SAMPLE_ICON = 'water';

export type SampleTypeIndicatorProps = {
    type: SampleType;
    size?: number;
    style?: StyleProp<ViewStyle>
};

export const SampleTypeIndicator = (props: SampleTypeIndicatorProps) => {
    const getDisplayName = (): string => {
        switch (props.type) {
            case SampleType.BLOOD:
                return BLOOD_SAMPLE_DISPLAY_NAME;
            case SampleType.SALIVA:
                return SALIVA_SAMPLE_DISPLAY_NAME;
            case SampleType.URINE:
                return URINE_SAMPLE_DISPLAY_NAME;
            case SampleType.BLOOD_AND_URINE:
                return BLOOD_AND_URINE_SAMPLE_DISPLAY_NAME;
            default:
                return DEFAULT_SAMPLE_DISPLAY_NAME;
        }
    };
    const getIcon = (): string | undefined => {
        switch (props.type) {
            case SampleType.BLOOD:
            case SampleType.BLOOD_AND_URINE:
                return BLOOD_SAMPLE_ICON;
            default:
                return undefined;
        }
    };
    const {theme} = useThemeContext();
    const displayName = getDisplayName();
    const icon = getIcon();
    const DEFAULT_SIZE = 32;
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sampleTypeLabel: {
            fontSize: 15,
            fontWeight: 'bold',
            color: theme.colors.primary
        },
    });
    return (
        <View style={[styles.container, props.style]}>
            {icon ? <Ionicons name={icon} size={props.size || DEFAULT_SIZE} color={theme.colors.primary}/> : <></>}
            <SubText
                style={{color: theme.colors.primary, fontWeight: 'bold', textAlign: 'center'}}>{displayName}</SubText>
        </View>
    );
};