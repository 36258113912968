import React, {useEffect} from "react";
import {v4 as uuid } from 'uuid';
import {
    NativeSyntheticEvent,
    StyleProp,
    TextInputEndEditingEventData,
    TextInputKeyPressEventData,
    TextInputSubmitEditingEventData,
    TextStyle,
    View
} from "react-native";
import {TextInput, HelperText} from "react-native-paper";
import {TextInputLabelProp} from "react-native-paper/lib/typescript/components/TextInput/types";

import {ValidatorFn} from "@luminate/luminate-ts-sdk";

export type ValidatedInputProps = {
    label?: TextInputLabelProp;
    mode?: "flat" | "outlined" | undefined;
    placeholder?: string;
    multiline?: boolean;
    rules?: Array<ValidatorFn<string>>;
    errors: Array<string>;
    autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
    dense?: boolean;
    disabled?: boolean;
    onChangeText?: (text: string) => void;
    onEndEditing?: (event: NativeSyntheticEvent<TextInputEndEditingEventData>) => void;
    onSubmitEditing?: (event: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
    onKeyPress?: (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
    onValidate?: (errors: Array<string>) => void;
    reValidate?: boolean;
    onFocus?: (args: any) => void;
    onBlur?: (args: any) => void;
    value?: any | undefined;
    autoCorrect?: boolean;
    secureTextEntry?: boolean;
    style?: StyleProp<TextStyle>;
    errorsStyle?: StyleProp<TextStyle>;
    theme?: ReactNativePaper.Theme;
    left?: React.ReactNode;
    right?: React.ReactNode;
};

export const ValidatedInput = (props: ValidatedInputProps) => {
    const {errors = new Array<string>()} = props;

    useEffect(() => {
        if (props.value) {
            validate(props.value);
        }
    }, [props.value, props.reValidate]);

    const validate = (value: string) => {
        const fieldErrors = new Array<string>();

        props.rules?.forEach(validator => {
            const error = validator(value);
            if (error) {
                fieldErrors.push(error);
            }
        });

        if (props.onValidate) {
            props.onValidate(fieldErrors);
        }
    };

    const onBlur = (event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
        validate(event.nativeEvent.text);
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const onEndEditing = (event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
        if (props.onEndEditing) {
            props.onEndEditing(event);
        }
    };

    return (
        <View>
            <TextInput
                label={props.label}
                mode={props.mode}
                placeholder={props.placeholder}
                multiline={props.multiline}
                error={errors?.length > 0}
                dense={props.dense}
                disabled={props.disabled}
                autoCapitalize={props.autoCapitalize}
                onChangeText={props.onChangeText}
                onSubmitEditing={props.onSubmitEditing}
                onKeyPress={props.onKeyPress}
                onFocus={props.onFocus}
                onBlur={onBlur}
                value={props.value}
                style={props.style}
                autoCorrect={props.autoCorrect}
                secureTextEntry={props.secureTextEntry}
                onEndEditing={onEndEditing}
                right={props.right}
            />
            {errors.map(error =>
                <HelperText key={uuid()} type={'error'} style={props.errorsStyle}>{error}</HelperText>
            )}
        </View>
    );
};