import {ReactNode} from "react";
import {LayoutChangeEvent, StyleProp, StyleSheet, TextStyle} from "react-native";
import {Text} from "react-native-paper";

import {useThemeContext} from "../../contexts/ThemeContext";

export type Header30Props = {
    children?: ReactNode;
    style?: StyleProp<TextStyle>;
    numberOfLines?: number | undefined;
    onLayout?: ((event: LayoutChangeEvent) => void) | undefined;
    ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip' | undefined;
};

export const Header30 = (props: Header30Props) => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        text: {
            fontSize: 30,
            fontWeight: 'bold',
            lineHeight: 30,
            marginVertical: 2,
            letterSpacing: 0.15,
            color: theme.colors.primary,
        }
    });

    return (
        <Text style={[styles.text, props.style]}
              numberOfLines={props.numberOfLines}
              ellipsizeMode={props.ellipsizeMode}
              onLayout={props.onLayout}
        >{props.children}</Text>
    );
};