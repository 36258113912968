import React, {useRef, useState} from 'react';
import {
    TextInput,
    ActivityIndicator,
    HelperText, Card
} from 'react-native-paper';
import {
    NativeSyntheticEvent,
    ScrollView,
    TextInputKeyPressEventData,
    View
} from 'react-native';
import {useNavigation} from "@react-navigation/native";
import ReCAPTCHA from "react-google-recaptcha";

import {useAuth} from '../../contexts/AuthContext';
import {Keys} from "../../models";
import {PasswordInput} from "../common/PasswordInput";
import {useThemeContext} from "../../contexts/ThemeContext";
import {Button} from "../common/Button";
import {Header24, Header18, SubText} from "../typography";
import {PageFooter} from "../common/PageFooter";
import {
    AnalyticsService,
    LoginFailedEvent,
} from "../../services/AnalyticsService";
import {CaptchaException} from "@luminate/luminate-ts-sdk/dist/models/exceptions/CaptchaException";
import Environment from "../../models/Environment";

export const SignInScreen = () => {
    const navigation = useNavigation();
    const captchaRef = useRef();
    const [loading, isLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState(false);
    const [captchaRequired, setCaptchaRequired] = useState<boolean>(false);
    const [captcha, setCaptcha] = useState<string>();

    const {signIn, session} = useAuth();
    const {theme} = useThemeContext();

    const onSignIn = async () => {
        isLoading(true);
        try {
            await signIn(userName, password, captcha);
        } catch (ex) {
            if (ex instanceof CaptchaException) {
                setCaptchaRequired(true);
            }
            if (captchaRequired) {
                // @ts-ignore
                captchaRef?.current?.reset();
                setCaptcha(undefined);
            }
            setAuthError(true);
            AnalyticsService.create().sendEvent(new LoginFailedEvent(session?.lab.id as number));
        } finally {
            isLoading(false);
        }
    };

    const onForgotUsername = async () => {
        navigation.navigate('ForgotUsername');
    };

    const onForgotPassword = async () => {
        navigation.navigate('ForgotPassword');
    };

    const onCreateAccount = async () => {
        navigation.navigate('Register');
    };

    const onUserNameChanged = (value: any): void => {
        setUserName(value);
    };
    const onPasswordChanged = (value: any): void => {
        setPassword(value);
    };

    const onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
        if (event.nativeEvent?.key === Keys.Enter) {
            onSignIn();
        }
    };

    const onCaptchaChanged = (value: string | null): void => {
        setCaptcha(value !== null ? value : undefined);
    };

    return (
        <ScrollView style={{flex: 1, backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap-reverse', justifyContent: 'center'}}>
                <View style={{flex: 1, minWidth: 270, maxWidth: 500, margin: 10}}>
                    <Card style={{minHeight: 382}}>
                        <Card.Content>
                            <View style={{flex: 1, justifyContent: 'space-between'}}>
                                <View style={{margin: 10, alignItems: 'center'}}>
                                    <Header24 style={{marginVertical: 20}}>I do not have an account</Header24>
                                    <Button onPress={onCreateAccount}>Create Account</Button>
                                </View>
                                <View style={{margin: 10}}>
                                    <Header18>{session?.appConfig.signInAlreadyHaveAnAccountHeader}</Header18>
                                    <SubText>
                                        Accounts created here or through the Patient Portal use the same credentials. If
                                        you have created an account in either place, sign in using your User Name and
                                        Password.
                                    </SubText>
                                </View>
                            </View>
                        </Card.Content>
                    </Card>
                </View>
                <View style={{flex: 1, minWidth: 270, maxWidth: 500, margin: 10}}>
                    <Card>
                        <Card.Content>
                            <View style={{margin: 10, alignItems: 'center'}}>
                                <Header24 style={{marginVertical: 20}}>{session?.appConfig.signInLoginHeader}</Header24>
                            </View>
                            <View>
                                <View>
                                    <TextInput mode={'outlined'}
                                               label={'User Name'}
                                               style={{
                                                   marginVertical: 5,
                                                   marginHorizontal: 20,
                                                   backgroundColor: theme.colors.surface
                                               }}
                                               outlineColor={theme.colors.primary}
                                               value={userName}
                                               autoCapitalize="none"
                                               onKeyPress={onKeyPress}
                                               onChangeText={onUserNameChanged}
                                               error={authError}/>
                                    <PasswordInput label='Password'
                                                   mode={'outlined'}
                                                   style={{marginHorizontal: 20, backgroundColor: theme.colors.surface}}
                                                   value={password}
                                                   onChangeText={onPasswordChanged}
                                                   onKeyPress={onKeyPress}/>
                                    <HelperText visible={authError}
                                                type={'error'}
                                                style={{color: theme.colors.error}}>
                                        Invalid username or password.
                                    </HelperText>
                                </View>
                                {captchaRequired &&
                                    <View style={{alignItems: 'center'}}>
                                        <ReCAPTCHA
                                            //@ts-ignore
                                            ref={captchaRef}
                                            style={{alignItems: 'center'}}
                                            sitekey={Environment.captchaSiteKey || 'SITE_KEY_NOT_CONFIGURED'}
                                            onChange={onCaptchaChanged}
                                        />
                                    </View>
                                }
                            </View>
                        </Card.Content>
                        <Card.Actions>
                            <View style={{flex: 1, alignItems: 'center'}}>
                                <Button style={{marginVertical: 5, width: 100}} disabled={captchaRequired && !captcha} onPress={onSignIn}>Sign In</Button>
                                {loading ? <ActivityIndicator animating={true} size={'large'}/> : <></>}
                                <SubText onPress={onForgotUsername}
                                         style={{marginVertical: 10, textDecorationLine: 'underline'}}>Forgot
                                    Username?</SubText>
                                <SubText onPress={onForgotPassword} style={{textDecorationLine: 'underline'}}>Forgot
                                    Password?</SubText>
                            </View>
                        </Card.Actions>
                    </Card>
                </View>
            </View>
            <PageFooter/>
        </ScrollView>
    );
};
