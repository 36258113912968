import {StyleSheet, View} from "react-native";
import {ActivityIndicator, Card, Paragraph} from "react-native-paper";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {useThemeContext} from "../../contexts/ThemeContext";
import {DefaultFastingContent} from "./DefaultFastingContent";
import {useAuth} from "../../contexts/AuthContext";
import {LabIds} from "../../models";
import {PplFastingContent} from "./PplFastingContent";
import {useEffect, useState} from "react";
import {DatFastingContent, DatFastingService} from "@luminate/luminate-ts-sdk";
import Environment from "../../models/Environment";
import {SubText} from "../typography";
import {FastingContentFromConfig} from "./FastingContentFromConfig";

export type FastingDetailsProps = {
    fasting: boolean;
};

export const FastingDetails = (props: FastingDetailsProps) => {
    const [fastingContent, setLabFastingContent] = useState<DatFastingContent | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const {displaySnack} = useAuth();
    const {theme} = useThemeContext();
    const {useLab} = useAuth();

    useEffect(() => {
        loadLabFastingContent();
    }, []);

    const loadLabFastingContent = async () => {
        setLoading(true);
        try {
            setLabFastingContent(await DatFastingService.create(Environment.apiBaseUrl as string).getDatFastingContent());
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>
                We were unable to load the fasting content. Please try again later.
            </SubText>);
        } finally {
            setLoading(false);
        }
    };

    const styles = StyleSheet.create({
        fastingTitleLabel: {
            color: theme.colors.surface,
            fontSize: 18,
            fontWeight: 'bold'
        },
        fastingTitleStyle: {
            borderTopRightRadius: 12,
            borderTopLeftRadius: 12,
            backgroundColor: theme.colors.primary
        },
        fastingDescription: {
            fontSize: 15
        },
        instructionsHeader: {
            fontSize: 15,
            fontWeight: 'bold',
            color: theme.colors.primary
        },
        fastingIcon: {
            color: theme.colors.surface
        }
    });
    return (
        <Card style={{marginHorizontal: 10, marginTop: 10}}>
            <Card.Title title={fastingContent ? fastingContent.header : 'Fasting Specimens'}
                        left={() => <MaterialIcons style={styles.fastingIcon} name={'no-food'} size={24}/>}
                        titleStyle={styles.fastingTitleLabel}
                        style={styles.fastingTitleStyle}/>
            <Card.Content>
                {props.fasting
                    ? <>
                        {
                            loading
                                ? <ActivityIndicator animating={true} size={'large'}/>
                                : <>
                                    {useLab(LabIds.PPL) && <PplFastingContent/>}
                                    {useLab(LabIds.ALVERNO) && <DefaultFastingContent/>}
                                    {!useLab(LabIds.ALVERNO) && !useLab(LabIds.PPL) && fastingContent &&
                                        <FastingContentFromConfig description={fastingContent.description}
                                                                  subHeader={fastingContent.subHeader}
                                                                  subDescription={fastingContent.subDescription}/>}
                                </>
                        }
                    </>
                    : <View>
                        <Paragraph style={styles.fastingDescription}>
                            This test <strong>does not</strong> require you to be fasting prior to collection.
                        </Paragraph>
                    </View>
                }
            </Card.Content>
        </Card>
    );
};