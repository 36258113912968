import {Card, MD2Colors, TextInput} from 'react-native-paper';
import {ScrollView, View} from "react-native";
import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {Strong} from "@expo/html-elements";

import {
    atLeast2Chars,
    containsOneLowercaseCharacter,
    containsOneNumber,
    containsOneSpecialCharacter,
    containsOneUppercaseCharacter,
    dateIsInThePast,
    eightCharacterMinimum,
    isEmail,
    isRequired,
    isValidDate,
    Message,
    mustBe18YearsOld,
    PASSWORD_MUST_MATCH_MESSAGE,
    sameAs,
    ValidUsernameResponseType
} from "@luminate/luminate-ts-sdk";

import {PasswordInput} from "../common/PasswordInput";
import {ValidatedInput} from "../common/ValidatedInput";
import {useRegistrationContext} from "../../contexts/RegistrationContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {Dialog} from "../common/Dialog";
import {Header40, SubText} from "../typography";
import {Button} from "../common/Button";
import {LabeledSwitch} from "../common/LabeledSwitch";
import {ValidatedDateInput} from "../common/ValidatedDateInput";
import {PageFooter} from "../common/PageFooter";
import {useAuth} from "../../contexts/AuthContext";
import {Opacity, ThemeUtilities} from "../../styles/LuminateTheme";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const RegisterScreen = () => {
    const {theme} = useThemeContext();
    const navigation = useNavigation();
    const {isValidUsername} = useAuth();
    const {
        firstName, setFirstName,
        middleName, setMiddleName,
        lastName, setLastName,
        dateOfBirth, setDateOfBirth,
        email, setEmail,
        password, setPassword,
        userName, setUserName,
        agreeToTerms, setAgreeToTerms,
        reset
    } = useRegistrationContext();

    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [firstNameErrors, setFirstNameErrors] = useState(new Array<string>());

    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [lastNameErrors, setLastNameErrors] = useState(new Array<string>());

    const [dateOfBirthErrors, setDateOfBirthErrors] = useState(new Array<string>());
    const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailErrors, setEmailErrors] = useState(new Array<string>());

    const [usernameIsEmail, setUsernameIsEmail] = useState(true);
    const [isUserNameValid, setIsUserNameValid] = useState(false);
    const [userNameErrors, setUserNameErrors] = useState(new Array<string>());
    const [usernameInUse, setUsernameInUse] = useState(false);
    const [usernameFormatInvalid, setUsernameFormatInvalid] = useState(false);

    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState(new Array<string>());

    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [reValidateConfirmPassword, setRevalidateConfirmPassword] = useState(false);
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState(new Array<string>());

    const isFormValid = () => {
        return isFirstNameValid && isLastNameValid && isEmailValid && isUserNameValid && isPasswordValid && isConfirmPasswordValid &&
            isDateOfBirthValid && agreeToTerms;
    };

    const resetForm = () => {
        setUsernameInUse(false);
        reset();
    };

    const onSignIn = () => {
        resetForm();
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.SIGN_IN);
    };

    const showForgotPassword = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.FORGOT_PASSWORD);
    };

    const onCreateAccount = async () => {
        const usernameValid: ValidUsernameResponseType = await isValidUsername(userName);
        if (usernameValid !== ValidUsernameResponseType.NOT_IN_USE) {
            setUsernameInUse(usernameValid === ValidUsernameResponseType.ALREADY_IN_USE);
            setUsernameFormatInvalid(usernameValid === ValidUsernameResponseType.IN_VALID_FORMAT);
        } else {
            // @ts-ignore
            navigation.navigate(SCREEN_NAMES.CONNECT);
        }
    };

    const showTermsOfService = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.TERMS_OF_SERVICE);
    };

    const showContactUs = () => {
        resetForm();
        // @ts-ignore
        navigation.pop();
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS);
    };

    const onFirstNameValidated = (errors: Array<string>) => {
        setIsFirstNameValid(errors.length === 0);
        setFirstNameErrors(errors);
    };

    const onLastNameValidated = (errors: Array<string>) => {
        setIsLastNameValid(errors.length === 0);
        setLastNameErrors(errors);
    };
    const onEmailValidated = (errors: Array<string>) => {
        setIsEmailValid(errors.length === 0);
        setEmailErrors(errors);
    };

    const onDateOfBirthChanged = (selectedDate: string | undefined) => {
        if (selectedDate.length == 2 && dateOfBirth.length == 1) {
            selectedDate += '/'
        } else if (selectedDate.length == 2 && dateOfBirth.length == 3) {
            selectedDate = selectedDate[0]
        } else if (selectedDate.length == 5 && dateOfBirth.length == 4) {
            selectedDate += '/'
        } else if (selectedDate.length == 5 && dateOfBirth.length == 6) {
            selectedDate = dateOfBirth?.slice(0, 4)
        }
        setDateOfBirth(selectedDate);
    };

    const onDateOfBirthValidated = (errors: Array<string>) => {
        setDateOfBirthErrors(errors);
        setIsDateOfBirthValid(errors.length === 0);
    };

    const onEmailChanged = (value: string) => {
        setEmail(value);
        if (usernameIsEmail) {
            setUserName(value);
        }
    };

    const onUsernameIsEmailChanged = () => {
        setUsernameIsEmail(!usernameIsEmail);
        if (!usernameIsEmail) {
            setUserName(email);
        }
    };

    const onUsernameChanged = (value: string) => {
        setUserName(value);
    };
    const onUserNameValidated = (errors: Array<string>) => {
        setIsUserNameValid(errors.length === 0);
        setUserNameErrors(errors);
    };
    const onPasswordValidated = (errors: Array<string>) => {
        setIsPasswordValid(errors.length === 0);
        setPasswordErrors(errors);
        setRevalidateConfirmPassword(true);
    };
    const onConfirmPasswordValidated = (errors: Array<string>) => {
        setIsConfirmPasswordValid(errors.length === 0);
        setConfirmPasswordErrors(errors);
        setRevalidateConfirmPassword(false);
    };
    const passwordMustMatch = (confirmPassword: string, message = PASSWORD_MUST_MATCH_MESSAGE): Message => {
        return sameAs({first: password, second: confirmPassword}, message);
    }

    return (
        <>
            <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
                <Dialog
                    style={{marginVertical: 20}}
                    actions={<View style={{flex: 1, flexDirection: 'column', alignItems: 'center'}}>
                        <Button style={{marginHorizontal: 5, marginVertical: 10}} disabled={!isFormValid()}
                                onPress={onCreateAccount}>
                            Create Account
                        </Button>
                        <SubText>
                            Already have an account?
                            <SubText onPress={onSignIn}
                                     style={{marginLeft: 10, textDecorationLine: 'underline'}}>
                                Sign In
                            </SubText>
                        </SubText>
                        <SubText>
                            Need Help?
                            <SubText onPress={showContactUs}
                                     style={{marginLeft: 10, textDecorationLine: 'underline'}}>
                                Contact Us
                            </SubText>
                        </SubText>
                    </View>}
                >
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Header40>Why create an account?</Header40>
                        <SubText style={{marginVertical: 10}}>Creating an account is required to successfully check-out
                            and process your order. Once you've created an account, this will be the same information
                            you use to order in the future and access your results.
                        </SubText>
                    </View>
                    <View>
                        <SubText style={{textAlign: 'center', marginVertical: 10, marginLeft: 10}}>All fields are
                            required unless noted.</SubText>

                        <ValidatedInput label='First Name'
                                        mode={'outlined'}
                                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                        value={firstName}
                                        onChangeText={(value) => setFirstName(value)}
                                        rules={[isRequired, atLeast2Chars]}
                                        errors={firstNameErrors}
                                        onValidate={onFirstNameValidated}
                        />
                        <SubText>Please enter the name you use for medical records.</SubText>
                        <TextInput label='Middle Name (optional)'
                                   mode={'outlined'}
                                   style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                   value={middleName}
                                   onChangeText={(value) => setMiddleName(value)}
                        />
                        <ValidatedInput label='Last Name'
                                        mode={'outlined'}
                                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                        value={lastName}
                                        onChangeText={(value) => setLastName(value)}
                                        rules={[isRequired, atLeast2Chars]}
                                        errors={lastNameErrors}
                                        onValidate={onLastNameValidated}
                        />
                        <ValidatedDateInput label="Date of Birth (MM/DD/YYYY)"
                                            mode={'outlined'}
                                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                            onChangeText={onDateOfBirthChanged}
                                            value={dateOfBirth}
                                            onValidate={onDateOfBirthValidated}
                                            errors={dateOfBirthErrors}
                                            rules={[isValidDate, dateIsInThePast, mustBe18YearsOld]}
                        />
                        <ValidatedInput label='Email'
                                        mode={'outlined'}
                                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                        autoCorrect={false}
                                        autoCapitalize='none'
                                        value={email}
                                        onChangeText={onEmailChanged}
                                        rules={[isRequired, isEmail]}
                                        errors={emailErrors}
                                        onValidate={onEmailValidated}
                        />
                        <LabeledSwitch value={usernameIsEmail} onValueChanged={onUsernameIsEmailChanged}>
                            <SubText>Use email address for username (recommended)</SubText>
                        </LabeledSwitch>
                        <ValidatedInput label='Username'
                                        mode={'outlined'}
                                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                        autoCorrect={false}
                                        autoCapitalize='none'
                                        disabled={usernameIsEmail}
                                        value={userName}
                                        onChangeText={onUsernameChanged}
                                        rules={[isRequired]}
                                        errors={userNameErrors}
                                        onValidate={onUserNameValidated}
                        />
                        {usernameInUse
                            ? <Card
                                style={{
                                    borderWidth: 1,
                                    borderColor: theme.colors.notification,
                                    backgroundColor: ThemeUtilities.getOpacityForColor(MD2Colors.red200, Opacity.PERCENT_20)
                                }}>
                                <Card.Content>
                                    <SubText style={{color: theme.colors.notification, marginVertical: 10}}>Someone has
                                        already signed up
                                        with this username. Please try another one.
                                        Or did you <Strong style={{textDecorationLine: 'underline'}}
                                                           onPress={showForgotPassword}>forget
                                            your password</Strong>?</SubText>
                                    <SubText style={{color: theme.colors.notification, marginVertical: 10}}>Need
                                        help? <Strong
                                            style={{textDecorationLine: 'underline'}}
                                            onPress={showContactUs}>Contact Us</Strong>!</SubText>
                                </Card.Content>
                            </Card>
                            : <></>
                        }
                        {usernameFormatInvalid
                            ? <Card
                                style={{
                                    borderWidth: 1,
                                    borderColor: theme.colors.notification,
                                    backgroundColor: ThemeUtilities.getOpacityForColor(MD2Colors.red200, Opacity.PERCENT_20)
                                }}>
                                <Card.Content>
                                    <SubText style={{color: theme.colors.notification, marginVertical: 10}}>The username
                                        you have choosen is too short. Please try another one.</SubText>
                                    <SubText style={{color: theme.colors.notification, marginVertical: 10}}>Need
                                        help? <Strong
                                            style={{textDecorationLine: 'underline'}}
                                            onPress={showContactUs}>Contact Us</Strong>!</SubText>
                                </Card.Content>
                            </Card>
                            : <></>
                        }
                        <PasswordInput label='Password'
                                       mode={'outlined'}
                                       style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                       value={password}
                                       onChangeText={(value => setPassword(value))}
                                       rules={[eightCharacterMinimum, containsOneSpecialCharacter, containsOneLowercaseCharacter, containsOneUppercaseCharacter, containsOneNumber]}
                                       errors={passwordErrors}
                                       onValidate={onPasswordValidated}
                        />
                        <PasswordInput label='Confirm Password'
                                       mode={'outlined'}
                                       style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                                       value={confirmPassword}
                                       onChangeText={(value) => setConfirmPassword(value)}
                                       rules={[passwordMustMatch]}
                                       errors={confirmPasswordErrors}
                                       reValidate={reValidateConfirmPassword}
                                       onValidate={onConfirmPasswordValidated}
                        />

                        <LabeledSwitch value={agreeToTerms} onValueChanged={() => setAgreeToTerms(!agreeToTerms)}>
                            <SubText>
                                I agree to the <SubText onPress={showTermsOfService}
                                                        style={{textDecorationLine: 'underline'}}>Terms
                                of Service</SubText>
                            </SubText>
                        </LabeledSwitch>
                    </View>
                </Dialog>
                <PageFooter/>
            </ScrollView>
        </>
    );
};