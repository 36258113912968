import React, {useEffect, useState} from "react";
import {FlatList, FlatListProps} from "react-native";
import {useResponsive} from "../hooks/useResponsive";

export interface ReactiveFlatListPropsInterface<ItemT> extends FlatListProps<ItemT> {
    itemMinimumWidth: number;
    itemMargin?: number;
}

export type ReactiveFlatListProps<T = any> = ReactiveFlatListPropsInterface<T>;

export const ReactiveFlatList = (props: ReactiveFlatListProps) => {
    const {screenWidth, deviceType} = useResponsive();

    const calculateColumnCount = () => {
        return 3 - deviceType;
    };

    const [columnCount, setColumnCount] = useState(calculateColumnCount());

    useEffect(() => {
        const calculatedColumnCount = calculateColumnCount();

        const result = props.numColumns && props.numColumns < calculatedColumnCount ? props.numColumns : calculatedColumnCount;

        setColumnCount(result);
    }, [screenWidth, deviceType]);

    return (
        <FlatList
            key={columnCount}
            keyExtractor={props.keyExtractor}
            style={props.style}
            data={props.data}
            numColumns={columnCount}
            renderItem={props.renderItem}
            onEndReached={props.onEndReached}
            onEndReachedThreshold={props.onEndReachedThreshold}
            refreshing={props.refreshing}
            ItemSeparatorComponent={props.ItemSeparatorComponent}
            ListEmptyComponent={props.ListEmptyComponent}
            ListFooterComponent={props.ListFooterComponent}
            ListFooterComponentStyle={props.ListFooterComponentStyle}
            ListHeaderComponent={props.ListHeaderComponent}
            ListHeaderComponentStyle={props.ListHeaderComponentStyle}
            columnWrapperStyle={props.columnWrapperStyle}
            keyboardShouldPersistTaps={props.keyboardShouldPersistTaps}
            extraData={props.extraData}
            getItemLayout={props.getItemLayout}
            horizontal={props.horizontal}
            initialNumToRender={props.initialNumToRender}
            initialScrollIndex={props.initialScrollIndex}
            legacyImplementation={props.legacyImplementation}
            onRefresh={props.onRefresh}
            onViewableItemsChanged={props.onViewableItemsChanged}
            viewabilityConfig={props.viewabilityConfig}
            removeClippedSubviews={props.removeClippedSubviews}
            fadingEdgeLength={props.fadingEdgeLength}
            scrollEnabled={props.scrollEnabled}
            contentContainerStyle={props.contentContainerStyle}
            onScroll={props.onScroll}
            scrollEventThrottle={props.scrollEventThrottle}
            onLayout={props.onLayout}
        />
    );
};