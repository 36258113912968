import {View} from "react-native";
import React, {useEffect, useState} from "react";
import {HelperText} from "react-native-paper";
import {StripeCardElement} from "@stripe/stripe-js/types/stripe-js/elements";

import {useThemeContext} from "../../contexts/ThemeContext";

export type StripeCardWebProps = {
    card?: StripeCardElement;
    onValidation: (isValid: boolean) => void;
};

export const StripeCardWeb = (props: StripeCardWebProps) => {
    const {theme} = useThemeContext();
    const [cardError, setCardError] = useState();
    const [cardFocus, setCardFocus] = useState(false);

    // @ts-ignore
    const onCardChanged = ({error, complete}) => {
        setCardError(error?.message);
        props.onValidation(complete);
    };

    useEffect(() => {
        if (props.card) {
            mountCard();
        }
    }, [props.card]);

    const mountCard = async () => {
        props.card?.mount('#stripe-card');
        props.card?.on('change', onCardChanged);
        props.card?.on('focus', () => setCardFocus(true));
        props.card?.on('blur', () => setCardFocus(false));
    };

    return (
        <View>
            <View
                style={{
                    elevation: 20,
                    margin: 20,
                    borderStyle: 'solid',
                    padding: 20,
                    borderWidth: cardFocus ? 2 : 1,
                    borderRadius: 4,
                    borderColor: cardError ? theme.colors.error : cardFocus ? theme.colors.primary : theme.colors.backdrop
                }}
                nativeID={'stripe-card'}/>
            <View>
                {cardError
                    ? <HelperText type={'error'}>* {cardError} </HelperText>
                    : <></>
                }
            </View>
        </View>
    );
};