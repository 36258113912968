import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {LayoutChangeEvent, ScrollView, View} from "react-native";
import {Surface, TouchableRipple} from "react-native-paper";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

import {TermsAndConditions} from "../terms/TermsAndConditions";
import {PageFooter} from "../common/PageFooter";
import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText} from "../typography";

export const TermsOfServiceScreen = () => {
    const {theme} = useThemeContext();
    const navigation = useNavigation();
    const [headerPadding, setHeaderPadding] = useState(0);

    return (
        <>
            <ScrollView style={{paddingTop: headerPadding}}>
                <View style={{alignItems: 'center'}}>
                    <View style={{maxWidth: 768}}>
                        <TermsAndConditions/>
                    </View>
                </View>
                <PageFooter/>
            </ScrollView>
            <Surface
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0
                }}
                onLayout={(event: LayoutChangeEvent) => {
                    setHeaderPadding(event.nativeEvent.layout.height);
                }}
            >
                <TouchableRipple onPress={() => navigation.goBack()}>
                    <View style={{paddingVertical: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <MaterialIcons name={'chevron-left'} size={32} color={theme.colors.primary}/>
                        <SubText style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            textDecorationLine: 'underline',
                        }}>
                            Back
                        </SubText>
                    </View>
                </TouchableRipple>
            </Surface>
        </>
    );
};