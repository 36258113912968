import React, {useEffect, useState} from 'react';
import {ScrollView, View} from "react-native";
import {ActivityIndicator, Card, Surface} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";

import {CartTest, DatComponent, DatComponentName, DatComponentService} from "@luminate/luminate-ts-sdk";

import {CartTestSummary, PurchaseConditions, PurchaseSummary, ReviewingResults, SchedulingTests} from "../cart";
import {ShoppingCartWebNavigationScreenProps} from "../routes/CartStackWeb";
import {Header18, Header30, SubText} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";
import {Button} from "../common/Button";
import {useAuth} from "../../contexts/AuthContext";
import {PageFooter} from "../common/PageFooter";
import {CancellationPolicy} from "../order/CancellationPolicy";
import {ExpirationPolicy} from "../order/ExpirationPolicy";
import {useResponsive} from "../hooks/useResponsive";
import {AnalyticsService, ViewCartEvent} from "../../services/AnalyticsService";
import {TestToAnalyticsItemConverter} from "../../services/converter/TestToAnalyticsItemConverter";
import Environment from "../../models/Environment";

export const ShoppingCartScreen = () => {
    const orderConfirmationComponents: DatComponentName[] = [
        DatComponentName.DAT_PURCHASING_CONDITIONS,
        DatComponentName.DAT_CANCELLATION_POLICY,
        DatComponentName.DAT_EXPIRATION_POLICY,
        DatComponentName.DAT_RECEIVING_RESULTS,
        DatComponentName.DAT_SCHEDULING_TESTS_CART
    ];
    const navigation = useNavigation<ShoppingCartWebNavigationScreenProps>();
    const {theme} = useThemeContext();
    const {session, cart, authData, displaySnack} = useAuth();
    const {screenWidth} = useResponsive();

    const [loading, setLoading] = useState(false);
    const [purchasingConditionsComponent, setPurchasingConditionsComponent] = useState<DatComponent | undefined>(undefined);
    const [cancellationPolicyComponent, setCancellationPolicyComponent] = useState<DatComponent | undefined>(undefined);
    const [expirationPolicyComponent, setExpirationPolicyComponent] = useState<DatComponent | undefined>(undefined);
    const [receivingResultsComponent, setReceivingResultsComponent] = useState<DatComponent | undefined>(undefined);
    const [schedulingTestComponent, setSchedulingTestComponent] = useState<DatComponent | undefined>(undefined);
    const [components, setComponents] = useState<Array<DatComponentName>>();

    const loadComponentsContent = async () => {
        setLoading(true);
        try {
            const componentsMap: Map<DatComponentName, DatComponent> = await DatComponentService.create(Environment.apiBaseUrl as string).getDatComponents(orderConfirmationComponents);
            setComponents(Array.from(componentsMap.keys()));
            setPurchasingConditionsComponent(componentsMap.get(DatComponentName.DAT_PURCHASING_CONDITIONS));
            setCancellationPolicyComponent(componentsMap.get(DatComponentName.DAT_CANCELLATION_POLICY));
            setExpirationPolicyComponent(componentsMap.get(DatComponentName.DAT_EXPIRATION_POLICY));
            setReceivingResultsComponent(componentsMap.get(DatComponentName.DAT_RECEIVING_RESULTS));
            setSchedulingTestComponent(componentsMap.get(DatComponentName.DAT_SCHEDULING_TESTS_CART));
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>We were unable to load the shopping card content.
                Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    const sendViewCartAnalyticsEvent = () => {
        AnalyticsService.create().sendEvent(
            new
            ViewCartEvent(
                session?.lab.id as number,
                //@ts-ignore
                cart?.totals / 100,
                //@ts-ignore
                cart?.cartTests.map(test => TestToAnalyticsItemConverter(test)),
                authData?.patientId
            )
        );
    };

    const renderComponent = (componentName: DatComponentName) => {
        switch (componentName) {
            case DatComponentName.DAT_CANCELLATION_POLICY:
                return <CancellationPolicy componentData={cancellationPolicyComponent}/>;
            case DatComponentName.DAT_PURCHASING_CONDITIONS:
                return <PurchaseConditions componentData={purchasingConditionsComponent}/>;
            case DatComponentName.DAT_SCHEDULING_TESTS_CART:
                return <SchedulingTests componentData={schedulingTestComponent}/>;
            case DatComponentName.DAT_RECEIVING_RESULTS:
                return <ReviewingResults componentData={receivingResultsComponent}/>;
            case DatComponentName.DAT_EXPIRATION_POLICY:
                return <ExpirationPolicy componentData={expirationPolicyComponent}/>;
        }
    }

    useEffect(() => {
        loadComponentsContent();
    }, []);

    useEffect(() => {
        sendViewCartAnalyticsEvent();
    }, []);

    const renderCartTestSummary = (test: CartTest) => {
        return (
            <CartTestSummary key={test.testId}
                             id={test.testId}
                             name={test.name}
                             code={test.code}
                             description={test.shortDescription}
                             price={test.price}
            />
        );
    };
    const emptyCart = !cart || cart.cartTests.length === 0;

    const onProceedToCheckout = () => {
        navigation.navigate('Checkout');
    };

    const MAX_SIDE_BY_SIDE = 560;
    const MIN_SIDE_BY_SIDE = 270;
    const MAX_TOTAL = MAX_SIDE_BY_SIDE * 2;

    return (
        <ScrollView>
            <Surface style={{padding: 5}}>
                <View style={{alignItems: 'center'}}>
                    <View style={{width: '100%', maxWidth: MAX_TOTAL}}>
                        <Header30>My Cart</Header30>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap-reverse',
                        padding: 5,
                        width: '100%',
                        maxWidth: MAX_TOTAL
                    }}>
                        <View style={{
                            flex: 3,
                            width: '100%',
                            minWidth: MIN_SIDE_BY_SIDE
                        }}>
                            {emptyCart
                                ? <View style={{flex: 2, justifyContent: 'center'}}>
                                    <Card style={{backgroundColor: theme.colors.secondaryAccent}}>
                                        <Card.Content style={{alignItems: 'center'}}>
                                            <SubText>Your shopping cart is empty.</SubText>
                                        </Card.Content>
                                    </Card>
                                </View>
                                : cart?.cartTests.map(test => {
                                    return renderCartTestSummary(test)
                                })
                            }
                        </View>
                        <View style={[{
                            flex: 3,
                            width: '100%',
                            minWidth: MIN_SIDE_BY_SIDE
                        }, screenWidth >= MAX_SIDE_BY_SIDE ? {paddingLeft: 10} : {paddingBottom: 10}]}>
                            <Card style={{backgroundColor: theme.colors.secondaryAccent}}>
                                <Card.Content>
                                    <Header18 style={{marginLeft: 10}}>Order Summary</Header18>
                                    <PurchaseSummary tests={cart?.cartTests || []} fees={cart?.fees || 0}
                                                     taxes={cart?.taxes || 0}
                                                     total={cart?.totals || 0}/>
                                </Card.Content>
                                <Card.Actions style={{justifyContent: 'center'}}>
                                    <Button disabled={emptyCart} onPress={onProceedToCheckout}>Checkout Now</Button>
                                </Card.Actions>
                                <SubText style={{
                                    fontSize: 10,
                                    textAlign: 'left',
                                    padding: 20
                                }}>{session?.appConfig.feesMsg}</SubText>
                            </Card>
                        </View>
                    </View>
                    {
                        loading
                            ? <ActivityIndicator animating={true} size={'large'}/>
                            : <View style={{paddingBottom: 20, paddingHorizontal: 5, maxWidth: MAX_TOTAL}}>
                                {components?.map((name) => renderComponent(name))}
                            </View>
                    }
                </View>
            </Surface>
            <PageFooter/>
        </ScrollView>
    );
};