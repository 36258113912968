import Constants from 'expo-constants';
import {Linking} from "react-native";

const WHITE_LABEL_QUERY_PARAMETER = 'whitelabel_domain';

export type EnvConfigType = {
    apiBaseUrl?: string;
    appUrl?: string,
    manualWhiteLabel?: string;
    testImageUrl: string;
    env?: Record<string, string>;
    google?: {
        tmContainerId?: string;
    }
    pubNub?: {
        publishKey?: string;
        subscribeKey?: string;
        userId?: string;
    },
    captchaSiteKey?: string,
};

/**
 * Sets the environment configuration based on the target environment.
 * @see app.config.ts
 */
const Environment = {
    env: Constants.manifest?.extra?.env,
    testImageUrl: Constants.manifest?.extra?.env?.ENV_TEST_RESOURCE_URL,
    google: {
        tmContainerId: Constants.manifest?.extra?.env?.ENV_GOOGLE_TM_CONTAINER_ID,
    },
    pubNub: {
        publishKey: Constants.manifest?.extra?.env?.ENV_PUBNUB_PUBLISH_KEY,
        subscribeKey: Constants.manifest?.extra?.env?.ENV_PUBNUB_SUBSCRIBE_KEY,
        userId: Constants.manifest?.extra?.env?.ENV_PUBNUB_USER_ID,
    },
    captchaSiteKey: Constants.manifest?.extra?.env?.ENV_CAPTCHA_SITE_KEY
} as EnvConfigType;

const setAppOrigin = async () => {
    const initialUrl = await Linking.getInitialURL();
    const url = new URL(initialUrl as string);
    Environment.apiBaseUrl = url?.origin;
    Environment.manualWhiteLabel = url.searchParams.has(WHITE_LABEL_QUERY_PARAMETER) ? url.searchParams.get(WHITE_LABEL_QUERY_PARAMETER) as string : undefined;
    Environment.appUrl = url.href;
};

setAppOrigin();

export default Environment;