import {TextInputLabelProp} from "react-native-paper/lib/typescript/src/components/TextInput/types";
import {
    NativeSyntheticEvent,
    StyleProp,
    TextInputEndEditingEventData,
    TextInputKeyPressEventData,
    TextInputSubmitEditingEventData,
    TextStyle
} from "react-native";
import {TextInput} from "react-native-paper";
import {useState} from "react";

import {ValidatorFn} from "@luminate/luminate-ts-sdk";

import {ValidatedInput} from "./ValidatedInput";


export type PasswordInputProps = {
    label?: TextInputLabelProp;
    mode?: "flat" | "outlined" | undefined;
    placeholder?: string;
    rules?: Array<ValidatorFn<string>>;
    errors?: Array<string>;
    dense?: boolean;
    disabled?: boolean;
    onChangeText?: (text: string) => void;
    onEndEditing?: (event: NativeSyntheticEvent<TextInputEndEditingEventData>) => void;
    onSubmitEditing?: (event: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
    onKeyPress?: (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
    onValidate?: (errors: Array<string>) => void;
    reValidate?: boolean;
    onFocus?: (args: any) => void;
    onBlur?: (args: any) => void;
    value?: string;
    style?: StyleProp<TextStyle>;
    theme?: any;
};

export const PasswordInput = (props: PasswordInputProps) => {
    const {errors = new Array<string>()} = props;
    const [secureTextEntry, setSecureTextEntry] = useState(true);
    return (
        <ValidatedInput
            label={props.label}
            mode={props.mode}
            placeholder={props.placeholder}
            errors={errors}
            dense={props.dense}
            rules={props.rules}
            onChangeText={props.onChangeText}
            onEndEditing={props.onEndEditing}
            onSubmitEditing={props.onSubmitEditing}
            onKeyPress={props.onKeyPress}
            onValidate={props.onValidate}
            reValidate={props.reValidate}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            value={props.value}
            style={props.style}
            autoCorrect={false}
            autoCapitalize='none'
            secureTextEntry={secureTextEntry}
            right={
                secureTextEntry ?
                    <TextInput.Icon icon={'eye-off'}
                                    onPress={() => setSecureTextEntry(!secureTextEntry)}/> :
                    <TextInput.Icon icon={'eye'}
                                    onPress={() => setSecureTextEntry(!secureTextEntry)}/>

            }
        />
    );
};