import {View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {Header34, SubText} from "../typography";
import {Button} from "../common/Button";
import {useAuth} from "../../contexts/AuthContext";
import {LuminateStyles} from "../../styles/LuminateStyles";
import {DatHowItWorksContent, DatHowItWorksService} from "@luminate/luminate-ts-sdk";
import Environment from "../../models/Environment";
import {useEffect, useState} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";
import {HowItWorksFromConfig} from "./HowItWorksFromConfig";
import {DefaultHowItWorks} from "./DefaultHowItWorks";
import {LabIds} from "../../models";

export const HowItWorks = () => {
    const [howItWorksLabContent, setHowItWorksLabContent] = useState<DatHowItWorksContent | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const {theme} = useThemeContext();
    const {displaySnack, useLab} = useAuth();
    const navigation = useNavigation();

    useEffect(() => {
        loadHowItWorksLabContent();
    }, []);

    const loadHowItWorksLabContent = async () => {
        setLoading(true);
        try {
            setHowItWorksLabContent(await DatHowItWorksService.create(Environment.apiBaseUrl as string).getDatHowItWorks());
        } catch (ex) {
            displaySnack(<SubText style={{color: theme.colors.surface}}>We were unable to load the how it works content.
                Please try again later.</SubText>);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {
                loading
                    ? <ActivityIndicator animating={true} size={'large'}/>
                    : <>
                        {(useLab(LabIds.ALVERNO) || useLab(LabIds.PPL)) && <DefaultHowItWorks/>}
                        {!useLab(LabIds.ALVERNO) && !useLab(LabIds.PPL) && howItWorksLabContent &&
                            <HowItWorksFromConfig pageTitle={howItWorksLabContent.pageTitle}
                                                  pageDescription={howItWorksLabContent.pageDescription}
                                                  steps={howItWorksLabContent.steps}/>
                        }
                    </>
            }

            <View>
                <View style={[LuminateStyles.centeredInContainer, {margin: 50}]}>
                    <Header34>Need additional help?</Header34>
                    <Button style={{margin: 20}} onPress={() => navigation.navigate('FaqTab')}>Read The
                        FAQ</Button>
                </View>
            </View>
        </>
    );
}