import {CartTest, OrderableTest, OrderTest} from "@luminate/luminate-ts-sdk";
import {AnalyticsItem} from "../AnalyticsService";

export const TestToAnalyticsItemConverter = (source: CartTest | OrderableTest | OrderTest): AnalyticsItem => {
    return {
        item_id: source.code,
        item_name: source.name,
        price: source.price ? source.price / 100 : 0, // Convert into USD
        quantity: 1,
    };
};