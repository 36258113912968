import {useEffect, useState} from "react";

export enum DeviceType {
    DESKTOP,
    TABLET,
    MOBILE
}

export const useResponsive = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

    const getDeviceType = (): DeviceType => {
        const isTablet = window.innerWidth < 1024 && window.innerWidth > 540;
        const isMobile = window.innerWidth <= 540;
        return isMobile ? DeviceType.MOBILE : isTablet ? DeviceType.TABLET : DeviceType.DESKTOP;
    };

    const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

    const onWindowResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
        setDeviceType(getDeviceType());
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    });

    return {
        screenWidth,
        screenHeight,
        deviceType
    }
};