import { Card } from "react-native-paper";
import React from "react";

import { GradientBar } from "../common/GradientBar";
import { Header18 } from "../typography";
import { HtmlView } from "../common/HtmlView";
import { IndividualTest, OrderableTest } from "@luminate/luminate-ts-sdk";
import { View, Text, StyleSheet } from "react-native";
import { DeviceType, useResponsive } from "../hooks/useResponsive";

export type BundleInclusionProps = {
    testId: number;
    name: string;
    description: string;
    tests: (OrderableTest | IndividualTest)[];
    orderable: boolean;
    extended: boolean;
};

const styles = StyleSheet.create({
    card: {
        margin: 5,
        maxWidth: 400,
        width: "100%",
        paddingBottom: 15,
    },
    content: {
        marginTop: 5,
        marginBottom: 2,
    },
    htmlViewHeader: {
        marginBottom: 10,
    }
});

export const BundleInclusion = (props: BundleInclusionProps) => {
    const { screenWidth, deviceType } = useResponsive();
    
    const dynamicCardStyle = deviceType === DeviceType.MOBILE
        ? { width: '90%' }
        : { width: (screenWidth - 300) * 0.45 };

    return (
        <Card style={[styles.card, dynamicCardStyle]}>
            <GradientBar />
            <Card.Content>
                <Header18>{props.name}</Header18>
            </Card.Content>
            <Card.Content style={styles.content}>
                {props.description && (
                    <HtmlView style={styles.htmlViewHeader}>{props.description}</HtmlView>
                )}
                {!props.orderable && props.extended && (
                    <View>
                        <HtmlView style={styles.htmlViewHeader}>Test Includes:</HtmlView>
                        {props.tests.map(test => (
                            <Text key={test.testId}>{test.name}</Text>
                        ))}
                    </View>
                )}
            </Card.Content>
        </Card>
    );
};
