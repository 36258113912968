import { Headline, MD2Colors } from "react-native-paper";
import { Image, ImageBackground, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { useResponsive } from "../../hooks/useResponsive";
import { useAuth } from "../../../contexts/AuthContext";
import { Images } from "../../../models";
import { DatImageUtility } from "../../../services/DatImageUtility";
import { LinearGradient } from "expo-linear-gradient";

export type DefaultHeroProps = {
    imageUrl: string;
    imageStyle?: StyleProp<ViewStyle>
    label?: string;
    labelStyle?: StyleProp<TextStyle>;
    gradientStyle?: StyleProp<ViewStyle>
};

export const DefaultHero = (props: DefaultHeroProps) => {
    const { screenWidth } = useResponsive();
    const { session } = useAuth();

    const MAX_LOGO_WIDTH = 550;
    const MAX_LOGO_HEIGHT = 256;
    const maxLogoWidth = screenWidth > 768 ? MAX_LOGO_WIDTH : MAX_LOGO_WIDTH * .35;
    const maxLogoHeight = screenWidth > 768 ? MAX_LOGO_HEIGHT : MAX_LOGO_HEIGHT * .35;

    const styles = StyleSheet.create({
        imageStyle: {
            width: '100%',
            height: '100%',
        },
        labelStyle: {
            fontFamily: 'century-gothic',
            fontSize: screenWidth > 768 ? 28 : screenWidth > 425 ? 16 : 10,
            fontWeight: "300",
            textAlign: 'center',
            lineHeight: screenWidth > 768 ? 32 : screenWidth > 425 ? 20 : 14,
            resizeMode: 'contain'
        },
    });

    return (
        <ImageBackground source={{ uri: props.imageUrl }}
            defaultSource={Images.placeholder}
            style={[styles.imageStyle, props.imageStyle]} resizeMode={'cover'}>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: 20,
            }}>
                <LinearGradient
                    style={{
                        height: '100%',
                        zIndex: 2,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                    }}
                    colors={[props?.gradientStyle.color, MD2Colors.transparent]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                />
                <Headline numberOfLines={3} style={[styles.labelStyle, props.labelStyle, { zIndex: 3, textAlign: 'left', textTransform: 'uppercase' }]}>{props.label}</Headline>
                <Image
                    source={{ uri: DatImageUtility.getImageUrl(session?.lab.id, 'logo_transparent.png') }}
                    style={[{
                        width: maxLogoWidth,
                        height: maxLogoHeight,
                        zIndex: 3
                    }]}
                    resizeMode={'contain'}
                />
            </View>
        </ImageBackground>
    );
};