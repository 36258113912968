import {StyleSheet, View} from "react-native";
import {Header18, SubText} from "../typography";
import {useAuth} from "../../contexts/AuthContext";
import {HtmlView} from "../common/HtmlView";
import {LabIds} from "../../models";
import {HelperText} from "react-native-paper";
import React from "react";
import {DatComponent} from "@luminate/luminate-ts-sdk";

export const ExpirationPolicy = (props: { componentData?: DatComponent }) => {
    const {session, useLab} = useAuth();
    const styles = StyleSheet.create({
        padding: {marginVertical: 5}
    });
    return (
        <View style={styles.padding}>
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <Header18>What is your expiration policy?</Header18>}
            {(useLab(LabIds.PPL) || useLab(LabIds.ALVERNO)) && <SubText>{session?.appConfig.expirationPolicy}</SubText>}
            {
                !useLab(LabIds.PPL) && !useLab(LabIds.ALVERNO) && props.componentData?.enabled ?
                    props.componentData?.error ?
                        <HelperText type={"error"}>{props.componentData?.content.content}</HelperText>
                        : <>
                            <Header18>{props.componentData?.content.header}</Header18>
                            <HtmlView>{props.componentData?.content.content}</HtmlView>
                        </>
                    :<></>
            }
        </View>
    );
};