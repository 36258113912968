import { ImageBackground, StyleProp, StyleSheet, TextStyle, View } from "react-native";
import { Text } from "react-native-paper";

import { Images } from "../../../models";
import { useThemeContext } from "../../../contexts/ThemeContext";
import { useResponsive } from "../../hooks/useResponsive";

export type CircleHeroProps = {
    imageUrl: string;
    label: string;
    labelStyle?: StyleProp<TextStyle>
};

export const CircleHero = (props: CircleHeroProps) => {
    const { screenWidth } = useResponsive();
    const { theme } = useThemeContext();

    const HERO_HEIGHT = screenWidth >= 768 ? 400 : 200;
    const CIRCLE_SIZE = screenWidth >= 768 ? 300 : 150;

    const styles = StyleSheet.create({
        labelContainer: {
            borderRadius: CIRCLE_SIZE / 2,
            height: CIRCLE_SIZE,
            width: CIRCLE_SIZE,
            backgroundColor: theme.colors.tertiary,
            justifyContent: 'center',
            alignItems: 'center'
        },
        labelStyle: {
            fontSize: screenWidth >= 768 ? 25 : 13,
            fontWeight: 'bold',
            textAlign: 'center',
            resizeMode: 'contain'
        },
    });

    return (
        <View style={{ height: HERO_HEIGHT }}>
            <ImageBackground source={{ uri: props.imageUrl }}
                defaultSource={Images.placeholder}
                style={{ width: '100%', height: '100%' }} resizeMode={'cover'}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    margin: 20
                }}>
                    <View style={styles.labelContainer}>
                        <Text style={[styles.labelStyle, props.labelStyle]}>{props.label}</Text>
                    </View>
                </View>
            </ImageBackground>
        </View>
    );
};