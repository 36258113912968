import {ReactNode} from "react";
import {View} from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

export type UnorderedItemProps = {
    size?: number;
    icon?: string;
    children?: ReactNode;
    centered?: boolean;
};

export const UnorderedItem = (props: UnorderedItemProps) => {
    return (
       <View>
            <View style={{flex: 1, flexDirection: 'row', marginLeft: 20}}>
                <MaterialIcons style={{position: 'absolute', top: 15, left: 0, paddingRight: 20, marginRight: 20}}
                               size={props.size ? props.size : 6} name={props.icon ? props.icon : 'circle'}/>
                <View style={{flex: 1, marginLeft: 20}}>
                    {props.children}
                </View>
            </View>
        </View>
    );
};