import {Paragraph} from "react-native-paper";
import {StyleSheet, View} from "react-native";
import {Header16} from "../typography";

export const FastingContentFromConfig = (props: { description: string, subHeader: string, subDescription: string }) => {
    const styles = StyleSheet.create({
        fastingDescription: {
            fontSize: 15
        }
    });

    return (
        <View>
            <Paragraph style={styles.fastingDescription}>
                {props.description}
            </Paragraph>
            <Header16>
                {props.subHeader}
            </Header16>
            <Paragraph style={styles.fastingDescription}>
                {props.subDescription}
            </Paragraph>
        </View>
    );
}