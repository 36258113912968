import { MD2Colors, Switch} from "react-native-paper";
import {StyleSheet, View} from "react-native";
import {ReactNode} from "react";

export type LabeledSwitchProps = {
    children?: ReactNode,
    value: boolean;
    disabled?: boolean;
    onValueChanged: () => void;
};

export const LabeledSwitch = (props: LabeledSwitchProps) => {
    const styles = StyleSheet.create({
        rootContainer: {
            flexDirection: 'row',
            marginTop: 10,
            marginBottom: 10
        },
        switch: {
            marginLeft: 10,
            marginRight: 10
        },
        labelContainer: {
            flex: 1,
            flexWrap: 'wrap',
            marginRight: 10
        }
    });
    return (
        <View style={styles.rootContainer}>
            <View>
                <Switch style={styles.switch}
                        value={props.value}
                        onValueChange={props.onValueChanged}
                        disabled={props.disabled}
                        color={MD2Colors.green500}
                />
            </View>
            <View style={styles.labelContainer}>
                {props.children}
            </View>
        </View>
    );
};