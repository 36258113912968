import {StyleSheet, View} from "react-native";
import {Header16, SubText} from "../typography";

export const DefaultOrderCheckEmail = () => {
    const styles = StyleSheet.create({
        padding: {
            marginVertical: 5
        }
    });

    return (
        <View style={styles.padding}>
            <View>
                <Header16>Check Email</Header16>
                <SubText>
                    You will receive an order confirmation in your email that can be brought into the Patient
                    Service Center when you arrive for testing. You can also log in to your account to view this
                    information.
                </SubText>
            </View>
        </View>
    );
};