import {View, ScrollView} from "react-native";

import {useThemeContext} from "../../contexts/ThemeContext";
import {HowItWorks, PPLHowItWorks} from "../howItWorks";
import {useAuth} from "../../contexts/AuthContext";
import {PageFooter} from "../common/PageFooter";
import {LabIds} from "../../models";

export const HowItWorksScreen = () => {
    const {useLab, useDefaultLab} = useAuth();
    const {theme} = useThemeContext();

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <View style={{alignItems: 'center'}}>
                <View style={{maxWidth: 955}}>
                    {useLab(LabIds.PPL) && <PPLHowItWorks/>}
                    {useDefaultLab([LabIds.PPL]) && <HowItWorks/>}
                </View>
            </View>
            <PageFooter/>
        </ScrollView>
    );
};