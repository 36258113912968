import {StyleSheet, View} from "react-native";
import {Header18, Header16, SubText} from "../typography";

export const DefaultPreparingForYourTests = () => {
    const styles = StyleSheet.create({
        padding: {marginVertical: 5}
    });
    return (
        <View style={styles.padding}>
            <Header18>Preparing For Your Tests</Header18>
            <Header16>If Fasting is Required</Header16>
            <ol>
                <li>
                    <SubText>
                        Abstain from all food for a minimum of 10 – 12 hours (12 hours for triglyceride analysis).
                        Only water may be consumed during this time.
                    </SubText>
                </li>
                <li style={{marginTop: 5}}>
                    <SubText>
                        Check with your doctor to determine if you should continue to take your usual medication(s)
                        during this time. The laboratory cannot advise you on medications.
                    </SubText>
                </li>
                <li style={{marginTop: 5}}>
                    <SubText>
                        As soon as the laboratory has collected the required specimen, you may resume normal eating
                        unless
                        otherwise directed by your doctor.
                    </SubText>
                </li>
            </ol>
        </View>
    );
};