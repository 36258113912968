import 'react-native-get-random-values';
import {registerRootComponent} from "expo";
import 'react-native-gesture-handler';

import {RouterWeb} from "./src/components/routes/RouterWeb";
import {AuthProvider} from "./src/contexts/AuthContext";
import {ThemeProvider} from "./src/contexts/ThemeContext";

const App = () => {
    return (
        <ThemeProvider>
            <AuthProvider>
                <RouterWeb/>
            </AuthProvider>
        </ThemeProvider>
    );
};

registerRootComponent(App);

export default App;