import {useEffect, useState} from "react";
import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {A, Strong} from "@expo/html-elements";

import {LabeledSwitch} from "../common/LabeledSwitch";
import {SubText} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {useAuth} from "../../contexts/AuthContext";
import {ExternalLink} from "../common/ExternalLink";
import {AcknowledgmentsProps} from "./Acknowledgements";

export const AlvernoAcknowledgments = (props: AcknowledgmentsProps) => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const {session} = useAuth();
    const [over18, setOver18] = useState(false);
    const [forMyself, setForMyself] = useState(false);
    const [resideInState, setResideInState] = useState(false);
    const [agree, setAgree] = useState(false);
    const [redFlag, setRedFlag] = useState(false);

    const styles = StyleSheet.create({
        buttonPadding: {
            margin: 10
        },
        textColor: {
            color: theme.colors.primary
        },
        heading: {
            paddingVertical: 10,
            paddingHorizontal: 5,
            fontSize: 17
        }
    });

    useEffect(() => {
        props.onAcknowledgment(over18 && forMyself && resideInState && agree && redFlag);
    }, [over18, forMyself, resideInState, agree, redFlag]);

    return (
        <View style={{margin: 5}}>
            <Text style={[styles.heading, styles.textColor]}>Before adding items to your cart, please confirm the
                following information:</Text>
            <LabeledSwitch value={over18}
                           onValueChanged={() => setOver18(!over18)}>
                <SubText>I am over 18 years old.</SubText>
            </LabeledSwitch>
            <LabeledSwitch value={forMyself}
                           onValueChanged={() => setForMyself(!forMyself)}>
                <SubText>I am purchasing this for myself.</SubText>
            </LabeledSwitch>
            <LabeledSwitch value={resideInState}
                           onValueChanged={() => setResideInState(!resideInState)}>
                <SubText>I have an Indiana or Illinois address and I will get tested at one
                    of the these locations. <SubText
                        style={[styles.textColor, {textDecorationLine: 'underline'}]}
                        onPress={() => navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS)}>Lab
                        Locations</SubText></SubText>
            </LabeledSwitch>
            <LabeledSwitch value={agree}
                           onValueChanged={() => setAgree(!agree)}>
                <SubText>
                    I agree to the <A style={{textDecorationLine: 'underline'}}
                                      onPress={() => navigation.navigate(SCREEN_NAMES.TERMS_OF_SERVICE)}>Luminate Health
                    Terms of Service</A>. Your information will be handled by Luminate Health in accordance with its <A
                    style={{textDecorationLine: 'underline'}}
                    onPress={() => navigation.navigate(SCREEN_NAMES.PRIVACY_POLICY)}>Privacy Policy</A>.
                    {/*
                        This needs to be a configurable option moving forward.  Maybe if Wheel enabled or something.
                        However this works for Wheel enabled labs moving forward.  This is quick and dirty.
                        */}
                    <SubText> I understand that lab review services and clinical follow up, to the extent
                        needed, are
                        provided by Wheel Provider
                        Group, an independent entity no affiliated with {session?.lab.name}. I agree
                        to <ExternalLink
                            url={'https://www.wheel.com/legal/wheel-provider-group-terms-of-use'}>
                            <SubText>Wheel Provider Group’s Terms of Use</SubText>
                        </ExternalLink>, <ExternalLink
                            url={'https://www.wheel.com/legal/notice-of-privacy-practices'}>
                            <SubText>Notice of Privacy Practices</SubText>
                        </ExternalLink>, and <ExternalLink
                            url={'https://www.wheel.com/wheel-provider-group-telehealth-informed-consent'}>
                            <SubText>Telehealth Informed Consent</SubText>
                        </ExternalLink>.
                    </SubText>
                    : <></>
                </SubText>
            </LabeledSwitch>
            <LabeledSwitch
                value={redFlag} onValueChanged={() => setRedFlag(!redFlag)}>
                <SubText>
                    I am <Strong>NOT</Strong> experiencing any of the following: high fever
                    ({">"}101.9F/38.8C), shortness of breath, difficulty breathing, chest pain, heart
                    palpitations, abnormal bruising, abnormal bleeding, extreme fatigue, dizziness, new
                    weakness or paralysis, difficulty with speech, confusion, extreme pain in any body
                    part, or inability to remain hydrated or keep down fluids.
                </SubText>
            </LabeledSwitch>
        </View>
    );
};