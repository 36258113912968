import {StyleSheet, View} from "react-native";
import {Paragraph, Subheading} from "react-native-paper";

import {useThemeContext} from "../../contexts/ThemeContext";

export const PplFastingContent = () => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        fastingDescription: {
            fontSize: 15
        },
        fastingSubHeader: {
            fontSize: 15,
            fontWeight: 'bold',
            color: theme.colors.primary,
            marginTop: 10
        }
    });

    return (
        <View>
            <Paragraph style={styles.fastingDescription}>
                This test requires you to be fasting prior to collection. Blood composition is significantly altered
                after meals and is not suitable for many clinical chemistry tests. Improper preparation for this test
                may cause the wrong results, leading to the wrong treatment being given.
            </Paragraph>
            <Subheading style={styles.fastingSubHeader} >Read Instructions</Subheading>
            <Paragraph style={styles.fastingDescription}>
                Do not eat or drink anything, except for water, at least 8 hours before your specimen is collected.
            </Paragraph>
        </View>
    );
}