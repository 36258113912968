import {useEffect, useState} from "react";
import {FlatList, ListRenderItemInfo, StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";

import {useThemeContext} from "../../contexts/ThemeContext";
import {LabeledSwitch} from "../common/LabeledSwitch";
import {AcknowledgmentsProps} from "./Acknowledgements";
import {DatAcknowledgement, DatAcknowledgementService} from "@luminate/luminate-ts-sdk";
import {HtmlView} from "../common/HtmlView";
import Environment from "../../models/Environment";

export const DefaultAcknowledgments = (props: AcknowledgmentsProps) => {
    const {theme} = useThemeContext();
    const [acknowledgments, setAcknowledgments] = useState<Array<DatAcknowledgement>>([]);
    const [ackState, setAckState] = useState<Map<string, boolean>>();

    const styles = StyleSheet.create({
        buttonPadding: {
            margin: 10
        },
        textColor: {
            color: theme.colors.primary
        },
        heading: {
            paddingVertical: 10,
            paddingHorizontal: 5,
            fontSize: 17
        }
    });

    const loadAcknowledgmentList = async () => {
        try {
            const result = await DatAcknowledgementService.create(Environment.apiBaseUrl as string).getDatAcknowledgments();

            const newAckState = new Map<string, boolean>();
            result.forEach(ack => {
                newAckState.set(ack.id, false);
            });

            setAckState(newAckState);
            setAcknowledgments(result);
        } catch (ex) {
            console.log('Error Loading Acknowledgments', ex);
        }
    };

    useEffect(() => {
        loadAcknowledgmentList();
    }, []);

    const toggleAckState = (ackId: string) => {
        const newAckState = new Map<string, boolean>(ackState);
        newAckState.set(ackId, !ackState!.get(ackId));
        setAckState(newAckState);
    };

    useEffect(() => {
        let aggregate: boolean = !!ackState?.keys();

        ackState?.forEach(value => {
            aggregate = aggregate && value;
        });

        if (props.onAcknowledgment) {
            props.onAcknowledgment(aggregate || false);
        }
    }, [ackState]);

    const renderAcknowledgment = (acknowledgment: ListRenderItemInfo<DatAcknowledgement>) => {
        return (
            <LabeledSwitch value={ackState!.get(acknowledgment.item.id) || false}
                           onValueChanged={() => toggleAckState(acknowledgment.item.id)}>
                <HtmlView>
                    {acknowledgment.item.content}
                </HtmlView>
            </LabeledSwitch>
        );
    };

    const renderEmptyAcknowledgmentList = () => {
        return (
            <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: theme.colors.errorContainer
            }}>
                <Text style={{color: theme.colors.onErrorContainer}}>Could not load acknowledgments.</Text>
            </View>
        );
    };

    return (
        <View style={{margin: 5}}>
            <Text style={[styles.heading, styles.textColor]}>Before adding items to your cart, please confirm the
                following information:</Text>
            <FlatList data={acknowledgments}
                      renderItem={renderAcknowledgment}
                      ListEmptyComponent={renderEmptyAcknowledgmentList}
                      keyExtractor={ack => `${ack.id}`}/>
        </View>
    );
};