import React from "react";
import {SubText} from "../typography";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {useNavigation} from "@react-navigation/native";

export const DefaultSchedulingTestsWithLabLocations = () => {
    const navigation = useNavigation();
    return (
        <SubText>No appointment is needed. Get tested at any of our <SubText
            style={{textDecorationLine: 'underline'}} onPress={() => navigation.navigate(SCREEN_NAMES.LAB_LOCATIONS)}>Lab
            Locations</SubText>.</SubText>
    );
};