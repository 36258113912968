import React from "react";
import {Text} from "react-native-paper";
import {StyleProp, TextStyle} from "react-native";
import {NumberToCurrencyConverter} from "@luminate/luminate-ts-sdk";

export type CurrencyProps = {
    value: number | undefined;
    style?: StyleProp<TextStyle>
};

export const Currency = (props: CurrencyProps) => {
    const currencyConverter = NumberToCurrencyConverter.create();

    return (
        <Text style={props.style}>{currencyConverter.toModel(props.value)}</Text>
    )
};