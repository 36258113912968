import {ReactNode, useState} from "react";
import {Image, ImageSourcePropType, LayoutChangeEvent, StyleSheet, View} from "react-native";
import {Images} from "../../models/Images";

export type HowItWorksContentProps = {
    title: ReactNode;
    description: ReactNode,
    reverse?: boolean;
    image?: ImageSourcePropType;
};

export const HowItWorksContent = (props: HowItWorksContentProps) => {
    const MAX_IMAGE_WIDTH = 543;
    const MAX_IMAGE_HEIGHT = 305;

    const [imageWidth, setImageWidth] = useState(MAX_IMAGE_WIDTH);
    const [imageHeight, setImageHeight] = useState(MAX_IMAGE_HEIGHT);

    const styles = StyleSheet.create({
        wrapper: {
            flexWrap: 'wrap',
            flexDirection: props.reverse ? 'row-reverse' : 'row',
            margin: 20,
            justifyContent: 'space-evenly',
            alignItems: 'center',
        }
    });

    const onLayoutChanged = (event: LayoutChangeEvent): void => {
        const changePercentage = event.nativeEvent.layout.width / 548;
        const newWidth = changePercentage < 1 ? MAX_IMAGE_WIDTH * changePercentage : MAX_IMAGE_WIDTH;
        const newHeight = changePercentage < 1 ? MAX_IMAGE_HEIGHT * changePercentage : MAX_IMAGE_HEIGHT;
        setImageWidth(newWidth);
        setImageHeight(newHeight);
    };

    return (
        <View
            onLayout={onLayoutChanged}
            style={styles.wrapper}>
            <View style={{flex: 1, maxWidth: 543, minWidth: 260, padding: 10}}>
                {props.title}
                {props.description}
            </View>
            <View>
                <Image source={props.image}
                       defaultSource={Images.placeholder}
                       style={{width: imageWidth, height: imageHeight}}
                       resizeMode={'cover'} />
            </View>
        </View>
    );
};