import React from "react";
import {StyleSheet, View} from "react-native";
import {Card, Title} from "react-native-paper";
import {MaterialIcons} from "@expo/vector-icons";

import {Images} from "../../models";
import {Currency} from "../common/Currency";
import {useThemeContext} from "../../contexts/ThemeContext";
import {SubText} from "../typography";
import {GradientBar} from "../common/GradientBar";
import {Button} from "../common/Button";
import {DatImageUtility} from "../../services/DatImageUtility";
import {useAuth} from "../../contexts/AuthContext";
import {HtmlView} from "../common/HtmlView";

export interface OrderableTestProps {
    id: number;
    name: string;
    code: string;
    description: string;
    featured: boolean;
    price: number;
    minCardWidth: number;
    onAddTestToCart: (testId: number) => Promise<void> | undefined;
}

export const OrderableTestSummary = (props: OrderableTestProps) => {
    const {theme} = useThemeContext();
    const {session} = useAuth();

    const styles = StyleSheet.create({
        card: {
            width: props.minCardWidth,
            margin: 10,
            flex: 1
        },
        cardContainer: {
            flexShrink: 0,
            flex: 1
        },
        cardActionsStyle: {
            justifyContent: 'space-between',
            alignContent: 'center',
            alignSelf: 'flex-end'
        },
        testTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.colors.primary,
        },
        testImage: {
            width: 100,
            height: 100,
            flex: 1
        },
        featured: {
            backgroundColor: theme.colors.secondaryAccent,
            width: 85
        },
        currency: {
            fontSize: 24,
            color: theme.colors.primary,
            fontWeight: 'bold',
            marginTop: 5
        },
        testDescription: {
            flexGrow: 1,
            fontSize: 15,
            marginTop: 20,
            fontFamily: theme.fonts.bodyMedium.fontFamily,
            fontWeight: theme.fonts.bodyMedium.fontWeight
        },
        cover: {
            height: 'auto', 
            aspectRatio: 16/9
        }
    });

    const onAddTestToCart = async () => {
        if (props.onAddTestToCart) {
            props.onAddTestToCart(props.id);
        }
    };

    return (
        <Card style={styles.card} contentStyle={styles.cardContainer}>
            <Card.Cover source={{ uri: DatImageUtility.getJpgImageUrl(session?.lab.id, props.code)}}
                        defaultSource={Images.placeholder}
                        resizeMode={'cover'}
                        style={styles.cover}/>
            <GradientBar/>
            <Card.Content style={{flex: 1}}>
                <View>
                    <Title style={styles.testTitle}>{props.name}</Title>
                    <HtmlView style={{marginTop: 20, marginBottom: 2}}>
                        {props.description}
                    </HtmlView>
                </View>
                <View style={{marginVertical: 10, flexDirection: 'row', alignItems: 'center'}}>
                    <SubText style={{textDecorationLine: 'underline', color: theme.colors.primary, fontWeight: 'bold'}}>View
                        Details</SubText>
                    <MaterialIcons name={'chevron-right'} size={32} color={theme.colors.primary}/>
                </View>
            </Card.Content>
            <Card.Actions style={styles.cardActionsStyle}>
                <Currency style={styles.currency} value={props.price}/>
                <Button onPress={onAddTestToCart}>Add To Cart</Button>
            </Card.Actions>
        </Card>
    );
};