import {ReactNode} from "react";
import {StyleProp, StyleSheet, TextStyle} from "react-native";
import {Text} from "react-native-paper";

import {useThemeContext} from "../../contexts/ThemeContext";

export type Header24Props = {
    children?: ReactNode;
    style?: StyleProp<TextStyle>;
};

export const Header24 = (props: Header24Props) => {
    const {theme} = useThemeContext();

    const styles = StyleSheet.create({
        text: {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 30,
            marginVertical: 2,
            letterSpacing: 0.15,
            color: theme.colors.primary,
        }
    });

    return (
        <Text style={[styles.text, props.style]}>{props.children}</Text>
    );
};