import {ReactNode} from "react";
import {StyleSheet, View, ViewStyle} from "react-native";
import {Card} from "react-native-paper";
import {PlatformUtils} from "../../models";

export type DialogProps = {
    style?: ViewStyle;
    children?: ReactNode;
    actions?: ReactNode;
    contentStyle?: ViewStyle;
};

export const Dialog = (props: DialogProps) => {
    const styles = StyleSheet.create({
        form: {
            flex: 1,
            maxWidth: PlatformUtils.isWeb() ? 700 : undefined,
        },
        screen: {
            flex: 1,
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center'
        },
    });

    return (
        <View style={[styles.screen, props.style]}>
            <View style={styles.form}>
                <Card>
                    <Card.Content style={props.contentStyle} >{props.children}</Card.Content>
                    {props.actions
                        && <Card.Actions>{props.actions}</Card.Actions>
                    }
                </Card>
            </View>
        </View>
    );
};