import {Divider, Menu, Surface} from "react-native-paper";
import {LayoutChangeEvent, Linking, StyleSheet, TouchableOpacity, View} from "react-native";
import {useNavigation, useNavigationState} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";
import {useState} from "react";

import {useThemeContext} from "../../contexts/ThemeContext";
import {GradientBar} from "./GradientBar";
import {useAuth} from "../../contexts/AuthContext";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import Environment from "../../models/Environment";
import {SubText} from "../typography";
import {NavigationLink} from "./NavigationLink";
import {DatNavigationUtils} from "./DatNavigator";

export const PageFooter = () => {
    const {theme} = useThemeContext();
    const navigation = useNavigation();
    const {authData, session} = useAuth();

    const [menuVisible, setMenuVisible] = useState(false);
    const navigationState = useNavigationState(state => state);

    const [displayMenu, setDisplayMenu] = useState(false);
    const [displayViewMyResultsNavLink, setDisplayViewMyResultsNavLink] = useState(!session?.appConfig.guestCheckoutEnabled);
    const [displayLabLocationsNavLink, setDisplayLabLocationsNavLink] = useState(!session?.appConfig.serviceAreasEnabled);
    const [displayServiceAreasNavLink, setDisplayServiceAreasNavLink] = useState(!!session?.appConfig.serviceAreasEnabled);
    const [displayHelpNavLink, setDisplayHelpNavLink] = useState(true);
    const [displayHowItWorksNavLink, setDisplayHowItWorksNavLink] = useState(true);
    const [displayBrowseNavLink, setDisplayBrowseNavLink] = useState(true);
    const [displayAuthNavLink, setDisplayAuthNavLink] = useState(!session?.appConfig.guestCheckoutEnabled);

    const MIN_MENU_DISPLAY_RESOLUTION = 1195;
    const VIEW_LAB_LOCATIONS_RESOLUTION = 1062;
    const VIEW_HELP_DISPLAY_RESOLUTION = 850;
    const VIEW_HOW_IT_WORKS_DISPLAY_RESOLUTION = 675;
    const VIEW_BROWSE_DISPLAY_RESOLUTION = 500;
    const VIEW_AUTH_DISPLAY_RESOLUTION = 360;

    const styles = StyleSheet.create({
        container: {
            backgroundColor: theme.colors.primary
        },
        inner: {
            padding: 12,
        }
    });

    const onNavigateTo = (screen: string) => {
        setMenuVisible(false);
        navigation.navigate(screen);
    };

    const isMenuSelected = (screen: string): boolean => {
        return DatNavigationUtils.isSelected(screen, navigationState);
    };

    const onPageFooterLayoutChanged = (event: LayoutChangeEvent): void => {
        const screenWidth = event.nativeEvent.layout.width;
        setDisplayMenu(screenWidth <= MIN_MENU_DISPLAY_RESOLUTION);
        setDisplayViewMyResultsNavLink(screenWidth > MIN_MENU_DISPLAY_RESOLUTION && !session?.appConfig.guestCheckoutEnabled);
        setDisplayLabLocationsNavLink(screenWidth > VIEW_LAB_LOCATIONS_RESOLUTION && !session?.appConfig.serviceAreasEnabled);
        setDisplayServiceAreasNavLink(screenWidth > VIEW_LAB_LOCATIONS_RESOLUTION && !!session?.appConfig.serviceAreasEnabled);
        setDisplayHelpNavLink(screenWidth > VIEW_HELP_DISPLAY_RESOLUTION);
        setDisplayHowItWorksNavLink(screenWidth > VIEW_HOW_IT_WORKS_DISPLAY_RESOLUTION);
        setDisplayBrowseNavLink(screenWidth > VIEW_BROWSE_DISPLAY_RESOLUTION);
        setDisplayAuthNavLink(screenWidth > VIEW_AUTH_DISPLAY_RESOLUTION && !session?.appConfig.guestCheckoutEnabled);
    };

    return (
        <Surface
            onLayout={onPageFooterLayoutChanged}
            style={styles.container}>
            <GradientBar/>
            <View style={styles.inner}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 2, flexDirection: 'row', flexWrap: 'wrap'}}>
                        {displayMenu
                            ? <Menu
                                visible={menuVisible}
                                onDismiss={() => setMenuVisible(false)}
                                anchor={
                                    <TouchableOpacity onPress={() => setMenuVisible(true)}>
                                        <View style={{margin: 10}}>
                                            <MaterialIcons name={'menu'} size={24} color={theme.colors.surface}/>
                                        </View>
                                    </TouchableOpacity>
                                }>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.BROWSE) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayBrowseNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.BROWSE)}
                                    title='Browse Tests'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.HOW_IT_WORKS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayHowItWorksNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.HOW_IT_WORKS)}
                                    title='How It Works'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.HELP) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayHelpNavLink ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.HELP)}
                                    title='Help / FAQ'
                                />
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.LAB_LOCATIONS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayLabLocationsNavLink && !session?.appConfig.serviceAreasEnabled ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.LAB_LOCATIONS)}
                                    title='Lab Locations'/>
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.SERVICE_AREAS) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayServiceAreasNavLink && !!session?.appConfig.serviceAreasEnabled? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.SERVICE_AREAS)}
                                    title='Service Areas'/>
                                <Menu.Item onPress={async () => {
                                    await Linking.openURL(Environment.apiBaseUrl as string);
                                    setMenuVisible(false);
                                }} title='View Results'
                                           style={
                                               [
                                                   !displayViewMyResultsNavLink && !session?.appConfig.guestCheckoutEnabled ? {} : {display: 'none'}
                                               ]
                                           }/>
                                {!displayAuthNavLink && !session?.appConfig.guestCheckoutEnabled ? <Divider/> : <></>}
                                <Menu.Item
                                    style={
                                        [
                                            isMenuSelected(SCREEN_NAMES.ACCOUNT) ? {
                                                borderLeftWidth: 2,
                                                borderLeftColor: theme.colors.secondary
                                            } : {},
                                            !displayAuthNavLink && !session?.appConfig.guestCheckoutEnabled ? {} : {display: 'none'}
                                        ]
                                    }
                                    onPress={() => onNavigateTo(SCREEN_NAMES.ACCOUNT)}
                                    title={authData ? 'My Account' : 'Sign In'}
                                />
                            </Menu>
                            : <></>
                        }
                        <NavigationLink
                            screen={SCREEN_NAMES.BROWSE}
                            dark={true}
                            label={'Browse Tests'}
                            visible={displayBrowseNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.BROWSE)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.HOW_IT_WORKS}
                            dark={true}
                            label={'How It Works'}
                            visible={displayHowItWorksNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.HOW_IT_WORKS)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.HELP}
                            dark={true}
                            label={'Help / FAQ'}
                            visible={displayHelpNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.HELP)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.LAB_LOCATIONS}
                            dark={true}
                            label={'Lab Locations'}
                            visible={displayLabLocationsNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.LAB_LOCATIONS)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.SERVICE_AREAS}
                            dark={true}
                            label={'Service Areas'}
                            visible={displayServiceAreasNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.SERVICE_AREAS)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.RESULTS}
                            dark={true}
                            label={'View Results'}
                            visible={displayViewMyResultsNavLink}
                            onNavigate={async () => {
                                await Linking.openURL(Environment.apiBaseUrl as string);
                            }}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.CONTACT_US}
                            dark={true}
                            label={'Contact Us'}
                            onNavigate={() => { onNavigateTo(SCREEN_NAMES.CONTACT_US) }}
                        />
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', direction: 'rtl'}}>
                        <NavigationLink
                            screen={SCREEN_NAMES.CART}
                            dark={true}
                            label={'View Cart'}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.CART)}
                        />
                        <NavigationLink
                            screen={SCREEN_NAMES.ACCOUNT}
                            dark={true}
                            label={authData ? 'My Account' : 'Sign In'}
                            visible={displayAuthNavLink}
                            onNavigate={() => onNavigateTo(SCREEN_NAMES.ACCOUNT)}
                        />
                    </View>
                </View>
                <View style={{flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', padding: 5}}>
                    <View style={{flex: 1}}>
                        <SubText style={{fontSize: 10, color: theme.colors.surface}}>
                            © Copyright 2024 Luminate Health Inc. All rights reserved.
                        </SubText>
                    </View>
                    <View style={{flex: 2, flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                        <SubText style={{marginHorizontal: 5, fontSize: 10, color: theme.colors.surface}}
                                 onPress={() => onNavigateTo(SCREEN_NAMES.PRIVACY_POLICY)}>
                            Privacy Policy
                        </SubText>
                        <SubText style={{marginHorizontal: 5, fontSize: 10, color: theme.colors.surface}}
                                 onPress={() => onNavigateTo(SCREEN_NAMES.TERMS_OF_SERVICE)}>
                            Terms and Conditions
                        </SubText>
                    </View>
                </View>
            </View>
        </Surface>
    );
};