import React, {ReactNode} from "react";
import WebView from "react-native-webview";
import Color from "color";
import {PlatformUtils} from "../../models";
import {useThemeContext} from "../../contexts/ThemeContext";

export type HtmlViewProps = {
    style?: any;
    children?: ReactNode;
};

export const HtmlView = (props: HtmlViewProps) => {
    const {theme} = useThemeContext();
    const defaultStyle = {
        boxSizing: 'border-box',
        direction: 'ltr',
        display: 'block',
        fontSize: 15,
        fontFamily: theme.fonts.bodyMedium.fontFamily,
        fontWeight: theme.fonts.bodyMedium.fontWeight,
        color: Color(theme.colors.text),
        letterSpacing: 0.15,
        overflowWrap: 'break-word',
        pointerEvents: 'auto',
        textAlign: 'left',
        textRendering: 'optimizeLegibility',
        textSizeAdjust: '100%',
        unicodeBidi: 'isolate',
        whiteSpace: 'pre-wrap',
        lineHeight: '20px',
    };

    return (
        <>
            {PlatformUtils.isWeb()
                ? <div style={{...defaultStyle, ...props.style}}
                       dangerouslySetInnerHTML={{__html: props?.children as string}}/>
                : <WebView style={[defaultStyle, props.style]}
                           originWhitelist={['*']}
                           source={{html: props?.children as string}}
                />
            }
        </>
    );
}