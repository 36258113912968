import {useEffect, useState} from "react";
import {ActivityIndicator, Card, Searchbar, Surface} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {FlatList, ListRenderItemInfo, Text, TouchableOpacity, View} from "react-native";
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

import {DateUtilities, Order} from "@luminate/luminate-ts-sdk";

import {OrderHistorySummary} from "../order/OrderHistorySummary";
import {useAuth} from "../../contexts/AuthContext";
import {OrderHistoryListScreenNavigationProps} from "../routes/OrderHistoryStack";
import {Header18} from "../typography";
import {useThemeContext} from "../../contexts/ThemeContext";
import {PageFooter} from "../common/PageFooter";
import {useResponsive} from "../hooks/useResponsive";

export const OrderHistoryListScreen = () => {
    const {
        authData,
        orders,
        loadOrders,
        loadingOrders,
        ordersFilter,
        onOrdersFilterChanged
    } = useAuth();
    const navigation = useNavigation<OrderHistoryListScreenNavigationProps>();
    const {theme} = useThemeContext();
    const {screenWidth} = useResponsive();
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        setMobileView(screenWidth < 600);
    }, [screenWidth]);

    const renderOrderHistorySummary = (order: ListRenderItemInfo<Order>) => {
        return (
            <TouchableOpacity onPress={() => navigation.push('OrderDetails', {orderId: order.item.id})}>
                <OrderHistorySummary orderId={order.item.id}
                                     orderDate={DateUtilities.formatYYYYMMDDHHMM(order.item.createdDateTime)}
                                     oderNumber={order.item.orderNumber}
                                     status={order.item.status} total={order.item.totals}
                                     compactView={mobileView}
                />
            </TouchableOpacity>);
    };

    const onEndReached = async () => {
        if (!loadingOrders) {
            await loadOrders();
        }
    };

    useEffect(() => {
        if (authData) {
            loadOrders();
        }
    }, [ordersFilter]);

    return (
        <View style={{flex: 1}}>
            <Surface>
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: 10
                    }}>
                    <Header18>Order History</Header18>
                    <Searchbar
                        value={ordersFilter}
                        placeholder={'Search Order Id'}
                        onChangeText={onOrdersFilterChanged}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                        clearIcon={() => <MaterialCommunityIcons onPress={() => onOrdersFilterChanged('')}
                                                                 name={'close'}/>}
                    />
                </View>
                {mobileView
                    ? <></>
                    : <Card>
                        <Card.Content style={{flexDirection: 'row', margin: 5}}>
                            <View style={{flex: 1, margin: 5}}>
                                <Text
                                    style={{fontSize: 16, fontWeight: 'bold', color: theme.colors.primary}}>Date</Text>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <Text style={{fontSize: 16, fontWeight: 'bold', color: theme.colors.primary}}>Order
                                    Id</Text>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    color: theme.colors.primary
                                }}>Status</Text>
                            </View>
                            <View style={{flex: 1, margin: 5}}>
                                <Text style={{fontSize: 16, fontWeight: 'bold', color: theme.colors.primary}}>Total</Text>
                            </View>
                        </Card.Content>
                    </Card>
                }
            </Surface>
            <FlatList
                data={orders}
                renderItem={renderOrderHistorySummary}
                style={{flex: 1}}
                keyExtractor={(order) => `order-${order.orderNumber}`}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.15}
                refreshing={loadingOrders}
                ListFooterComponent={<PageFooter/>}
            />
            {loadingOrders
                ? <ActivityIndicator animating={true} size={'large'}/>
                : <></>}
        </View>
    );
};