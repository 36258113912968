import { Dispatch, SetStateAction } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import DropDownPicker, { ItemType, ValueType } from 'react-native-dropdown-picker';

export type DropDownProps<T> = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    placeholderOffset?: number;
    placeholder?: string;
    placeholderStyle?: StyleProp<TextStyle>;
    style?: StyleProp<ViewStyle>;
    dropDownContainerStyle?: StyleProp<ViewStyle>;
    value: T | null;
    setValue: Dispatch<SetStateAction<T>>;
    items: ItemType<T>[];
    zIndex?: number;
    zIndexInverse?: number;
};

export const DropDown = <T extends ValueType>(props: DropDownProps<T>) => {
    const theme = useTheme();
    const elevatePlaceholder = props.placeholder && props.value != null;

    const styles = StyleSheet.create({
        elevatedPlaceHolderText: {
            position: 'absolute',
            left: 0,
            top: -3,
            zIndex: 6003,
            marginHorizontal: 10,
            paddingHorizontal: 10,
            backgroundColor: theme.colors.background,
            fontFamily: theme.fonts.bodyMedium.fontFamily,
        },
        openedPlaceHolderText: {
            color: theme.colors.primary,
        },
        closedPlaceHolderText: {
            color: theme.colors.outline,
        },
        placeHolderText: {
            color: theme.colors.backdrop,
            fontSize: 16,
            fontFamily: theme.fonts.bodyMedium.fontFamily,
        },
        defaultStyle: {
            borderRadius: 4,
            borderColor: theme.colors.backdrop,
            backgroundColor: theme.colors.background,
        },
        opened: {
            borderWidth: 2,
            borderColor: theme.colors.primary,
        },
    });

    return (
        <View style={[{ zIndex: 1, margin: 3 }, props.style]}>
            {elevatePlaceholder && (
                <Text style={[styles.elevatedPlaceHolderText, props.placeholderStyle, props.open ? styles.openedPlaceHolderText : styles.closedPlaceHolderText]}>
                    {props.placeholder}
                </Text>
            )}
            <DropDownPicker
                placeholder={props.placeholder}
                placeholderStyle={[styles.placeHolderText, props.placeholderStyle]}
                style={[styles.defaultStyle, props.open ? styles.opened : {}]}
                dropDownContainerStyle={[props.dropDownContainerStyle, props.open ? styles.opened : {}]}
                open={props.open}
                value={props.value}
                items={props.items}
                setOpen={props.setOpen}
                setValue={props.setValue}
                zIndex={props.zIndex}
                zIndexInverse={props.zIndexInverse}
            />
        </View>
    );
};
