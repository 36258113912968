import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import { MD2Colors} from "react-native-paper";
import {ReactNode} from "react";
import {Opacity, ThemeUtilities} from "../../styles/LuminateTheme";

export interface OverlayProps {
    style?: StyleProp<ViewStyle>;
    children?: ReactNode;
    width?: number;
    height?: number;
}

export const Overlay = (props: OverlayProps) => {
    const styles = StyleSheet.create({
        overlay: {
            top: 0,
            left: 0,
            position: 'absolute',
            height: props.height ? props.height : '100vh',
            width: props.width ? props.width : '100vw',
            backgroundColor: ThemeUtilities.getOpacityForColor(MD2Colors.grey500, Opacity.PERCENT_33)
        }
    });

    return (
        <View style={[styles.overlay, props.style]}>
            {props.children}
        </View>
    );
};