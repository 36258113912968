import React, {useEffect, useState} from "react";
import {LayoutChangeEvent, ListRenderItemInfo, StyleSheet} from "react-native";
import {Headline, TouchableRipple} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";

import {
    Category,
    CategoryService,
    OrderableTest,
    Page,
    Pagination,
    PublicOrderableTestService
} from "@luminate/luminate-ts-sdk";

import {useAuth} from "../../contexts/AuthContext";
import {ReactiveFlatList} from "../common/ReactiveFlatList";
import {BrowseTestNavigator} from "../orderableTests/BrowseTestNavigator";
import {OrderableTestSummary} from "../orderableTests/OrderableTestSummary";
import {PageFooter} from "../common/PageFooter";
import {useThemeContext} from "../../contexts/ThemeContext";
import {AddToCartOverlay, Layout} from "../orderableTests/AddToCartOverlay";
import {SCREEN_NAMES} from "../routes/AppStackWeb";
import {DeviceType, useResponsive} from "../hooks/useResponsive";
import {SubText} from "../typography";
import Environment from "../../models/Environment";
import {AnalyticsService, SearchEvent} from "../../services/AnalyticsService";
import {MAX_SCREEN_WIDTH} from "../../models";

export const BrowseScreen = () => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const {session, authData, displaySnack} = useAuth();
    const [menuItems, setMenuItems] = useState<Array<string>>([]);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [tests, setTests] = useState<Array<OrderableTest>>([]);
    const [testFilter, setTestFilter] = useState<string>('');

    const [loading, setLoading] = useState(false);
    const featuredList = 'Featured';
    const allTestsList = 'All Tests';
    const [selected, setSelected] = useState<string>(featuredList);
    const {screenWidth, deviceType} = useResponsive();

    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [testToAdd, setTestToAdd] = useState<OrderableTest>();
    const [overlayLayout, setOverlayLayout] = useState<Layout>();

    const getCardWidth = (): number => {
        const columnCount = 3 - deviceType;
        const useWidth = (deviceType === DeviceType.DESKTOP && screenWidth > MAX_SCREEN_WIDTH) ? MAX_SCREEN_WIDTH : screenWidth;
        return Math.floor ((useWidth - (20 * columnCount)) / columnCount);
    };

    const [cardWidth, setCardWidth] = useState(getCardWidth());

    useEffect(() => {
        setCardWidth(getCardWidth());
    }, [screenWidth, deviceType]);

    useEffect(() => {
        loadCategories();
        loadLists(featuredList, [], testFilter);
    }, [session?.lab]);

    const loadLists = async (selectedList: string, data: OrderableTest[] | Category[], filter?: string) => {
        if (selectedList === featuredList) {
            await loadFeaturedTests(data as OrderableTest[]);
        } else if (selectedList === allTestsList) {
            await loadIndividualTests(data as OrderableTest[], filter);
        } else {
            const category = categories.find(category => category.name === selectedList);
            await loadCategoryTests(category as Category, data as OrderableTest[]);
        }
    };

    const displayTestLoadingError = () => {
        displaySnack(<SubText style={{color: theme.colors.surface}}>An error occurred while loading the selected
            test list. Please try again later.</SubText>);
    };

    const loadIndividualTests = async (orderableTests: Array<OrderableTest>, filter?: string) => {
        setLoading(true);
        try {
            setTests(
                await Pagination.loadPage(
                    orderableTests,
                    async (page: Page) => {
                        if (filter && filter.length >= 2) {
                            AnalyticsService.create().sendEvent(new SearchEvent(session?.lab?.id as number, filter, authData?.patientId));
                            return await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listVisibleOrderableTestsByFilter(session?.lab?.id ? session?.lab?.id : 0, filter, page.nextPage, page.pageSize);
                        } else {
                            return await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listVisibleOrderableTests(session?.lab?.id ? session?.lab?.id : 0, undefined, page.nextPage, page.pageSize);
                        }
                    },
                    (a, b) => a.testId === b.testId
                )
            );
        } catch (ex) {
            displayTestLoadingError();
        } finally {
            setLoading(false);
        }
    };

    const loadCategoryTests = async (category: Category, orderableTests: Array<OrderableTest>) => {
        setLoading(true);
        try {
            setTests(await Pagination.loadPage(
                    orderableTests,
                    async (page: Page) => await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listVisibleOrderableTestsByCategory(session?.lab?.id ? session?.lab?.id : 0, category.id, page.nextPage, page.pageSize),
                    (a, b) => a.testId === b.testId
                )
            );
        } catch (ex) {
            displayTestLoadingError();
        }
    };

    const loadCategories = async () => {
        const categories = await CategoryService.create(Environment.apiBaseUrl as string).listCategories(session?.lab?.id || 0);

        const navList = new Array<string>();
        navList.push(featuredList);

        categories.forEach(category => navList.push(category.name));
        navList.push(allTestsList);

        setMenuItems(navList);
        setCategories(categories);
    };

    const loadFeaturedTests = async (tests: Array<OrderableTest>) => {
        setLoading(true);
        try {
            setTests(
                await Pagination.loadPage(tests,
                    async (page: Page) => await PublicOrderableTestService.create(Environment.apiBaseUrl as string).getFeaturedTests(session?.lab?.id ? session?.lab?.id : 0, page.nextPage, page.pageSize),
                    (a, b) => a.testId === b.testId),
            );
        } catch (ex) {
            displayTestLoadingError();
        } finally {
            setLoading(false);
        }
    }

    const onListChanged = async (list: string): Promise<void> => {
        setSelected(list);
        await loadLists(list, [], testFilter);
    }

    const onViewTestDetails = (test: OrderableTest) => {
        navigation.navigate(SCREEN_NAMES.TEST_DETAILS, {testId: test.testId});
    };

    const onAddTestToCart = async (testId: number) => {
        setTestToAdd(await PublicOrderableTestService.create(Environment.apiBaseUrl as string).listOrderableTestDetails(testId));
        setShowOverlay(true);
    };

    const onCloseOverlay = () => {
        setShowOverlay(false);
    };

    const onShowCart = () => {
        setShowOverlay(false);
        navigation.navigate(SCREEN_NAMES.CART);
    };

    const renderTestSummary = (toRender: ListRenderItemInfo<OrderableTest>) => {
        return (
            <TouchableRipple onPress={() => onViewTestDetails(toRender.item)}>
                <OrderableTestSummary
                    key={toRender.item.testId}
                    id={toRender.item.testId}
                    name={toRender.item.name}
                    description={toRender.item.shortDescription}
                    featured={toRender.item.featured}
                    price={toRender.item.price}
                    code={toRender.item.code}
                    minCardWidth={cardWidth}
                    onAddTestToCart={onAddTestToCart}
                />
            </TouchableRipple>
        );
    };

    const onOrderableTestListEndReached = async () => {
        if (!loading) {
            await loadLists(selected, tests, testFilter);
        }
    };

    const styles = StyleSheet.create({
        listContentContainer: {
            backgroundColor: theme.colors.secondaryAccent,
        }
    });

    const onFilterTests = async (filter: string) : Promise<void> => {
        setSelected(allTestsList);
        setTestFilter(filter);
        await loadLists(allTestsList, [], filter);
    };
    const onScreenLayoutChanged = (event: LayoutChangeEvent) => {
        setOverlayLayout(event.nativeEvent.layout as Layout);
    };

    return (
        <>
            <ReactiveFlatList
                itemMinimumWidth={cardWidth}
                itemMargin={20}
                data={tests}
                contentContainerStyle={styles.listContentContainer}
                ListHeaderComponent={<BrowseTestNavigator selectedMenu={selected}
                                                          menuItems={menuItems}
                                                          testFilter={testFilter}
                                                          onFilterChange={onFilterTests}
                                                          onNavigationChange={onListChanged}/>}
                ListFooterComponent={<PageFooter/>}
                ListEmptyComponent={<Headline style={{margin: 20}}>No tests found.</Headline>}
                renderItem={renderTestSummary}
                onEndReachedThreshold={0.5}
                onEndReached={onOrderableTestListEndReached}
                refreshing={loading}
                onLayout={onScreenLayoutChanged}
            />
            {showOverlay && testToAdd
                ? <AddToCartOverlay testIdToAdd={testToAdd?.testId as number}
                                    code={testToAdd?.code as string}
                                    test={testToAdd}
                                    layout={overlayLayout}
                                    onClose={onCloseOverlay}
                                    onShowCart={onShowCart}/>
                : <></>
            }
        </>
    );
};