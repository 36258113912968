import React, {useRef, useState} from "react";
import {
    ImageBackground,
    LayoutChangeEvent,
    Linking,
    StyleSheet,
    View,
} from "react-native";
import {Headline, IconButton, MD2Colors} from 'react-native-paper';
import Carousel from "react-native-reanimated-carousel";
import {CarouselRenderItemInfo, ICarouselInstance} from "react-native-reanimated-carousel/lib/typescript/types";
import {LinearGradient} from "expo-linear-gradient";
import {useNavigation} from "@react-navigation/native";
import {CarouselItem, instanceOfNativeNavigationCarouselAction} from "@luminate/luminate-ts-sdk";
import {useSharedValue} from "react-native-reanimated";

import {useThemeContext} from "../../contexts/ThemeContext";
import {Button} from "../common/Button";
import {Images} from "../../models";
import {HtmlView} from "../common/HtmlView";
import {CarouselPage} from "./CarouselPage";

export type BrowseCarouselProps = {
    items?: Array<CarouselItem>;
    autoPlayInterval?: 6000;
};
export const BrowseCarousel = (props: BrowseCarouselProps) => {
    const navigation = useNavigation();
    const {theme} = useThemeContext();
    const carouselRef = useRef<ICarouselInstance>(null);
    const [autoPlayEnabled, setAutoPlayEnabled] = useState<boolean>((props.items?.length || 0) > 1);
    const [carouselWidth, setCarouselWidth] = useState<number>(1);
    const [carouselHeight, setCarouselHeight] = useState<number>(1);
    const selectionProgress = useSharedValue<number>(0);
    const controlPanelOffset = 50;

    const styles = StyleSheet.create({
        fontColor: {
            color: MD2Colors.white
        }
    });

    const renderCarouselItem = (item: CarouselRenderItemInfo<CarouselItem>) => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ImageBackground style={{flex: 1, maxWidth: 1024, width: '100%', height: '100%'}}
                                 source={{uri: item.item.imageUrl}} defaultSource={Images.placeholder}
                                 resizeMode={'cover'}>
                    <LinearGradient
                        style={{
                            height: '100%',
                            zIndex: 2,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                        }}
                        colors={[theme.colors.primary, MD2Colors.transparent]}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                    />
                    <View style={{flex: 1, zIndex: 3}}>
                        <View style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                width: 55,
                                margin: 10,
                            }}>
                                <IconButton mode={'contained'} size={24} icon={'less-than'} onPress={movePrevious}/>
                            </View>
                            <View style={{flex: 1}}>
                                <View style={{maxWidth: 400}}>
                                    <Headline style={[styles.fontColor]}>{item.item.title}</Headline>
                                    <HtmlView style={{color: '#FFFFFF'}}>{item.item.description}</HtmlView>
                                    <Button style={{marginVertical: 5, maxWidth: 275}}
                                            mode={'outlined'}
                                            onPress={() => {
                                                onActionLinkClicked(item.item);
                                            }}>
                                        {item.item.action?.label || 'Learn More'}
                                    </Button>
                                </View>
                            </View>
                            <View style={{
                                width: 55,
                                margin: 10,
                            }}>
                                <IconButton mode={'contained'} icon={'greater-than'} onPress={moveNext}/>
                            </View>
                        </View>
                    </View>
                </ImageBackground>
            </View>
        );
    };

    const toggleAutoPlay = () => {
        setAutoPlayEnabled(!autoPlayEnabled);
    }
    const onActionLinkClicked = async (item: CarouselItem) => {
        if (item.action) {
            if (instanceOfNativeNavigationCarouselAction(item.action)) {
                // @ts-ignore
                navigation.navigate(item.action.screen, item.action.parameters);
            } else {
                await Linking.openURL(item.action.url);
            }
        }
    };

    const moveNext = () => {
        if (carouselRef.current) {
            setAutoPlayEnabled(false);
            carouselRef.current?.next();
        }
    };

    const movePrevious = () => {
        if (carouselRef.current) {
            setAutoPlayEnabled(false);
            carouselRef.current?.prev();
        }
    };

    const onImageLayoutChanged = (event: LayoutChangeEvent) => {
        setCarouselWidth((event.nativeEvent.layout.width || 0) > 0 ? event.nativeEvent.layout.width : 1);
        setCarouselHeight((event.nativeEvent.layout.height || 0) > 0 ? event.nativeEvent.layout.height : 1);
    };

    const onProgressChange = (offsetProgress: number, absoluteProgress: number) => {
        selectionProgress.value = absoluteProgress;
    };

    const onCarouselPageSelected = (selectedIndex: number) => {
        setAutoPlayEnabled(false);
        carouselRef.current?.scrollTo({index: selectedIndex, animated: true});
    };

    return (
        <View style={{flex: 1, minHeight: 400, maxHeight: 600}}
              onLayout={onImageLayoutChanged}
        >
            <Carousel
                ref={carouselRef}
                loop={true}
                autoPlayInterval={6000}
                scrollAnimationDuration={2000}
                width={carouselWidth}
                height={carouselHeight}
                vertical={false}
                pagingEnabled={true}
                autoPlay={autoPlayEnabled}
                snapEnabled={true}
                data={props.items || []}
                mode={'parallax'}
                modeConfig={{
                    parallaxScrollingScale: 1,
                    parallaxScrollingOffset: 0,
                }}
                renderItem={renderCarouselItem}
                onProgressChange={onProgressChange}
            />
            <View style={{
                position: 'absolute',
                top: carouselHeight - controlPanelOffset,
                left: 0,
                width: carouselWidth,
                height: controlPanelOffset,
                flexDirection: 'column-reverse',
                alignItems: 'center',
            }}>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <IconButton mode={'contained'}
                                icon={autoPlayEnabled ? 'pause' : 'play'}
                                size={12}
                                onPress={toggleAutoPlay}/>
                    {props.items?.map((item, index) => {
                        return (
                            <CarouselPage pageIndex={index}
                                          pageCount={props.items?.length || 0}
                                          selectProgression={selectionProgress}
                                          onSelected={onCarouselPageSelected}
                            />
                        )
                    })
                    }
                </View>
            </View>
        </View>
    );
};