import React, {useState} from "react";
import {HelperText, TextInput} from "react-native-paper";
import {ScrollView, View} from "react-native";
import {useNavigation} from "@react-navigation/native";

import {
    DateUtilities,
    RegistrationRequest,
    isRequired,
    PhoneTypes,
    usPhoneNumber,
    UsStates,
    mustBe5Digits
} from "@luminate/luminate-ts-sdk";

import {ValidatedInput} from "../common/ValidatedInput";
import {useRegistrationContext} from "../../contexts/RegistrationContext";
import {useAuth} from "../../contexts/AuthContext";
import {Dialog} from "../common/Dialog";
import {Header40, SubText} from "../typography";
import {Button} from "../common/Button";
import {PageFooter} from "../common/PageFooter";
import {useThemeContext} from "../../contexts/ThemeContext";
import {DropDown} from "../common/DropDown";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const ConnectHealthDataScreen = () => {
    const {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        email,
        userName,
        password,
        guardianRequest,
        guardianFirstName,
        guardianLastName,
        guardianDateOfBirth,
        agreeToTerms,
        gender, setGender,
        last4, setLast4,
        phoneType, setPhoneType,
        phone, setPhone,
        addressOne, setAddressOne,
        addressTwo, setAddressTwo,
        city, setCity,
        state, setState,
        zip, setZip
    } = useRegistrationContext();
    const {register, genders} = useAuth();
    const navigation = useNavigation();
    const {theme} = useThemeContext();

    const [showGenderDropDown, setShowGenderDropDown] = useState(false);
    const [showPhoneDropDown, setShowPhoneDropDown] = useState(false);

    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [phoneErrors, setPhoneErrors] = useState(new Array<string>());

    const [isAddressOneValid, setIsAddressOneValid] = useState(false);
    const [addressOneErrors, setAddressOneErrors] = useState(new Array<string>());

    const [isCityValid, setIsCityValid] = useState(false);
    const [cityErrors, setCityErrors] = useState(new Array<string>());

    const [showStateDropDown, setShowStateDropDown] = useState(false);

    const [isZipValid, setIsZipValid] = useState(false);
    const [zipErrors, setZipErrors] = useState(new Array<string>());

    const [registering, isRegistering] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);
    const [registrationErrorMessage, setRegistrationErrorMessage] = useState<string | undefined>(undefined);

    const onCityChanged = (value: string) => {
        setCity(value);
    };
    const onCityValidated = (errors: Array<string>) => {
        setIsCityValid(errors.length === 0);
        setCityErrors(errors);
    };

    const isFormValid = () => {
        return isPhoneValid && isAddressOneValid && isCityValid && isZipValid && phoneType && gender && state;
    };

    const onBackToRegistration = () => {
        // @ts-ignore
        navigation.navigate(SCREEN_NAMES.REGISTER);
    };

    const onConnect = async () => {
        isRegistering(true);
        try {
            const validatedLast4 = last4 && last4.trim().length > 0 ? last4 : undefined;
            await register({
                firstName,
                middleName,
                lastName,
                dateOfBirth: DateUtilities.fromString(dateOfBirth),
                email,
                userName,
                password,
                guardianRequest,
                guardianFirstName,
                guardianLastName,
                guardianDateOfBirth: DateUtilities.fromString(guardianDateOfBirth),
                gender,
                ssn4: validatedLast4,
                phoneType,
                phone,
                address1: addressOne,
                address2: addressTwo,
                state,
                city,
                zip,
                agreeToTerms
            } as RegistrationRequest);
        } catch (ex) {
            setRegistrationError(true);
            setRegistrationErrorMessage(ex.message);
        } finally {
            isRegistering(false);
        }
    };

    const onLast4Changed = (value: string) => {
        setLast4(value);
    };

    const onPhoneChanged = (value: string) => {
        setPhone(value);
    };
    const onPhoneValidated = (errors: Array<string>) => {
        setIsPhoneValid(errors.length === 0);
        setPhoneErrors(errors);
    };

    const onAddressOneChanged = (value: string) => {
        setAddressOne(value);
    };
    const onAddressOneValidated = (errors: Array<string>) => {
        setIsAddressOneValid(errors.length === 0);
        setAddressOneErrors(errors);
    };

    const onAddressTwoChanged = (value: string) => {
        setAddressTwo(value);
    };

    const onZipChanged = (value: string) => {
        setZip(value);
    };
    const onZipValidated = (errors: Array<string>) => {
        setIsZipValid(errors.length === 0);
        setZipErrors(errors);
    };

    return (
        <>
            <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
                <Dialog
                    contentStyle={{zIndex: 1}}
                    style={{marginVertical: 20}}
                    actions={<View style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}>
                        <Button style={{marginHorizontal: 5, marginVertical: 10}}
                                disabled={!isFormValid() || registering}
                                loading={registering}
                                onPress={onConnect}>
                            Connect
                        </Button>
                        <Button style={{marginHorizontal: 5, marginVertical: 10}}
                                onPress={onBackToRegistration}>
                            Back
                        </Button>
                    </View>}>
                    <View style={{flex: 1, alignItems: 'center', marginVertical: 5}}>
                        <Header40>Connect My Health Data</Header40>
                    </View>
                    <View>
                        <SubText style={{textAlign: 'center', marginVertical: 10, marginLeft: 10}}>All fields are
                            required unless noted.</SubText>
                        <DropDown
                            placeholder={'Gender'}
                            placeholderOffset={194}
                            style={{zIndex:5, marginVertical: 5}}
                            open={showGenderDropDown}
                            value={gender}
                            items={genders}
                            setOpen={setShowGenderDropDown}
                            setValue={setGender}
                            zIndex={3000}
                            zIndexInverse={1000}
                        />
                        <TextInput
                            label="Last Four of SSN (Optional)"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={last4}
                            onChangeText={onLast4Changed}
                        />
                        <DropDown
                            placeholder={'Phone Type'}
                            placeholderOffset={194}
                            style={{marginTop: 10, marginBottom: 5}}
                            open={showPhoneDropDown}
                            value={phoneType}
                            items={PhoneTypes}
                            setOpen={setShowPhoneDropDown}
                            setValue={setPhoneType}
                            zIndex={2000}
                            zIndexInverse={2000}
                        />
                        <ValidatedInput
                            label="Phone Number"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={phone}
                            onChangeText={onPhoneChanged}
                            rules={[usPhoneNumber]}
                            errors={phoneErrors}
                            onValidate={onPhoneValidated}
                        />
                        <ValidatedInput
                            label="Street Address"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={addressOne}
                            onChangeText={onAddressOneChanged}
                            rules={[isRequired]}
                            errors={addressOneErrors}
                            onValidate={onAddressOneValidated}
                        />
                        <TextInput
                            label="Address (Continued)"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={addressTwo}
                            onChangeText={onAddressTwoChanged}
                        />
                        <ValidatedInput
                            label="City"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={city}
                            onChangeText={onCityChanged}
                            rules={[isRequired]}
                            errors={cityErrors}
                            onValidate={onCityValidated}
                        />
                        <DropDown
                            placeholder={'State'}
                            placeholderOffset={194}
                            style={{ zIndex:5, marginTop: 10, marginBottom: 5}}
                            open={showStateDropDown}
                            value={state}
                            items={UsStates}
                            setOpen={setShowStateDropDown}
                            setValue={setState}
                            zIndex={1000}
                            zIndexInverse={3000}
                        />
                        <ValidatedInput
                            label="Zip"
                            mode={'outlined'}
                            style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                            value={zip}
                            onChangeText={onZipChanged}
                            rules={[isRequired, mustBe5Digits]}
                            errors={zipErrors}
                            onValidate={onZipValidated}
                        />
                        <HelperText type={'error'}
                                    visible={registrationError}>
                            {registrationErrorMessage ? registrationErrorMessage : 'Unable to register account at this time.'}
                        </HelperText>
                    </View>
                </Dialog>
                <PageFooter/>
            </ScrollView>
        </>
    );
};