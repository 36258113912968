import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {ScrollView, View} from "react-native";

import {
    containsOneLowercaseCharacter, containsOneNumber,
    containsOneSpecialCharacter, containsOneUppercaseCharacter,
    eightCharacterMinimum,
    isEmail,
    isRequired,
    Message,
    sameAs
} from "@luminate/luminate-ts-sdk";

import {ValidatedInput} from "../common/ValidatedInput";
import {ChangeEmailScreenNavigationProps} from "../routes/ProfileStack";
import {PasswordInput} from "../common/PasswordInput";
import {useAuth} from "../../contexts/AuthContext";
import {Dialog} from "../common/Dialog";
import {Header30, SubText} from "../typography";
import {Button} from "../common/Button";
import {PageFooter} from "../common/PageFooter";
import {useThemeContext} from "../../contexts/ThemeContext";

export const ChangeEmailScreen = () => {
    const navigation = useNavigation<ChangeEmailScreenNavigationProps>();
    const {changeEmailAddress, displaySnack} = useAuth();
    const {theme} = useThemeContext();

    const [email, setEmail] = useState<string>();
    const [emailErrors, setEmailErrors] = useState<Array<string>>([]);
    const [emailValid, setEmailValid] = useState(false);
    const onEmailChanged = (value: string) => {
        setEmail(value);
    };
    const onEmailValidated = (errors: Array<string>) => {
        setEmailErrors(errors);
        setEmailValid(errors.length === 0);
        setRevalidateConfirmEmail(true);
    };

    const [reValidateConfirmEmail, setRevalidateConfirmEmail] = useState(false);

    const [confirmEmail, setConfirmEmail] = useState<string>();
    const [confirmEmailErrors, setConfirmEmailErrors] = useState<Array<string>>([]);
    const [confirmEmailValid, setConfirmEmailValid] = useState(false);
    const onConfirmEmailChanged = (value: string) => {
        setConfirmEmail(value);
    };
    const onConfirmEmailValidated = (errors: Array<string>) => {
        setConfirmEmailErrors(errors);
        setConfirmEmailValid(errors.length === 0);
        setRevalidateConfirmEmail(false);
    };

    const [password, setPassword] = useState<string>();
    const [passwordErrors, setPasswordErrors] = useState<Array<string>>([]);
    const [passwordValid, setPasswordValid] = useState(false);

    const isFormValid = emailValid && confirmEmailValid && passwordValid;

    const emailsMustMatch = (confirmEmail: string, message = 'Email addresses must match'): Message => {
        return sameAs({first: email, second: confirmEmail}, message);
    }

    const onUpdateEmailAddress = async () => {
        try {
            await changeEmailAddress(email as string, confirmEmail as string, password as string);
            navigation.pop();
        } catch (ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to save the changes to your
                email address. Please check your credentials or try again later.</SubText>)
        }
    };

    const onDiscardChanges = () => {
        navigation.pop();
    };

    const onPasswordChanged = (value: string) => {
        setPassword(value);
    };

    const onPasswordValidated = (errors: Array<string>) => {
        setPasswordErrors(errors);
        setPasswordValid(errors.length === 0);
    };

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <Dialog style={{marginVertical: 20}} actions={<View style={{flex: 1, alignItems: 'center'}}>
                <Button style={{margin: 5}} disabled={!isFormValid} onPress={onUpdateEmailAddress}>Update
                    Email Address</Button>
                <Button style={{margin: 5}} onPress={onDiscardChanges}>Discard Changes</Button>
            </View>}>
                <Header30 style={{marginVertical: 10}}>Change My Email Address</Header30>
                <ValidatedInput
                    key={'new-email'}
                    label={'New Email Address'}
                    errors={emailErrors}
                    value={email}
                    rules={[isRequired, isEmail]}
                    onChangeText={onEmailChanged}
                    onValidate={onEmailValidated}
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <ValidatedInput
                    key={'confirm-new-email'}
                    label={'Re-type New Email Address'}
                    errors={confirmEmailErrors}
                    value={confirmEmail}
                    rules={[emailsMustMatch]}
                    reValidate={reValidateConfirmEmail}
                    onChangeText={onConfirmEmailChanged}
                    onValidate={onConfirmEmailValidated}
                    mode={'outlined'}
                    style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
                <PasswordInput key={'verify-password'}
                               label='Password'
                               dense={true}
                               value={password}
                               errors={passwordErrors}
                               rules={[eightCharacterMinimum, containsOneSpecialCharacter, containsOneLowercaseCharacter, containsOneUppercaseCharacter, containsOneNumber]}
                               onChangeText={onPasswordChanged}
                               onValidate={onPasswordValidated}
                               mode={'outlined'}
                               style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                />
            </Dialog>
            <PageFooter/>
        </ScrollView>
    );
};