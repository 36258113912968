import {createStackNavigator, StackNavigationProp} from "@react-navigation/stack";
import {CompositeNavigationProp, RouteProp} from "@react-navigation/native";
import {MaterialBottomTabNavigationProp} from "@react-navigation/material-bottom-tabs";

import {Order} from "@luminate/luminate-ts-sdk";

import {OrderHistoryListScreen} from "../screens/OrderHistoryListScreen";
import {OrderDetailsScreen} from "../screens/OrderDetailsScreen";
import {MyAccountTopNavigationParams} from "./MyAccountTabStack";
import {OrderConfirmationPdfWebScreen} from "../screens/OrderConfirmationPdfWebScreen";
import {CancelOrderScreen} from "../screens/CancelOrderScreen";

export type OrderHistoryStackParams = {
    OrderHistoryList: undefined,
    OrderDetails: { orderId: number }
    OrderPdfWeb: { orderId: number, oderNumber: string },
    CancelOrder: { order: Order },
};

const Stack = createStackNavigator<OrderHistoryStackParams>();
export type OrderHistoryListScreenNavigationProps = CompositeNavigationProp<StackNavigationProp<OrderHistoryStackParams, 'OrderHistoryList'>, MaterialBottomTabNavigationProp<MyAccountTopNavigationParams, 'OrderHistoryTab'>>;
export type OrderDetailsRouteProps = RouteProp<OrderHistoryStackParams, 'OrderDetails'>;

export type CancelListScreenNavigationProps = CompositeNavigationProp<StackNavigationProp<OrderHistoryStackParams, 'CancelOrder'>, MaterialBottomTabNavigationProp<MyAccountTopNavigationParams, 'OrderHistoryTab'>>;
export type CancelOrderRouteProps = RouteProp<OrderHistoryStackParams, 'CancelOrder'>;

export const OrderHistoryStack = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen options={{headerShown: false, title: 'Order History'}}
                          name={'OrderHistoryList'}
                          component={OrderHistoryListScreen}
            />
            <Stack.Screen options={{title: 'Order Details'}}
                          name={'OrderDetails'}
                          component={OrderDetailsScreen}
            />
            <Stack.Screen options={{headerTitle: 'View Order'}}
                          name={'OrderPdfWeb'}
                          component={OrderConfirmationPdfWebScreen}
            />
            <Stack.Screen options={{headerTitle: 'Cancel Order'}}
                          name={'CancelOrder'}
                          component={CancelOrderScreen}
            />
        </Stack.Navigator>
    );
};